
import React, {useState} from "react";

import * as DataHelper from '../utils/datahelper';

import { GoogleMap, useJsApiLoader,OverlayView, InfoWindow} from '@react-google-maps/api';

import { v4 as uuidv4 } from "uuid";

import houses from "../images/houses.jpg";

import MapRender from './maprender';
import zIndex from "@mui/material/styles/zIndex";

const containerStyle = {
  width: '100%',
  height: '100%'
};


function MapView(props) {

    var entries = props.entries;
    var center = props.center;
    if(center==null){
        center = {
            lat: 42.407245,
            lng: -71.382445
          };
          
    }
    var zoom = props.zoom;
    if(zoom==null){
        zoom = 8;
    }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: DataHelper.Config.mapKey,
  })

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  return isLoaded ? (
    <React.Fragment>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
      
    <MapRender entries = {entries} location = {props.location} center = {center} userData = {props.userData} contactCache = {props.contactCache} toggleContacts = {props.toggleContacts} filter = {props.data.filter}></MapRender>

      </GoogleMap>
      </React.Fragment>
  ) : <></>
}

export default React.memo(MapView)