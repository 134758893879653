import React from "react";


import './datatable.css';

import VCardGroup from "./vcardgroup";

import AliasGroup from "./aliasgroup";

import TagGroup from "./taggroup";

import { v4 as uuidv4 } from "uuid";

import CheckAttribute from './checkattribute';

import ItemAttribute from './itemattribute';

import TextAreaAttribute from './textareaattribute';

import HoverOverlay from './hoveroverlay';

import IconButton from './iconbutton';

import ToolTip from './tooltip';

import * as Helper from '../utils/helper';
import { toHaveFocus } from "@testing-library/jest-dom/dist/matchers";

class DataCell extends React.Component {

    constructor(props) {
        super(props);
        this.modifyCell = this.modifyCell.bind(this);
        this.focusCell = this.focusCell.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.enableEditing = this.enableEditing.bind(this);
        this.disableEditing = this.disableEditing.bind(this);
        this.submitDisableEditing = this.submitDisableEditing.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.submitChanges = this.submitChanges.bind(this);
        this.removeChange = this.removeChange.bind(this);
        this.setActive = this.setActive.bind(this);
        var content = this.props.content;
        if(this.props.changedValue!=null){
            content = this.props.changedValue;
        }
        this.id = uuidv4();
        this.state = { content: content, editing: this.props.editing };
    }
    componentDidMount(){
        if(this.props.editing&&typeof this.state.content !== 'string' && !(this.state.content instanceof String)){
            this.focusCell();
        }
    }

    modifyCell(value) {
        var callback = this.focusCell;
        if (typeof value === 'string' || value instanceof String)
            {
                callback = null;
            }
        this.setState({ content: value},callback);
    }
    focusCell(){
var cellElement = document.getElementById(this.id);
cellElement.focus();
    }
    toggleEditing() {
        if(this.props.name!="state"&&this.props.name!="caseNumber"){
        this.props.setEditing(!this.state.editing,this.props.name);
    }
    }

    enableEditing() {
        if(this.props.name!="state"&&this.props.name!="caseNumber"){
        this.props.setEditing(true,this.props.name);
    }
    }
      disableEditing() {
        this.props.setEditing(false,this.props.name);
    
    }

    submitDisableEditing(){
        this.submitChanges();
                this.props.setEditing(false,this.props.name);
    }
    onBlur(e) {
        var parent = document.getElementById(this.id);
        if(parent.contains(e.relatedTarget)||!this.state.editing)
            {
                return;
            }
        this.props.setEditing(false,this.props.name);
        this.submitChanges();
    }
    setActive(active){
        if(!this.props.cellChanged){
                      this.props.modifyRow({
            active: active }); 
                  }
                  else{
                    this.props.removeChange("active");
                  }
    }
    submitChanges(){
        var name = this.props.name;
        var newEntry = this.props.newEntry;
        if(JSON.stringify(this.props.content)!=JSON.stringify(this.state.content)||newEntry){
              this.props.modifyRow({
            [name]: this.state.content }); 
          }
          else{
            this.removeChange();
          }
    }
    removeChange(){
        var name = this.props.name;
        this.props.removeChange(name);
    }
    handleInput(e) {

        if (e.key == "Escape"||e.key=="Enter") {
            this.submitChanges(e);
        }
    }
    renderData(name, content) {
        try{
        var editing = this.state.editing;
        var manager = this.props.manager;

        var clickToCopy = this.props.clickToCopy;

        var renderedCell;
        if (!editing) {
            switch (name) {
                case "aliases":
                    var aliases = content.map(alias => <div key = {uuidv4()}>{alias}<IconButton type = "copy" action = "copy" tooltip className = "copyButton" title = "Copy Alias" value = {alias}></IconButton></div>);
                    renderedCell = aliases;
                    break;
                case "tags":
                var tagHandles = content.map(tagName=><ItemAttribute key = {uuidv4()} fontSize = "0.8em" tag = {true} nameProp = {tagName} valueProp = {tagName}></ItemAttribute>);
                renderedCell = <div className = "tagHandleWrapper">{tagHandles}</div>;
                break;
                case "contacts":
                    if (Array.isArray(content) && content.length > 0) {
                        var performanceMode = this.props.performanceMode;
                        var vCards = <VCardGroup entry = {this.props.entry} toggleContacts = {this.props.toggleContacts} contactCache = {this.props.contactCache} rowActive = {this.props.rowActive} performanceMode = {performanceMode} data = {content}></VCardGroup>;
                        renderedCell = vCards;
                    }
                    break;

                case "active":
                    var activeHandle = <CheckAttribute checkedProp = {content} onChangeProp = {e=>{this.setActive(!content)}} valueProp = "active"></CheckAttribute>
                    renderedCell = activeHandle;
                    break;
                default:
                    if(clickToCopy){
                    var contentCopy = <div className = "w-100 h-100" onClick = {e=>Helper.copyText(content.toString())}>{content.toString()}</div>
                    renderedCell = <ToolTip text = "Copied!" title = "Click to copy" trigger = {contentCopy}></ToolTip>;
                    }
                    else{
                    renderedCell = content.toString();
                    }
                    break;
            }
            return <React.Fragment>{renderedCell}</React.Fragment>;

        } else {
            var modifyCell = this.modifyCell;

            switch (name) {
                case "aliases":
                if (Array.isArray(content)) {
                renderedCell = <AliasGroup onChange = {modifyCell} data = {content} onBlur = {this.onBlur}></AliasGroup>
                }
                else{
                renderedCell = <div className = "editWrapper"><TextAreaAttribute className = "textAreaFill" onChangeProp = {function(e){modifyCell(JSON.parse(e))}} onBlurProp = {this.onBlur} valueProp = {JSON.stringify(content)} onKeyDownProp = {this.handleInput} autoFocus></TextAreaAttribute></div>;
                }
                break;
                case "tags":
                if (Array.isArray(content)) {
                renderedCell = <TagGroup onChange = {modifyCell} data = {content} onBlur = {this.onBlur}></TagGroup>
                }
                else{
                renderedCell = <div className = "editWrapper"><TextAreaAttribute className = "textAreaFill" onChangeProp = {function(e){modifyCell(JSON.parse(e))}} onBlurProp = {this.onBlur} valueProp = {JSON.stringify(content)} onKeyDownProp = {this.handleInput} autoFocus></TextAreaAttribute></div>;
                }
                break;
                case "contacts":
                        if (Array.isArray(content)) {
                        var performanceMode = this.props.performanceMode;
                        var vCards = <VCardGroup entry = {this.props.entry} toggleContacts = {this.props.toggleContacts} contactCache = {this.props.contactCache} rowActive = {this.props.rowActive} performanceMode = {performanceMode} data = {content} editing = {true} onBlurProp = {this.onBlur} modifyCell = {modifyCell}></VCardGroup>;
                        renderedCell = vCards;
                    }
                    else{
                renderedCell = <div className = "editWrapper"><TextAreaAttribute className = "textAreaFill" onChangeProp = {function(e){modifyCell(JSON.parse(e))}} onBlurProp = {this.onBlur} valueProp = {JSON.stringify(content)} onKeyDownProp = {this.handleInput} autoFocus></TextAreaAttribute></div>;
                    }
                break;
                case "active":;
                    var activeHandle = <CheckAttribute checkedProp = {content} onChangeProp = {this.props.setActive} valueProp = {!content}></CheckAttribute>
                    renderedCell = activeHandle;
                    break;
                default:
                    renderedCell = <React.Fragment><div className = "editWrapper"><TextAreaAttribute className = "textAreaFill" onChangeProp = {this.modifyCell} onBlurProp = {this.onBlur} valueProp = {content.toString()} onKeyDownProp = {this.handleInput} autoFocus></TextAreaAttribute></div></React.Fragment>;
                    break;
            }

                        var revert = <IconButton className = "invisible"></IconButton>;
            if(this.props.changedValue){
                revert = <IconButton type = "trash" title = "Reset Changes" onClick = {this.removeChange}></IconButton>;
            }
            var editButton = <IconButton type = "done" color = "green" title = "Finish Editing" onClick = {this.submitDisableEditing}></IconButton>;
            return <React.Fragment><div className = "d-flex justify-content-between">{revert}{editButton}</div><div>{renderedCell}</div></React.Fragment>;
        }
}
catch(err){
return err.toString();
}
    }
    render() {
        var editing = this.state.editing;
        var manager = this.props.manager;

var newEntry = this.props.newEntry;

        var tryToggleEdit;
        if (!editing && manager&&this.props.name!="active") {
            tryToggleEdit = this.enableEditing;
        }
        return <React.Fragment>

            <td tabIndex = {0} onBlur = {this.onBlur} id = {this.id} onDoubleClick = {tryToggleEdit} className = {`dataCell ${this.props.name}${this.props.cellChanged?' modifiedCell':''}${this.props.editing?' cellEditing':''}${newEntry?' modifyNewEntry':''}`}>{this.renderData(this.props.name,this.state.content)}</td>
</React.Fragment>
    }
}

export default DataCell;