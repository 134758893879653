import React from "react";

import './login.css';


class Login extends React.Component{

	render(){
		return <React.Fragment>Login</React.Fragment>
	}
}

export default Login;