import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom"


import NinjaClient from './api/ninjaclient';

import DataTab from "./dashboard/datatab";

import MapTab from "./dashboard/maptab";

import ContactsTab from "./dashboard/contactstab";

import EventsTab from "./dashboard/eventstab";

import PlanTab from "./dashboard/plantab";

import CRMTab from "./dashboard/crmtab";

import * as DataHelper from './utils/datahelper';

import { RowTemplate } from './constants';
import { mdiImageFilterTiltShift } from "@mdi/js";
import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";

class DashboardContent extends React.Component {
    constructor(props) {
        super(props);
        this.setDataSearch = this.setDataSearch.bind(this);
        this.persistNewEntries = this.persistNewEntries.bind(this);
        this.persistChanges = this.persistChanges.bind(this);
        this.persistCaseInfo = this.persistCaseInfo.bind(this);
        this.addEntry = this.addEntry.bind(this);
        this.addChanges = this.addChanges.bind(this);
        this.removeChanges = this.removeChanges.bind(this);
        this.removeChangeGroup = this.removeChangeGroup.bind(this);
        this.clearChanges = this.clearChanges.bind(this);
        this.pushChanges = this.pushChanges.bind(this);
        this.toggleShowOnlyModified = this.toggleShowOnlyModified.bind(this);
        this.toggleShowDisabled = this.toggleShowDisabled.bind(this);
        this.toggleShowOnlyDisabled = this.toggleShowOnlyDisabled.bind(this);
        this.toggleConfirmPrompt = this.toggleConfirmPrompt.bind(this);
        this.toggleClickToCopy = this.toggleClickToCopy.bind(this);

        var newEntries = DataHelper.getState(DataHelper.Config.newEntriesPath);
        if (newEntries == null) {
            newEntries = {};
        }
        var changes = DataHelper.getState(DataHelper.Config.changesPath);
        if (changes == null) {
            changes = {};
        }

       var caseInfo = DataHelper.getState(DataHelper.Config.caseInfoPath);
        if (caseInfo == null) {
            caseInfo = {};
        }


        this.state = { dataSearch:"",newEntries: newEntries, changes: changes, caseInfo: caseInfo, showOnlyModified: false,showDisabled: false,showOnlyDisabled:false, confirmPrompt: true, clickToCopy: true };

    }
    componentDidMount() {
        /* window.addEventListener("beforeunload", function (e) {
    if(Object.keys(this.state.changes).length>0){
    var confirmationMessage = 'You have unpushed changes. '
                            + 'If you leave before pushing, your changes will be lost.';
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
}.bind(this));*/
    }
    setDataSearch(search){
        this.setState({dataSearch:search});
    }
    toggleShowOnlyModified() {
        this.setState(prevState => ({ showOnlyModified: !prevState.showOnlyModified }));
    }
    toggleShowDisabled(){
        this.setState(prevState => ({ showDisabled: !prevState.showDisabled }));
    }
    toggleShowOnlyDisabled(){
        this.setState(prevState => ({ showOnlyDisabled: !prevState.showOnlyDisabled }));
    }
    toggleConfirmPrompt() {
        this.setState(prevState => ({ confirmPrompt: !prevState.confirmPrompt }));
    }
    toggleClickToCopy() {
        this.setState(prevState => ({ clickToCopy: !prevState.clickToCopy }));
    }
    persistNewEntries(entries) {
        DataHelper.setState(DataHelper.Config.newEntriesPath, entries);
    }
    persistChanges(changes) {
        DataHelper.setState(DataHelper.Config.changesPath, changes);
    }
    persistCaseInfo(caseInfo) {
        DataHelper.setState(DataHelper.Config.caseInfoPath, caseInfo);
    }
    addEntry(newEntry) {
        this.addChanges(newEntry.id, newEntry, newEntry.state, newEntry.caseNumber, true);
    }
    addChanges(id, attributes, state, caseNumber, newEntry = false) {
        this.setState((prevState) => {
            var existingChange = prevState.changes[id];
            var newChange = attributes;
            if (existingChange != null) {
                newChange = Object.assign(existingChange, newChange);
            }
            var newChanges = { ...prevState.changes, [id]: newChange };
            this.persistChanges(newChanges);
            var newCaseInfo = { ...prevState.caseInfo, [id]: { state: state, caseNumber: caseNumber } };
            this.persistCaseInfo(newCaseInfo);
            var newEntries = { ...prevState.newEntries };
            if (newEntry) {
                newEntries[id] = attributes;
            }
            if (newEntries[id] != null) {
                newEntries[id] = Object.assign(newEntries[id], attributes);
            }
            this.persistNewEntries(newEntries);

            var newState = { newEntries: newEntries, changes: newChanges, caseInfo: newCaseInfo };
            return newState;
        });

    }

    removeChanges(id, attribute) {
        if (this.state.newEntries[id] == null) {
            if (this.state.changes[id] != null && this.state.changes[id][attribute] != null) {
                this.setState(prevState => {
                    var newChanges = JSON.parse(JSON.stringify(prevState.changes));
                    delete newChanges[id][attribute];

                    var newCaseInfo = {...prevState.caseInfo};
                    if (Object.keys(newChanges[id]).length == 0) {
                        delete newChanges[id];
                        delete newCaseInfo[id];
                    }
                    this.persistChanges(newChanges);
                    this.persistCaseInfo(newCaseInfo);
                    var newState = { changes: newChanges,caseInfo:newCaseInfo };
                    return newState;


                });
            }
        } else {
            var freshRow = RowTemplate();
            this.setState(prevState => {
                var newChanges = JSON.parse(JSON.stringify(prevState.changes));
                newChanges[id][attribute] = freshRow[attribute];

                var newEntries = { ...prevState.newEntries };
                newEntries[id][attribute] = freshRow[attribute];

                this.persistNewEntries(newEntries);


                this.persistChanges(newChanges);
                var newState = { newEntries: newEntries, changes: newChanges };
                return newState;


            });
        }
    }

    removeChangeGroup(id) {
        if (this.state.changes[id] != null) {
            this.setState(prevState => {
                var newChanges = JSON.parse(JSON.stringify(prevState.changes));
                delete newChanges[id];
                this.persistChanges(newChanges);

                var newEntries = { ...prevState.newEntries };
                if (newEntries[id] != null) {
                    delete newEntries[id];
                    this.persistNewEntries(newEntries);
                }
                var newCaseInfo = {...prevState.caseInfo};
                delete newCaseInfo[id];
                this.persistCaseInfo(newCaseInfo);
                var newState = { newEntries: newEntries, changes: newChanges };


                return newState;


            })
        }
    }

    clearChanges() {
        this.persistChanges({});
        this.setState({ changes: {} });
    }

    async pushChanges() {
        var formattedChanges = [];
        var changeIds = Object.keys(this.state.changes);
        for (var i = 0; i < changeIds.length; i++) {
            var id = changeIds[i];
            var identifier = this.state.caseInfo[id];
            var attributes = this.state.changes[id]
            var formattedChange = { identifier: identifier, attributes: attributes };
            formattedChanges.push(formattedChange);
        }
        var results = await NinjaClient.pushChanges(formattedChanges);
        if (results != null) {
            this.setState({ changes: {},newEntries:{},caseInfo:{} }, function() {
                this.persistChanges({});
                this.persistNewEntries({});
                this.persistCaseInfo({});
                this.props.updateEntries(results)
            });
        }
    }

    render() {
var changedIds = Object.keys(this.state.changes);

        var data = {...this.props.data,dataCache:[...this.props.data.dataCache]};

        var showOnlyModified = this.state.showOnlyModified;
        var showDisabled = this.state.showDisabled;
        var showOnlyDisabled = this.state.showOnlyDisabled;

        if(showOnlyModified){
            data.dataCache = data.dataCache.filter(row=>changedIds.includes(row.id));
        }
        if(showOnlyDisabled){
            data.dataCache = data.dataCache.filter(row=>row.tags.includes("Disabled"));
        }
        else
        if(!showDisabled){
            data.dataCache = data.dataCache.filter(row=>!row.tags.includes("Disabled"));
        }

        var dataSearch = this.state.dataSearch.trim().toLowerCase();
        if(dataSearch.length>0){
            data.dataCache = data.dataCache.filter(row=>{
                var matchString = `${row.state} ${row.dataType} ${row.caseNumber} ${row.filledDate} ${row.city} ${row.street} ${row.plaintiff} ${row.defendant} ${row.aliases.join(" ")} ${row.tags.join(" ")} ${row.description}`.toLowerCase();
                return matchString.includes(dataSearch);
            });
        }

        return <React.Fragment><Routes>
        <Route path = "data" element = {<DataTab data = {data} setDataSearch = {this.setDataSearch} dataSearch = {this.state.dataSearch} toggleField = {this.props.toggleField} sortBy = {this.props.sortBy} view = {this.props.view} setViewType = {this.props.setViewType} setViewPage = {this.props.setViewPage} setViewPerPage = {this.props.setViewPerPage} searchEntries = {this.props.searchEntries} userData = {this.props.userData} newEntries = {this.state.newEntries} changes = {this.state.changes} addEntry = {this.addEntry} addChanges = {this.addChanges} removeChanges = {this.removeChanges} removeChangeGroup = {this.removeChangeGroup} clearChanges = {this.clearChanges} pushChanges = {this.pushChanges} showOnlyModified = {this.state.showOnlyModified} toggleShowOnlyModified = {this.toggleShowOnlyModified} showDisabled = {this.state.showDisabled} toggleShowDisabled = {this.toggleShowDisabled} showOnlyDisabled = {this.state.showOnlyDisabled} toggleShowOnlyDisabled = {this.toggleShowOnlyDisabled} confirmPrompt = {this.state.confirmPrompt} toggleConfirmPrompt = {this.toggleConfirmPrompt} clickToCopy = {this.state.clickToCopy} toggleClickToCopy = {this.toggleClickToCopy} toggleContacts = {this.props.toggleContacts} contactCache = {this.props.contactCache}></DataTab>}/>
        <Route path = "map" element = {<MapTab data = {data} userData = {this.props.userData} toggleContacts = {this.props.toggleContacts} contactCache = {this.props.contactCache}></MapTab>}/>
        <Route path = "contacts" element = {<ContactsTab userData = {this.props.userData} data = {this.props.contacts} retrieveData = {this.props.retrieveData} toggleContacts = {this.props.toggleContacts} updateContacts = {this.props.updateContacts} ></ContactsTab>}/>
        <Route path = "crm" element = {<CRMTab userData = {this.props.userData} retrieveData = {this.props.retrieveData}></CRMTab>}/>
        <Route path = "events" element = {<EventsTab data = {this.props.listeners} userData = {this.props.userData} retrieveData = {this.props.retrieveData}></EventsTab>}/>
        <Route path = "plan" element = {<PlanTab plans = {this.props.plans} userData = {this.props.userData}></PlanTab>}/>
        </Routes>
        </React.Fragment>
    }
}

export default DashboardContent;