
import React from "react";


class CheckAttribute extends React.Component {

    render() {
        var name = this.props.nameProp;
        var checked = this.props.checkedProp;
        var changed = this.props.onChangeProp;
        var clicked = this.props.onClickProp;

        if(changed==null){
            changed = ()=>{};
        }
        if(clicked==null){
            clicked = ()=>{};
        }
        var value = this.props.valueProp;
        var disabled = this.props.disabled;

            return <React.Fragment>
       <span className = "d-flex justify-content-center align-items-center"><input type = "checkbox" disabled = {disabled} className= {`checkInput ${name&&name.length>0?"me-1":""}`}  value = {value} onClick = {e=>clicked(e.target.value)} onChange = {e=>changed(e.target.value)} checked = {checked}/><span className = "checkLabel">{name}</span></span>
</React.Fragment>
    }
}

export default CheckAttribute;