
import React from "react";

import { v4 as uuidv4 } from "uuid";

class ListAttribute extends React.Component {

    render() {
        var name = this.props.nameProp;
        var value = this.props.valueProp;
        var options = this.props.optionsProp
        var changed = this.props.onChangeProp;
        var placeholder = this.props.placeholder;

        var optionsArray = [];
        if(placeholder!=null){
            var placeholderOption = <option key = {uuidv4()} value = "">{placeholder}</option>
            optionsArray = [placeholderOption];
        }
        var optionsList = options.map(option=><option key = {uuidv4()} value = {option}>{option}</option>);

        optionsArray = optionsArray.concat(optionsList);

            return <React.Fragment>
            <span className = "d-flex align-items-center justify-content-center">
          {name&&name.length>0?<label className = "me-1">{name}</label>:null}<select className = {this.props.className?this.props.className:"listInput"} value = {value} onChange = {e=>changed(e.target.value)}>{optionsArray}</select>
          </span>
</React.Fragment>
    }
}

export default ListAttribute;