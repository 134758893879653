import React from "react";

import Navigation from "./navigation";

import './error.css';

import { Link } from "react-router-dom";

class Error extends React.Component{


	render(){
		return <React.Fragment><Navigation navState = "home"/>

 <div class = "content">
	<div className="authWrapper">
<div className="text-danger text-center" style = {{fontSize:"2em"}}>Error: Page Not Found
<div className="text-primary mt-2"><Link to = "/">Back To Home</Link></div>
</div>
</div>
 </div>


		</React.Fragment>
	}
}

export default Error;