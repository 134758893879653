import React from "react";

import NinjaClient from '../api/ninjaclient';

import { Link } from "react-router-dom";

import * as DataHelper from "../utils/datahelper";

import Icon from '@mdi/react'
import { mdiPhoneForward } from '@mdi/js'

import {Permissions} from '../constants';

class CRMTab extends React.Component {
    constructor(props) {
        super(props);


const queryString = window.location.search;
var params = {};
queryString.replace("?","").split("&").map(function(pair){
var split = pair.split("=");
params[split[0]]=split[1];

});

if(params.liondesk_auth!=null){
var code = params.liondesk_auth;
NinjaClient.lionDeskLogin(code);
}
        
    }


    render() {
        var userData = this.props.userData;

        var manager = userData.manager;

        var dataView = <div className = "contentCentered heading">Loading...</div>;
        if (userData.subscriptions != null) {
           dataView =  <div className = "contentCentered heading"><span className = "text-center">No plan found<br></br><br></br>Subscribe to a&nbsp;<Link to = "/dashboard/plan">plan</Link></span></div>
        }
        if (manager || userData.permissions[Permissions.GET_ENTRIES]) {


var lionDeskSession = this.props.userData.liondesk_session;

var lionDeskStatusText ="";

var lionDeskButtonText = "Connect to LionDesk"; 

var lionDeskStatus = false;


if(lionDeskSession&&lionDeskSession.expires!=null){
var lionDeskExpiration = new Date(lionDeskSession.expires);
var lionDeskExpirationEpoch = lionDeskExpiration.getTime();




if(Date.now()>lionDeskExpirationEpoch){
lionDeskStatusText = "Status: Session Expired, Please Log In Again";
}
else{
lionDeskStatusText = `Status: Linked Until ${lionDeskExpiration.toLocaleString()}`;
lionDeskButtonText  = 'Linked to LionDesk';
lionDeskStatus = true;
}
}
var lionDeskStatusDiv;
if(lionDeskStatusText.length>0){
lionDeskStatusDiv=<div className = "p-3">{lionDeskStatusText}</div>;
}
dataView = <React.Fragment><div className="container py-2"><div className="row d-flex align-items-center py-5">
                          <div className="col text-center h1">
                            My CRM
                            </div>

                          </div>

<div className = "row mb-5 text-center d-flex justify-content-around">
                                      <div className="col-lg-8 mb-4 mb-lg-0">
        <div className={`bg-white p-4 planCard${lionDeskStatus?" planSubscribed":" shadow"}`}>
          <h1 className="h4 p-3 d-flex justify-content-center align-items-center"><span className = "wolf"></span>LionDesk</h1>
          <hr></hr>
          <div className="p-3">
          Connect to LionDesk instantly, to automatically import contacts.
          </div>
          <div className = "p-3">
          <a className = "dashButton btn btn-success rounded-pill" href = {DataHelper.Config.lionDeskAuthUrl}><Icon className = "me-1" path = {mdiPhoneForward} size = {1}></Icon>{lionDeskButtonText}</a>
          </div>
      {lionDeskStatusDiv}
        </div>
      </div>
</div>
</div>
</React.Fragment>

}


        return <React.Fragment>
        <div className = "dataContent">
{dataView}

        </div>
        </React.Fragment>
    }
}

export default CRMTab;