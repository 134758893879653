
import React from "react";

import { v4 as uuidv4 } from "uuid";

import ListAttribute from './listattribute';

class PropertyListAttribute extends React.Component {
constructor(props){
super(props);
this.submitValue = this.submitValue.bind(this);
}

submitValue(value){
if(value!=null){
var onChange = this.props.onChange;
var path = this.props.path;

onChange(path,value);
}
}


    render() {
        var name = this.props.name;
        var value = this.props.value;
        var list = this.props.list;
        var placeholder = this.props.placeholder;
        var className = this.props.className;


            return <React.Fragment>
        <div className = {className?className:"propertyAttributeWrapper"}>
          <ListAttribute placeholder = {placeholder} className={className} nameProp = {name} valueProp = {value} optionsProp = {list} onChangeProp = {e=>this.submitValue(e)}></ListAttribute>
          </div>
</React.Fragment>
    }
}

export default PropertyListAttribute;