
import React from "react";


import Collapsible from 'react-collapsible';

import VCardPanel from './vcardpanel'

import './vcard.css';

import Icon from '@mdi/react'
import { mdiAccount } from '@mdi/js'

import ToolTip from './tooltip';

import CheckAttribute from "./checkattribute";

class VCardHandle extends React.Component {
constructor(props){
    super(props);
    this.checkEnter = this.checkEnter.bind(this);
    this.checkLeave = this.checkLeave.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.toggleContact = this.toggleContact.bind(this);
    this.triggerActive = true;
    var added = this.props.added;
    if(added==null){
        added = false;
    }
    this.state = {open:this.props.open,added:added};

}

checkEnter(e){
    this.triggerActive = false;
}

checkLeave(e){
    this.triggerActive = true;
}

toggleOpen(){
    if(this.triggerActive){
    this.setState(prevState=>({open:!prevState.open}));
    }
}
toggleContact(id){
    this.props.toggleContacts([id]);
}
    render() {


var data = this.props.data;
var performanceMode = this.props.performanceMode;
var name = `${data.firstName}${data.middleName.length>0?` ${data.middleName} `:' '}${data.lastName}`;
var editing = this.props.editing;

var added = this.state.added;


var rowActive = this.props.rowActive;

var toggleElement = <span onClick = {e=>this.toggleContact(data.id)} onMouseEnter = {this.checkEnter} onMouseLeave = {this.checkLeave} className={`vCardToggle ${(!rowActive&&!added)?"invisible":""}`}><CheckAttribute checkedProp = {added} disabled = {!rowActive&&!added} valueProp = {data.id}></CheckAttribute></span>

var handle = <React.Fragment> <div className = "vCardHandle"><span></span><span>{name}<Icon path={mdiAccount} className="vCardIcon"/></span>{toggleElement}</div></React.Fragment>

            return <React.Fragment>
            <div className = "vCardWrapper"><Collapsible overflowWhenOpen="visible" trigger = {handle} handleTriggerClick = {this.toggleOpen} open = {this.state.open} transitionTime = {performanceMode?10:400} easing = {performanceMode?"steps(1, end)":"linear"}><VCardPanel data = {data} path = {this.props.path} modifyProperty = {this.props.modifyProperty} deleteProperty = {this.props.deleteProperty} appendToProperty = {this.props.appendToProperty} editing = {editing} toggleOpen = {this.toggleOpen}></VCardPanel></Collapsible></div>
</React.Fragment>
    
}

}

export default VCardHandle;