import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"


import Landing from './landing';
import Error from './error';
import Main from './main';
import Dashboard from './dashboard';
import Solutions from './solutions'
import Contact from './contact'
import Login from './login';

import './main.css';


class App extends React.Component {

render(){
    return <React.Fragment>
    <BrowserRouter>
    <Routes>

    <Route path = "/dashboard/*" element = {<Dashboard/>}>
    </Route>

    <Route path = "/solutions/*" element = {<Solutions/>}>
    </Route>


    <Route path = "/contact/*" element = {<Contact/>}>
    </Route>
    
        <Route path = "/" exact element = {<Landing/>}/>
    <Route path = "*" element={<Error/>}/>
    </Routes>
    </BrowserRouter>
  </React.Fragment>}
}

export default App;