import React from "react";

import {Parser} from "json2csv";

export function copyText(text) {
    navigator.clipboard.writeText(text)
}

export function listify(list, tailDelimiter = " and ", commaDelimiter = ", ") {
    if (list.length == 0) {
        return "";
    }
    if (list.length == 1) {
        return list[0];
    }
    if (list.length == 2) {;
        return list.join(tailDelimiter);
    }
    if (list.length > 2) {
        var listBody = list.slice(0, list.length - 1).join(`${commaDelimiter}`);
        var listTail = list[list.length - 1];
        return `${listBody}${tailDelimiter}${listTail}`;
    }
}

export function listifySpan(list, tailDelimiter = " and ", commaDelimiter = ", ") {
    tailDelimiter = <span>{tailDelimiter}</span>;
    commaDelimiter = <span>{commaDelimiter}</span>

    if (list.length == 0) {
        return <React.Fragment></React.Fragment>
    }
    if (list.length == 1) {
        return list[0];
    }
    if (list.length == 2) {;
        return <React.Fragment>{list[0]}{tailDelimiter}{list[1]}</React.Fragment>
    }
    if (list.length > 2) {
        var listBody = threadArray(list.slice(0, list.length - 1),commaDelimiter);
        var listTail = list[list.length - 1];
        return <React.Fragment>{listBody}{tailDelimiter}{listTail}</React.Fragment>
    }
}

export function pluralify(singular, amount, plural = null) {
    if (plural == null) {
        plural = singular + "s";
    }

    if (amount == 0) {
        return plural;
    }
    if (amount == 1) {
        return singular;
    }
    if (amount > 1) {
        return plural;
    }
}

export function multiReplace(str, replaces) {;

    var delimiters = Object.keys(replaces);

    var splits = [str];
    var layer = [];

    delimiters.forEach(function(delimiter) {
        splits.forEach(function(split) {
            if (replaces[split] == null) {
                var delims = split.split(delimiter);
                var threadedDelims = threadArray(delims,delimiter);
                layer = layer.concat(threadedDelims);
            }
            else{
            	layer.push(split);
            }

        });
        splits = layer;
        layer = [];
    });
    var replaced = splits.map(split => replaces[split] ? replaces[split] : split);
    return replaced;
}
export function threadArray(array, thread) {

    var newArray = [];
    array.forEach(function(item) {
        newArray.push(item);
        newArray.push(thread);
    });
    newArray = newArray.slice(0, newArray.length - 1);
    return newArray;
}

export function stringToColor(stringInput) {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export function sortKeys(unordered){
    const ordered = Object.keys(unordered).sort().reduce(
        (obj, key) => { 
          obj[key] = unordered[key]; 
          return obj;
        }, 
        {}
      );
      return ordered;
}

export function capitalizeWords(phrase){
    var words = phrase.split(" ");
    var capitalized = words.map(word=>{
        var newWord = word;
        if(word.length>0){
            newWord = newWord[0].toUpperCase()+newWord.substr(1);
        }
        return newWord;
    });
    var newPhrase = capitalized.join(" ");
    return newPhrase;
}

export function downloadFile(name,content) {
    const file = new File([content], name);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
    }, 0);
}

export function jsonToCsv(data,fields=[]){
    const opts = {fields};
    const parser = new Parser(opts);
    return parser.parse(data);

}