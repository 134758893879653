import React from "react";

import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

import Icon from '@mdi/react'
import { mdiTableLarge, mdiEarth,mdiAccount,mdiPhone,mdiLightningBolt, mdiCreditCard } from '@mdi/js'


import './dashboard.css';
import './colors.css';

class DashboardMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <React.Fragment><div className = "d-flex flex-nowrap">
            <div
  className="d-flex flex-column flex-shrink-0 p-3 text-white dashMenu"
>
  <ul className="nav nav-pills flex-column mb-auto">
    <li className="nav-item">
            <NavLink to ="/dashboard/data" className="nav-link text-white" activeclassname = "active">
    <Icon path={mdiTableLarge} className = "icon watermelon"/>
        <span>Data</span>
      </NavLink>
    </li> 
    <li>
            <NavLink to ="/dashboard/map" className="nav-link text-white" activeclassname = "active">
    <Icon path={mdiEarth} className = "icon sprout"/>
        <span>Map</span>
      </NavLink>
    </li>
    <li>
            <NavLink to ="/dashboard/contacts" className="nav-link text-white" activeclassname = "active">
    <Icon path={mdiAccount} className = "icon celestial"/>
        <span>Contacts</span>
      </NavLink>
    </li>
      <li>
            <NavLink to ="/dashboard/crm" className="nav-link text-white" activeclassname = "active">
    <Icon path={mdiPhone} className = "icon bumblebee"/>
        <span>CRM</span>
      </NavLink>
    </li>
    <li>
            <NavLink to ="/dashboard/events" className="nav-link text-white" activeclassname = "active">
    <Icon path={mdiLightningBolt} className = "icon easter"/>
        <span>Events</span>
      </NavLink>
    </li>
        <li>
            <NavLink to ="/dashboard/plan" className="nav-link text-white" activeclassname = "active">
    <Icon path={mdiCreditCard} className = "icon mango"/>
        <span>My Plan</span>
      </NavLink>
    </li>
  </ul>
</div>


        </div>


        </React.Fragment>
    }
}

export default DashboardMenu;