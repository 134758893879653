import React from "react";

import Navigation from "./navigation";

import './solutions.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import NinjaClient from './api/ninjaclient';

import PriceGroup from './components/pricegroup';

class Solutions extends React.Component {

    constructor(props) {
        super(props);
        this.loadPlans = this.loadPlans.bind(this);
        this.state = {plans:[]};
    }
    componentDidMount() {
       this.loadPlans();
    }
    
    async loadPlans() {
        var plans = await NinjaClient.getPlans();
    this.setState({plans:plans});
    }
    render() {
        return <React.Fragment>
<Navigation navState = "solutions"/> 
<div className = "content">
<div className = "authWrapper">
<PriceGroup plans = {this.state.plans} interactive = {false}></PriceGroup>
</div>
</div>
</React.Fragment>
    }
}

export default Solutions;