import React from "react";

import './price.css'

import CheckAttribute from './checkattribute';

import Icon from '@mdi/react'
import { mdiNinja, mdiCheck, mdiCog } from '@mdi/js'

import { v4 as uuidv4 } from "uuid";

import NinjaClient from '../api/ninjaclient';

import { Link } from "react-router-dom";

class PricePlan extends React.Component {
    constructor(props) {
        super(props);

        this.toggleAddon = this.toggleAddon.bind(this);
        this.getSelectedAddons = this.getSelectedAddons.bind(this);
        this.checkout = this.checkout.bind(this);
        var plan = this.props.plan;

        var addonCache = {};
        var addonKeys = Object.keys(plan.addons);
        for (var i = 0; i < addonKeys.length; i++) {
            var key = addonKeys[i];
            var addon = plan.addons[key];
            addonCache[addon.price] = false;
        }

        this.state = { plan: plan, addonCache: addonCache };
    }

    toggleAddon(name) {
      if(this.state.addonCache[name]!=null){
        this.setState(prevState=>({addonCache:{...prevState.addonCache,[name]:!prevState.addonCache[name]}}));
      }
    }
    getSelectedAddons(){
              var addonCache = this.state.addonCache;
            var cacheKeys = Object.keys(addonCache);

            var addonsSelected = cacheKeys.filter(function(key){return addonCache[key]==true});
            return addonsSelected;
    }
    checkout(priceCart){
      var userData = this.props.userData;
      priceCart = priceCart.filter(function(price){return !userData.subscriptions.includes(price)});
      NinjaClient.checkout(priceCart);

    }
    render() {
        var userData = this.props.userData;

        var plan = this.state.plan;

        var interactive = this.props.interactive;

        var addonKeys = Object.keys(plan.addons);


        var actionButton;
        var addons;

        var shell = plan.price==null;
        if (interactive) {

        var subscribed = userData.subscriptions.includes(plan.price);
          var addonPrices = Object.keys(this.state.addonCache);
          var addonsSubscribed = 0;
          for(var i = 0;i<addonPrices.length;i++){
            var price = addonPrices[i];
            if(userData.subscriptions.includes(price)){
              addonsSubscribed++;
            }
          }
          if(addonsSubscribed>0){
            subscribed = true;
          }

          
          var btnClass = "btn-primary";
          var active = true;
          var btnText = "Subscribe";

            var addonsSelected = this.getSelectedAddons();
            var addonCount = addonsSelected.length;
          var priceCart = [];

          if(!shell){
          if(subscribed){

            if(addonCount==0){
            active = false;
            btnClass = "btn-success";
            btnText = "Subscribed";
            }
            else{
            btnClass = "btn-danger";
            priceCart = addonsSelected;
            if(addonCount==1){
              btnText = "Purchase 1 Addon"
            }
            else{
              btnText = `Purchase ${addonCount} Addons`
            }

            }
          }
          else{
            priceCart = [plan.price,...addonsSelected];
            if(addonCount>0){
            btnClass = "btn-danger";
            if(addonCount==1){
              btnText = `Subscribe + 1 Addon`
            }
            else{
              btnText = `Subscribe + ${addonCount} Addons`
            }
            }
          }
        }
        else{
                      if(addonCount==0){
            active = false;
            btnClass = "btn-success";
            btnText = `Subscribed to ${addonsSubscribed} ${addonsSubscribed==1?"item":"items"}`;
            }
            else{
            btnClass = "btn-primary";
            priceCart = addonsSelected;
            if(addonCount==1){
              btnText = "Subscribe to 1 item"
            }
            else{
              btnText = `Subscribe to ${addonCount} items`
            }

            }
        }
            actionButton = <button onClick = {e=>this.checkout(priceCart)} className={`${active?"":"disabled "}btn ${btnClass} shadow px-3 py-2 my-2 planActionButton`}>
            {btnText}
          </button>;
            addons = <div className={`text-small text-center planAddons shadow my-2`}>
          
            <div key = {uuidv4()} className="planAddon">
            <div className = "planAddonsHeader text-white bg-info">{shell?"Subscriptions":"Addons"}</div>
            <div className = "planAddonsContent">
            {addonKeys.map(key=>
            
            <div key = {uuidv4()} className = "text-nowrap">
            <span className="d-flex flex-nowrap justify-content-between align-items-center" title = {plan.addons[key].disabled?"Coming Soon":""}>
            <span style = {{textDecoration:plan.addons[key].disabled?"line-through":""}}>
            {userData.subscriptions.includes(plan.addons[key].price)?<span><Icon path={mdiCheck} className = "text-success" size = {1} /> {plan.addons[key].name}</span>:<CheckAttribute nameProp = {plan.addons[key].name}  disabled = {userData.subscriptions.includes(plan.addons[key].price)||plan.addons[key].disabled} checkedProp = {this.state.addonCache[plan.addons[key].price]} valueProp = {plan.addons[key].price} onChangeProp = {this.toggleAddon}></CheckAttribute>}
            </span>
            <span className = "ms-4 text-success priceTag" style = {{textDecoration:"none"}}>${plan.addons[key].perMonth}<span className = "addonSubText text-secondary">/month</span></span>
            </span>
            </div>
            )}
            </div>


            </div>
          </div>
        } else {
            actionButton = <Link to = "/dashboard/plan?state=signUp" className="dashButton btn btn-primary shadow px-3 py-2 my-2 planActionButton">
            Sign Up
          </Link>;
          addons = <div className={`text-small text-center planAddons shadow my-2`}>
          
            <div key = {uuidv4()} className="planAddon">
            <div className = "planAddonsHeader text-white bg-info">{shell?"Subscriptions":"Addons"}</div>
            <div className = "planAddonsContent">
            {addonKeys.map(key=>
            
            <div key = {uuidv4()} className = "text-nowrap">
            <span className="d-flex flex-nowrap justify-content-between align-items-center" title = {plan.addons[key].disabled?"Coming Soon":""}>
            <span style = {{textDecoration:plan.addons[key].disabled?"line-through":""}}>
            <span><Icon path={mdiCog} className = {plan.addons[key].disabled?"text-success":"text-primary"} size = {1}/> {plan.addons[key].name}</span>
            </span>
            <span style = {{display:"none"}} className = "ms-4 text-success priceTag">${plan.addons[key].perMonth}<span className = "addonSubText text-secondary">/month</span></span>
            </span>
            </div>
            )}
            </div>


            </div>
          </div>

        }



        var priceSpan;
        if(plan.perMonth){
          priceSpan = <React.Fragment><span className="h1 font-weight-normal ml-2">${plan.perMonth}</span><span className="h3 text-secondary font-weight-light ml-2">
            /month
          </span></React.Fragment>;
        }
        return (
            <div className="col-lg-6 mb-4 mb-lg-0">
        <div className={`bg-white p-4 planCard${subscribed?" planSubscribed":" shadow"}`}>
          <h1 className="h4 p-3"><Icon path={mdiNinja} size = {1.5}/> {plan.name}</h1>
          <hr></hr>
          {priceSpan}
          <div className = "planContent">
          <div className="text-small text-center">
          {plan.description}
          </div>
          <ul className="text-small text-center list-unstyled mb-0">
          {plan.features.map(feature=>
            <li key = {uuidv4()} className="py-1">
            - {feature}
            </li>)}
          </ul>
          {addons}
          </div>
          {actionButton}
        </div>
      </div>
        );
    }
}

export default PricePlan;