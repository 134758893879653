export const Permissions = {
    GET_ENTRIES: "getEntries",
    MODIFY_ENTRIES: "modifyEntries",
    ADMIN: "*",
    LISTS: {
        MA: {
            "Servicemember": "GET_MA_SERVICEMEMBER",
            "Tax Lien": "GET_MA_TAX_LIEN",
            "Probate": "GET_MA_PROBATE"
        }

    }
}

export const DataTypes = [
  "Servicemember",
  "Tax Lien",
  "Probate"  
];

export const Tags = { 
"On Market": "#00A300",
 "Off Market": "#ff0000",
  "Deceased": "#000000",
 "Estate": "#800080",
 "Verified Number": "#19e5e6",
 "Unverified Active Numbers": "#FFBF00",
 "No Active Numbers": "#964B00",
 "Disabled": "#686963"
}

export const States = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
]

export const StateColors = {
    "AL": "#a0bc8a",
    "AK": "#1aa34f",
    "AZ": "#4e718b",
    "AR": "#91b626",
    "CA": "#d50433",
    "CO": "#e5bd34",
    "CT": "#32683f",
    "DE": "#710c22",
    "FL": "#a731db",
    "GA": "#91449f",
    "HI": "#cd7c81",
    "ID": "#a8fdc7",
    "IL": "#1805a5",
    "IN": "#c18242",
    "IA": "#bb5559",
    "KS": "#2475a7",
    "KY": "#c5ec32",
    "LA": "#68d8f0",
    "ME": "#9cedb5",
    "MD": "#aeb16e",
    "MA": "#b0006a",
    "MI": "#34d8ce",
    "MN": "#36f8df",
    "MS": "#2409fc",
    "MO": "#d06596",
    "MT": "#da027f",
    "NE": "#dbeb18",
    "NV": "#cf65ee",
    "NH": "#737f2e",
    "NJ": "#8ee28d",
    "NM": "#010f9d",
    "NY": "#6eb2af",
    "NC": "#b119d7",
    "ND": "#55a1e4",
    "OH": "#af9363",
    "OK": "#8228aa",
    "OR": "#b4fd58",
    "PA": "#bda134",
    "RI": "#b65a60",
    "SC": "#bb2710",
    "SD": "#1c590e",
    "TN": "#660abf",
    "TX": "#2d92f4",
    "UT": "#8a2300",
    "VT": "#aa3523",
    "VA": "#522f12",
    "WA": "#b79673",
    "WV": "#3bdce1",
    "WI": "#bd8445",
    "WY": "#287b3b"
}

export const DataTypeColors = {
    "Servicemember":"#E15A97",
    "Tax Lien":"#0456ae",
    "Probate":"#59C9A5"
}


export const RowTemplate = () => {
    return {
    "id": "",
    "state": "",
    "dataType": "",
    "caseNumber": "",
    "filledDate": "",
    "city": "",
    "street": "",
    "plaintiff": "",
    "defendant": "",
    "aliases": [],
    "contacts": [],
    "tags": [],
    "description": "",
    "active": false
};

}

export const ValueTypes = {
    phone:["Mobile","Home","Office","Other"],
    email:["Primary","Secondary","Personal","Work","Other"]
}

export const ValueStatuses = {
    phone:["Confirmed","Unverified","Inactive","Other"],
    email:["Confirmed","Unverified","Inactive","Other"]
}

export const ExportTypes = ["Email","Text","Contacts","CRM"];

export const CRMTypes = ["LionDesk"];

export const ContactExportTypes = [".csv",".json","LionDesk"];