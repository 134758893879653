    export function setToValue(obj, value, path) {
        var i;
        for (i = 0; i < path.length - 1; i++){
            obj = obj[path[i]];
        }

        obj[path[i]] = value;
    }
    export function appendToValue(obj, value, path) {
        var i;
        for (i = 0; i < path.length - 1; i++){
            obj = obj[path[i]];
        }

        if (Array.isArray(obj[path[i]])) {
            obj[path[i]] = [...obj[path[i]], value]
        }
    }
    export function deleteValue(obj, path) {
        var i;
        for (i = 0; i < path.length - 1; i++){
            obj = obj[path[i]];
        }

        if (Array.isArray(obj)) {
            obj.splice(path[i], 1);
        } else {
            delete obj[path[i]];
        }
    }