import React from "react";

import ItemAttribute from './itemattribute';

import { v4 as uuidv4 } from "uuid";

import Icon from '@mdi/react'
import { mdiAlert} from '@mdi/js'


class ChangeGroup extends React.Component {
    constructor(props){
        super(props);
        this.deleteAttribute = this.deleteAttribute.bind(this);
    }
    deleteAttribute(attribute){
    var id = this.props.id;
    this.props.onChange(id,attribute);
    }

    render() {
        var data = this.props.data;
        var keys = Object.keys(data);
        var state = this.props.state;
        var caseNumber = this.props.caseNumber;
        var onClick = this.props.onClick;
        var id = this.props.id;

        var revealing = data.active!=null&&data.active==true;

        var danger;
        if(revealing){
            danger =  <Icon path={mdiAlert} className = "iconButtonMedium alertIcon"/>
        }

        var newEntry = this.props.newEntry;

        var groupContent;

        if(newEntry){
            groupContent = <div className = {"changeGroup modifyNewEntry"} title = "New Entry"><span className = "changeGroupHeader">{id} <span className = "changeGroupEntry">New {data.dataType} Entry</span></span></div>;
        }
        else{
            groupContent = <div className = {revealing?"changeGroup modifyDanger":"changeGroup"} title = {revealing?"Warning: active is set to 'true', will reveal to subscribers and trigger new entry hooks.":""}><span className = "changeGroupHeader">{id}</span>{keys.map(key=><ItemAttribute key = {uuidv4()} nameProp = {key} valueProp = {key} onChangeProp = {this.deleteAttribute}></ItemAttribute>)}{danger}</div>;
        }


        return <React.Fragment>
           {groupContent}
</React.Fragment>
}

}
export default ChangeGroup;