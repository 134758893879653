
import React from "react";

import Collapsible from 'react-collapsible';

import CheckAttribute from './checkattribute';

import ListAttribute from './listattribute';

import IntegerAttribute from './integerattribute';

import IconButton from "./iconbutton";

import PageTurner from './pageturner';

import { v4 as uuidv4 } from "uuid";

class DataFilter extends React.Component {


    render() {
        var userData = this.props.userData;

        var data = this.props.data;
        var fieldsToggled = this.props.data.filter.visible;
        var fieldAliases = this.props.data.fieldAliases;
        var toggleField = this.props.toggleField;
        var view = this.props.view;
        var keys = Object.keys(fieldsToggled);

        if(!userData.manager){
            keys.splice(keys.indexOf("active"),1);
        }

        var entryText = `Showing ${data.dataCache.length} entries`;
               var pagination;
               if(view.type=="Page"&&this.props.data.dataCache.length>0)
                {
                var pageStart = this.clamp((view.page-1)*view.perPage,0,data.dataCache.length-1);
               var pageEnd = this.clamp(Number(pageStart)+Number(view.perPage),0,data.dataCache.length);
               var pageMax = Math.ceil(data.dataCache.length/view.perPage);
               var pagePerMax = data.dataCache.length;
               entryText = `Showing ${pageStart+1}-${pageEnd} out of ${data.dataCache.length} entries`;
               pagination = <React.Fragment>        <span className = "filterWrapper"><IntegerAttribute nameProp = "Entries Per Page" minProp = {1} maxProp = {pagePerMax} valueProp = {view.perPage} onChangeProp = {this.props.setViewPerPage}></IntegerAttribute></span>
                                              <span className = "filterWrapper">                    <PageTurner label = "Page " data = {this.props.data} view = {this.props.view} setViewPage = {this.props.setViewPage}></PageTurner></span> 
                                                            </React.Fragment>;
            }
            return <React.Fragment>
            <div className = "dataFilterWrapper">
            <Collapsible transitionTime = {100} open = {true} trigger = {<div className = "dataFilterHeader">Data<IconButton className="expandedIcon" type = "collapsed"></IconButton></div>} triggerWhenOpen = {<div className = "dataFilterHeader">Data<IconButton className="expandedIcon" type = "expanded"></IconButton></div>}>
            <div className = "dataFilterRow"><span className = "dataFilterHeader dataFilterHeaderCell">Field:</span><span className = "filterContent filterFlex">{keys.map(key=>(<span key = {uuidv4()} className = "filterWrapper"><CheckAttribute nameProp = {fieldAliases[key]} checkedProp = {fieldsToggled[key]} valueProp = {key} onChangeProp = {toggleField}></CheckAttribute></span>))}</span></div>
                        <div className = "dataFilterRow"><span className = "dataFilterHeader dataFilterHeaderCell">View:</span><span className = "filterContent filterFlex">
                            <span className = "filterWrapper"><ListAttribute nameProp = "Type" valueProp = {view.type} optionsProp = {view.types} onChangeProp = {this.props.setViewType}></ListAttribute></span>

                    <span className = "filterWrapper"><CheckAttribute nameProp = "Click to copy" checkedProp = {this.props.clickToCopy} onChangeProp = {this.props.toggleClickToCopy}></CheckAttribute></span>                     
                    
                    {pagination}     
                            <span className = "filterWrapper">{entryText}</span>    
                        </span></div>
                        </Collapsible>
</div>

</React.Fragment>
    }
    clamp = (num, min, max) => Math.min(Math.max(num, min), max);
}

export default DataFilter;