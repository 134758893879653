import React from "react";

import { Link } from "react-router-dom";
import './contactstab.css';

import ContactsGroup from "../components/contactsgroup";

import { Permissions } from "../constants";

class ContactsTab extends React.Component {
    constructor(props) {
        super(props);
        this.toggleTable = this.toggleTable.bind(this);
        this.removeTableCache = this.removeTableCache.bind(this);
        var openCache = {};
        if(this.props.data){
        var tableNames = Object.keys(this.props.data);
        tableNames.forEach(tableName=>{
            openCache[tableName]=true;
        });
            }
        this.state = {openCache:openCache};
    }
    toggleTable(name,assert = null){
        this.setState(prevState=>({openCache:{...prevState.openCache,[name]:assert==null?!prevState.openCache[name]:assert}}));
    }
    removeTableCache(name){
        this.setState(prevState=>{
            var newOpenCache = JSON.parse(JSON.stringify(prevState.openCache));
            if(newOpenCache[name]!=null){
                delete newOpenCache[name];
            }
            var newState = {openCache:newOpenCache};
            return newState;
        });
    }
    render() {

        var userData = this.props.userData;
        var dataView = <div className = "contentCentered heading">Loading...</div>;
        if (userData.subscriptions != null) {
           dataView =  <div className = "contentCentered heading"><span className = "text-center">No plan found<br></br><br></br>Subscribe to a&nbsp;<Link to = "/dashboard/plan">plan</Link></span></div>
        }

        var manager = userData.manager;
        
        if (manager || userData.permissions[Permissions.GET_ENTRIES]) {
            dataView = <React.Fragment>
                <div className="col text-center h1">
                    My Contacts
                </div>
            <ContactsGroup data = {this.props.data} toggleTable = {this.toggleTable} removeTableCache = {this.removeTableCache} openCache = {this.state.openCache} userData = {userData} retrieveData = {this.props.retrieveData} toggleContacts = {this.props.toggleContacts} updateContacts = {this.props.updateContacts}></ContactsGroup>
            </React.Fragment>
        }

        return <React.Fragment>
            <div className="dataContent">
        {dataView}
        </div>
        </React.Fragment>
    }
}

export default ContactsTab;