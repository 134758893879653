import React from "react";

import './tooltip.css';

class ToolTip extends React.Component {

    constructor(props) {
        super(props);
        this.showToolTip = this.showToolTip.bind(this);
        this.deferHideToolTip = this.deferHideToolTip.bind(this);
        this.state = {text:this.props.text,active:false};

    }
    showToolTip(){
        this.setState({active:true},this.deferHideToolTip);
    }
    deferHideToolTip(){
        clearTimeout(this.hideToolTipThread);
        this.hideToolTipThread = setTimeout(function(){
            this.setState({active:false});
        }.bind(this),2000);
    }

    render() {
        var active = this.state.active;

        var text = this.state.text;
        var title = this.props.title;
        var classes = []

        if(active){
            classes.push("toolTipActive");
        }  

        var classString = classes.join(" ");

        return <React.Fragment>
    <div className = "toolTip">
<div onClick = {this.showToolTip} title = {title}>{this.props.trigger}</div>
<div className = {classString}><div className = "toolTipGroup"><span className = "toolTipText">{text}</span><div className = "toolTipArrow"></div></div></div>
</div>
</React.Fragment>
    }
}

export default ToolTip;