import React from "react";

import './dashboard.css';


import { BrowserRouter, Routes, Route, Link } from "react-router-dom"

import Navigation from "./navigation";

import DashboardContainer from "./dashboardcontainer";


import * as authUi from "./authui";

import { Amplify, AmplifyTheme, Auth } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


import authConfig from "./data/authConfig";



Amplify.configure(authConfig);

class Dashboard extends React.Component {

constructor(props){
super(props);
this.logOut = this.logOut.bind(this);

}
login(user,signOut){

this.signOut=signOut;
this.user = user;
}
logOut(){
if(this.signOut){
this.signOut();
}
}
getUser(){
    return this.user;
}
    render() {
const queryString = window.location.search;
var params = {};
queryString.replace("?","").split("&").map(function(pair){
var split = pair.split("=");
params[split[0]]=split[1];

});
var initialState = params.state;

        return <React.Fragment>
    
    <div className = "content">
    <div className="authWrapper">
        <Authenticator initialState = {initialState} formFields = {authUi.formFields} components = {authUi.components}>
      {({ signOut, user }) => {this.login(user,signOut)

return (
    <React.Fragment>
    <Navigation navState = "logged in" signOut={signOut} user={user}/>
      <DashboardContainer></DashboardContainer></React.Fragment>
            )
      }}
    </Authenticator>
    </div>
    </div></React.Fragment>
    }
}

export default Dashboard;