import React from "react";

import ListenerGroup from '../components/listenergroup';

import { Link } from "react-router-dom";

import { Permissions } from "../constants";

import './eventstab.css';

class EventsTab extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var userData = this.props.userData;
        var dataView = <div className = "contentCentered heading">Loading...</div>;
        if (userData.subscriptions != null) {
           dataView =  <div className = "contentCentered heading"><span className = "text-center">No plan found<br></br><br></br>Subscribe to a&nbsp;<Link to = "/dashboard/plan">plan</Link></span></div>
        }
        var manager = userData.manager;
        
        console.log(userData.permissions[Permissions.GET_ENTRIES]);
        if (manager || userData.permissions[Permissions.GET_ENTRIES]) {
            dataView = <React.Fragment>
            <div className="col text-center h1">
                Events
            </div>
            <ListenerGroup data = {this.props.data} userData = {userData} retrieveData = {this.props.retrieveData}></ListenerGroup>
            </React.Fragment>
        }

        return <React.Fragment>
        <div className = "dataContent">
        {dataView}
        </div>
        </React.Fragment>
    }
}

export default EventsTab;