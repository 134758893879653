import React from "react";

import Collapsible from 'react-collapsible';

import ItemAttribute from './itemattribute';

import CheckAttribute from './checkattribute';

import ListAttribute from './listattribute';

import { v4 as uuidv4 } from "uuid";

import { Permissions, States } from '../constants';

import IconButton from "./iconbutton";

import Cities from '../data/cities';

import ListTypeInput from '../input/listtypeinput';
class DataSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            states: {
                "AL": false,
                "AK": false,
                "AZ": false,
                "AR": false,
                "CA": false,
                "CO": false,
                "CT": false,
                "DE": false,
                "FL": false,
                "GA": false,
                "HI": false,
                "ID": false,
                "IL": false,
                "IN": false,
                "IA": false,
                "KS": false,
                "KY": false,
                "LA": false,
                "ME": false,
                "MD": false,
                "MA": true,
                "MI": false,
                "MN": false,
                "MS": false,
                "MO": false,
                "MT": false,
                "NE": false,
                "NV": false,
                "NH": false,
                "NJ": false,
                "NM": false,
                "NY": false,
                "NC": false,
                "ND": false,
                "OH": false,
                "OK": false,
                "OR": false,
                "PA": false,
                "RI": false,
                "SC": false,
                "SD": false,
                "TN": false,
                "TX": false,
                "UT": false,
                "VT": false,
                "VA": false,
                "WA": false,
                "WV": false,
                "WI": false,
                "WY": false
            }, cities: [], dataTypes: { "Servicemember": true, "Tax Lien": true, "Probate": true }, tags: { "On Market": false, "Off Market": false, "Deceased": false, "Estate": false, "Verified Number": false, "Unverified Active Numbers": false, "No Active Numbers": false }, visibilityTypes: { visible: true, invisible: true }, timeRange: "Past Month"
        };

        this.addState = this.addState.bind(this);
        this.removeState = this.removeState.bind(this);
        this.addCity = this.addCity.bind(this);
        this.removeCity = this.removeCity.bind(this);
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.toggleDataType = this.toggleDataType.bind(this);
        this.toggleVisibilityType = this.toggleVisibilityType.bind(this);
        this.setTimeRange = this.setTimeRange.bind(this);
        this.searchEntries = this.searchEntries.bind(this);
    }

    addState(state) {
        this.setState(prevState => ({ ...prevState, states: { ...prevState.states, [state]: true } }));
    }

    removeState(state) {
        this.setState(prevState => ({ ...prevState, states: { ...prevState.states, [state]: false } }));
    }
    addCity(city) {
        this.setState(prevState => {
            if (!prevState.cities[city]) {
                var newCities = [...prevState.cities];
                newCities.push(city);
                newCities.sort();
                return { cities: newCities };
            }
        });
    }

    removeCity(city) {
        this.setState(prevState => {
            var newCities = [...prevState.cities].filter(newCity => newCity != city);
            return { cities: newCities };
        });
    }

    addTag(tag) {
        this.setState(prevState => ({ ...prevState, tags: { ...prevState.tags, [tag]: true } }));
    }

    removeTag(tag) {
        this.setState(prevState => ({ ...prevState, tags: { ...prevState.tags, [tag]: false } }));
    }

    toggleDataType(dataType) {
        this.setState(prevState => ({ ...prevState, dataTypes: { ...prevState.dataTypes, [dataType]: !prevState.dataTypes[dataType] } }));

    }

    toggleVisibilityType(visibilityType) {
        this.setState(prevState => ({ ...prevState, visibilityTypes: { ...prevState.visibilityTypes, [visibilityType]: !prevState.visibilityTypes[visibilityType] } }));

    }

    setTimeRange(range) {
        this.setState({ timeRange: range });
    }

    searchEntries() {
        var userData = this.props.userData;


        var stateCache = this.state.states;
        var stateNames = Object.keys(stateCache);

        var states = stateNames.filter(function (stateName) { if (stateCache[stateName]) { return stateName } });

        var cities = this.state.cities;

        var dataTypeCache = this.state.dataTypes;
        var dataTypeNames = Object.keys(dataTypeCache);

        var dataTypes = dataTypeNames.filter(function (dataTypeName) { if (dataTypeCache[dataTypeName]) { return dataTypeName } });


        var tagCache = this.state.tags;
        var tagNames = Object.keys(tagCache);

        var tags = tagNames.filter(function (tagName) { if (tagCache[tagName]) { return tagName } });

        var timeStart = false;
        var timeEnd = Date.now();


        var timeRange = this.state.timeRange;

        switch (timeRange) {
            case "Past 48 Hours":
                timeStart = timeEnd - 172800000;
                break;
            case "Past Week":
                timeStart = timeEnd - 604800000;
                break;
            case "Past Month":
                timeStart = timeEnd - 2678400000;
                break;
            case "Past 6 Months":
                timeStart = timeEnd - 15811200000;
                break;
            case "Past Year":
                timeStart = timeEnd - 31536000000;
                break;

        }
        timeEnd += 86400000;

        if (userData.manager) {
            var visibilityTypeCache = this.state.visibilityTypes;
            var visibilityTypes = [];
            if (visibilityTypeCache["visible"]) {
                visibilityTypes.push(true);
            }
            if (visibilityTypeCache["invisible"]) {
                visibilityTypes.push(false);
            }
            this.props.searchEntries(states,cities, dataTypes, visibilityTypes, tags, timeStart, timeEnd);
        }
        else {
            this.props.searchEntries(states,cities, dataTypes, [], tags, timeStart, timeEnd);
        }
    }
    render() {
        var userData = this.props.userData;

        var states = this.state.states;
        var stateNames = Object.keys(states);

        var stateOptions = stateNames.filter(function (stateName) { return !states[stateName] });

        var validStates = stateNames.filter(function (stateName) { if (states[stateName]) { return stateName; } });

        var cityOptions = [];

        validStates.forEach(state => {
            var stateCities = Cities[state];
            if (stateCities) {
                var formattedCities = stateCities.map(stateCity => `${stateCity}, ${state}`);
                cityOptions = cityOptions.concat(formattedCities);
            }

        });

        var stateHandles = validStates.map(stateName => <ItemAttribute key={uuidv4()} nameProp={stateName} onChangeProp={this.removeState} valueProp={stateName}></ItemAttribute>);

        var cityHandles = this.state.cities.map(city => <ItemAttribute key={uuidv4()} nameProp={city} onChangeProp={this.removeCity} valueProp={city}></ItemAttribute>);

        var dataTypes = this.state.dataTypes;
        var dataTypeNames = Object.keys(dataTypes);


        var tags = this.state.tags;
        var tagNames = Object.keys(tags);
        var tagOptions = ["Add Tag"]
        tagOptions = tagOptions.concat(tagNames.filter(function (tagName) { if (tags[tagName] == false && tagName != "Disabled") { return tagName } }));

        var validTags = tagNames.filter(function (tagName) { if (tags[tagName]) { return tagName; } });

        var tagHandles = validTags.map(tagName => <ItemAttribute key={uuidv4()} fontSize = {"0.8em"} tag={true} nameProp={tagName} onChangeProp={this.removeTag} valueProp={tagName}></ItemAttribute>);

        var timeOptions = ["Past 48 Hours", "Past Week", "Past Month", "Past 6 Months", "Past Year", "All Time"];

        var visibilityOptions;
        if (userData.manager) {
            visibilityOptions =
                <React.Fragment>

                    <span className="filterWrapper"><label>Show:</label></span>
                    <span key={uuidv4()} className="filterWrapper"><CheckAttribute nameProp="Visible" checkedProp={this.state.visibilityTypes.visible} valueProp="visible" onChangeProp={this.toggleVisibilityType}></CheckAttribute></span>

                    <span key={uuidv4()} className="filterWrapper"><CheckAttribute nameProp="Invisible (unprocessed)" checkedProp={this.state.visibilityTypes.invisible} valueProp="invisible" onChangeProp={this.toggleVisibilityType}></CheckAttribute></span>
                </React.Fragment>
        }
        return <React.Fragment>
            <div className="dataFilterWrapper">
                <Collapsible transitionTime={100} open={true} trigger={<div className="dataFilterHeader">Query<IconButton className="expandedIcon" type="collapsed"></IconButton></div>} triggerWhenOpen={<div className="dataFilterHeader">Query<IconButton className="expandedIcon" type="expanded"></IconButton></div>}>
                    <div className="dataFilterRow">
                    <span className="dataFilterHeader dataFilterHeaderCell">Filter:</span>
                        <span className="filterContent filterFlex py-1">
                            <span className="filterContent filterFlex ps-1">
                                <span><ListTypeInput placeholder="Add State" options={stateOptions} onChange={this.addState} uppercase={true} className="listInputLarge"></ListTypeInput></span>
                                <span className="d-flex flex-wrap">{stateHandles}</span>
                            </span>
                            <span className="filterContent filterFlex ps-1">
                            <span><ListTypeInput placeholder="Add City" options={cityOptions} onChange={this.addCity} bypass={true} className="listInputLarge"></ListTypeInput></span>
                            <span className="d-flex flex-wrap">{cityHandles}</span>
                            </span>
                        </span>
                    </div>
                    <div className="dataFilterRow">
                    <span className="dataFilterHeader dataFilterHeaderCell"></span>
                    <span className="filterContent filterFlex pt-1 ps-1">
                    <span><ListAttribute valueProp="Add Tag" optionsProp={tagOptions} className="listInputLarge" onChangeProp={this.addTag}></ListAttribute></span>
                    <span className="d-flex flex-wrap">{tagHandles}</span>
                            </span>
                            <span className="filterContent filterFlex flex-wrap ps-1">
                            <ListAttribute className="listInputLarge" valueProp={this.state.timeRange} optionsProp={timeOptions} onChangeProp={this.setTimeRange}></ListAttribute>
                            </span>

                    </div>

                    <div className="dataFilterRow"><span className="dataFilterHeader dataFilterHeaderCell"></span><span className="filterContent filterFlex">
                        <div className="filterContent filterFlex">
                            {dataTypeNames.map(key => dataTypes[key] == true || dataTypes[key] == false ? <span key={uuidv4()} className="filterWrapper"><CheckAttribute nameProp={key} checkedProp={this.state.dataTypes[key]} valueProp={key} onChangeProp={this.toggleDataType}></CheckAttribute></span> : key)}
                            {visibilityOptions}
                        </div>
                        <span className="filterWrapper based"><button className="dashButton btn btn-primary searchButton" onClick={this.searchEntries}>Search Database</button></span>

                    </span></div>
                </Collapsible>
            </div>
        </React.Fragment>
    }
}

export default DataSearch;