

import React from "react";

class IntegerAttribute extends React.Component {
constructor(props){
super(props);
this.handleChange = this.handleChange.bind(this);
}

handleChange(e){
    var changed = this.props.onChangeProp;
    changed(e.target.value);

}
    render() {
        var name = this.props.nameProp;
        var value = this.props.valueProp;
        var min = this.props.minProp;
        var max = this.props.maxProp;

            return <React.Fragment>
           <label>{name}</label> <input type = "number" className = {`integerInput ${this.props.className}`} value = {this.props.valueProp} min = {min} max = {max} onChange = {this.handleChange}/>
</React.Fragment>
    }
}

export default IntegerAttribute;