import React from "react";

import VCardHandle from './vcardhandle'

import './vcard.css';

import Icon from '@mdi/react'
import { mdiPlus, mdiCloseCircle } from '@mdi/js'

import { v4 as uuidv4 } from "uuid";

import { ContactTemplate, AddressTemplate } from '../utils/contacttemplate';

import IconButton from './iconbutton';

class VCardGroup extends React.Component {
    constructor(props) {
        super(props);
        this.generateContact = this.generateContact.bind(this);
        this.addContact = this.addContact.bind(this);
        this.removeContact = this.removeContact.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.modifyProperty = this.modifyProperty.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
        this.appendToProperty = this.appendToProperty.bind(this);
        this.state = { data: this.props.data }

    }
    generateContact(aliasIndex) {
        var entry = this.props.entry;


        if(entry?.aliases.length > 0){
            aliasIndex = Math.min(entry?.aliases.length-1,aliasIndex);

            let address = AddressTemplate();
            address.name = "Property";
            address.country = "United States";
            address.state = entry.state;
            address.city = entry.city;
            address.street = entry.street;

            let addresses = [address];

            var alias = entry.aliases[aliasIndex];
            var aliasTerms = alias.split(" ");
            var contact = ContactTemplate();

            if (aliasTerms.length > 0) {
                contact.firstName = aliasTerms[0];
                if (aliasTerms.length > 1) {
                    contact.lastName = aliasTerms[aliasTerms.length - 1]
                }
                if (aliasTerms.length > 2) {
                    contact.middleName = aliasTerms.slice(1, aliasTerms.length - 1).join(" ");
                }
            }

            contact.addresses = addresses;

            return contact;
        }
        else{
            return ContactTemplate();
        }
    }
    addContact() {
        this.setState((prevState) => {
            var newContact = this.generateContact(prevState.data.length);
            var newState = { data: [...prevState.data, newContact] };
            this.props.modifyCell(newState.data);
            return newState;

        });
    }
    removeContact(id) {
        this.setState((prevState) => {
            var newData = [...prevState.data].filter(function (contact) { return contact.id != id });
            var newState = { data: newData };
            this.props.modifyCell(newState.data);
            return newState;

        });
    }
    onBlur(e) {
        var related = e.relatedTarget;
        if (related) {
            return;
        }
        var onBlur = this.props.onBlurProp;
        onBlur(e);
    }
    modifyProperty(path, value) {
        try {
            this.setState((prevState) => {
                var newData = JSON.parse(JSON.stringify(prevState.data));
                this.setToValue(newData, value, path);
                var newState = { data: newData };
                this.props.modifyCell(newState.data);
                return newState;

            });
        }
        catch (err) {
            console.log(err);
        }
    }
    deleteProperty(path) {
        try {
            var newData = this.state.data;
            this.deleteValue(newData, path);
            var newState = { data: newData };
            this.props.modifyCell(newState.data);
            this.setState(newState);
        }
        catch (err) {
            console.log(err);
        }
    }
    appendToProperty(path, value) {
        try {
            var newValue = [...this.getValue(this.state.data, path), value];
            var newData = this.state.data;
            this.setToValue(newData, newValue, path);
            var newState = { data: newData };
            this.props.modifyCell(newState.data);
            this.setState(newState);
        }

        catch (err) {
            console.log(err);
        }
    }
    getValue(obj, path) {
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        return JSON.parse(JSON.stringify(obj[path[i]]));
    }
    setToValue(obj, value, path) {
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        obj[path[i]] = value;
    }
    appendToValue(obj, value, path) {
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        if (Array.isArray(obj[path[i]])) {
            obj[path[i]] = [...obj[path[i]], value]
        }
    }
    deleteValue(obj, path) {
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        if (Array.isArray(obj)) {
            obj.splice(path[i], 1);
        }
        else {
            delete obj[path[i]];
        }
    }
    render() {
        var performanceMode = this.props.performanceMode;
        var data = this.state.data;
        var editing = this.props.editing;
        var handles;
        var removeContact = this.removeContact;

        var contactCache = this.props.contactCache;

        if (!editing) {
            handles = data.map((contact, index) => <VCardHandle added={contactCache[contact.id]==true} toggleContacts = {this.props.toggleContacts} rowActive={this.props.rowActive} data={contact} key={contact.id} performanceMode={performanceMode} path={[index]} editing={editing}></VCardHandle>)
        }
        else {
            handles = data.map((contact, index) => <div className="flexEditWrapper" key={contact.id}><VCardHandle added={contactCache[contact.id]==true} data={contact} path={[index]} open={true} modifyProperty={this.modifyProperty} deleteProperty={this.deleteProperty} appendToProperty={this.appendToProperty} performanceMode={performanceMode} editing={editing}></VCardHandle><IconButton type="delete" value={contact.id} onClick={removeContact}></IconButton></div>)
        }
        var contactWrapper = <div className="contactWrapper" key={uuidv4()}>{handles}</div>

        if (!editing) {
            return contactWrapper;
        }
        else {
            return <div tabIndex={0} onBlur={this.props.onBlurProp}>{contactWrapper}<IconButton type="add" onClick={this.addContact}></IconButton></div>;
        }
    }

}

export default VCardGroup;