
import React from "react";


class TextAreaAttribute extends React.Component {
    constructor(props){
        super(props);
        this.updateHeight=this.updateHeight.bind(this);
        this.state = {height:"1em"};
    }
    updateHeight(e){
        this.setState({height:e.target.scrollHeight+"px"});
    }
    render() {
        var changed = this.props.onChangeProp;
        var onKeyDown = this.props.onKeyDownProp;
        var value = this.props.valueProp;
        var disabled = this.props.disabled;
        var onBlur = this.props.onBlurProp;
        var autoFocus = this.props.autoFocus;
        var className = this.props.className;
        var placeholder = this.props.placeholder;

        var style;
        var stiff = this.props.stiff;
        if(!stiff){
        style = {height:this.state.height}
        }


            return <React.Fragment>
        
       <textarea placeholder = {placeholder} autoFocus = {autoFocus} disabled = {disabled} style = {style} className= {className?className:"textAreaInput"} value = {value} onChange = {e=>changed(e.target.value)} onInput = {this.updateHeight} onFocus = {this.updateHeight} onKeyDown = {onKeyDown} onBlur = {onBlur}></textarea>
</React.Fragment>
    }
}

export default TextAreaAttribute;