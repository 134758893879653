
import React from "react";

import './contacttable.css';

import ContactCell from './contactcell';

import { v4 as uuidv4 } from "uuid";

class ContactRow extends React.Component {

    constructor(props){
        super(props);
        this.selectContact = this.selectContact.bind(this);

    }

    selectContact(){
        var id = this.props.data.id;
        this.props.selectContact(id);
    }

    render() {

        var data = this.props.data;

        var selected = this.props.selected

        return <React.Fragment>
            <tr>
                <ContactCell name="first_name" content={data.first_name}></ContactCell>
                <ContactCell name="last_name" content={data.last_name}></ContactCell>
                <ContactCell name="phones" content={{mobile_phone:data.mobile_phone,home_phone:data.home_phone,office_phone:data.office_phone}}></ContactCell>
                <ContactCell name="additional_phones" content={data.additional_phones}></ContactCell>
                <ContactCell name="emails" content={{email:data.email,secondary_email:data.secondary_email}}></ContactCell>
                <ContactCell name="additional_emails" content={data.additional_emails}></ContactCell>
                <ContactCell name="addresses" content={data.addresses}></ContactCell>
                <ContactCell name="identifier" content={data.identifier}></ContactCell>
                <ContactCell name="tags" content={data.tags}></ContactCell>
                <ContactCell name="select" content={selected==true} selectContact = {this.selectContact}></ContactCell>
            </tr>
        </React.Fragment>
    }
}

export default ContactRow;