import React from "react";

import PropertyAttribute from './propertyattribute';

import IconButton from './iconbutton';

import { v4 as uuidv4 } from "uuid";


class AliasGroup extends React.Component {
    constructor(props){
        super(props);

        this.modifyAlias = this.modifyAlias.bind(this);
        this.deleteAlias = this.deleteAlias.bind(this);
        this.addAlias = this.addAlias.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state={aliases:this.props.data};
    }
    modifyAlias(index,value){

           var newAliases = [...this.state.aliases];
            newAliases[index]=value;
            this.props.onChange(newAliases);
            var newState = {aliases:newAliases};
            this.setState(newState);
    }
    deleteAlias(index){
              var newAliases = [...this.state.aliases];
            newAliases.splice(index,1);
            this.props.onChange(newAliases);
            var newState = {aliases:newAliases};
            this.setState(newState);
    }
    addAlias(){
             var newAliases = [...this.state.aliases];
            newAliases.push("");
            this.props.onChange(newAliases);
            var newState = {aliases:newAliases};
            this.setState(newState);
    }

     onBlur(e){
        var related = e.relatedTarget;
        if(related){
            return;
        }
        var onBlur = this.props.onBlur;
        onBlur(e);
    }

    render() {
        var changed = this.props.onChange;
        var aliases = this.state.aliases;
        return <React.Fragment>
        <div className = "aliasWrapper" tabIndex = {0} autoFocus onBlur = {this.props.onBlurProp}>
                     {aliases.map((al,index)=> <div className = "flexEditWrapper" key = {uuidv4()}>
            <PropertyAttribute key = {uuidv4()} value = {al}   placeholder = {`Alias ${index}`} path = {index} onSubmit = {(path,value)=>this.modifyAlias(path,value)} ></PropertyAttribute>            
    <IconButton key = {uuidv4()}  type = "delete" value = {index} onClick = {this.deleteAlias}></IconButton>
                </div>)}
                  <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" onClick = {this.addAlias}></IconButton>
                </div>
                </div>
</React.Fragment>
    }
}

export default AliasGroup;