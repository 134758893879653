import React from "react";


import './vcard.css';

import Icon from '@mdi/react'
import { mdiAccount } from '@mdi/js'

import VCard from 'vcard-creator';

import { v4 as uuidv4 } from "uuid";

import PropertyAttribute from './propertyattribute';

import PropertyListAttribute from './propertylistattribute';

import { ValueTemplate, FieldTemplate,AddressTemplate } from '../utils/contacttemplate';

import IconButton from './iconbutton';

import {ValueTypes,ValueStatuses} from '../constants';

class VCardPanel extends React.Component {
    constructor(props) {
        super(props);
        this.addField = this.addField.bind(this);
        this.addValue = this.addValue.bind(this);
        this.addAddress = this.addAddress.bind(this);
        this.deleteProperty = this.deleteProperty.bind(this);
    }

    addField(path) {
        var appendToProperty = this.props.appendToProperty;
        var value = FieldTemplate();
        appendToProperty(path,value);
    }

    addValue(path) {
        var appendToProperty = this.props.appendToProperty;
        var value = ValueTemplate();
        appendToProperty(path,value);
    }

    addAddress(path){
        var appendToProperty = this.props.appendToProperty;
        var value = AddressTemplate();
        appendToProperty(path,value);
    }

    deleteProperty(path) {
        var deleteProperty = this.props.deleteProperty;
        deleteProperty(path);
    }

    render() {

        var data = this.props.data;

        var editing = this.props.editing;

        var tabIndex = 0;
        var name;
        var phoneNumbers;
        var emails;
        var addresses;
        var fields;
        try {
            if (typeof data === 'object' &&
                !Array.isArray(data) &&
                data !== null) {

                if (!editing) {
                    if (data.firstName != null && data.middleName != null && data.lastName != null) {
                        name = <div className = "vCardGroup"><div className = "contactValue">{`${data.firstName}${data.middleName.length>0?` ${data.middleName} `:' '}${data.lastName}`}</div></div>;
                    }
                    if (data.phoneNumbers && data.phoneNumbers.length > 0) {
                        phoneNumbers = <div className = "vCardGroup"><div className = "vCardGroupHeader">Phone:</div>{data.phoneNumbers.map(pn=><div className = "contactValue" key = {uuidv4()}><a href = {`tel:${pn.value}`}>{pn.value}</a><IconButton type = "copy" action = "copy" tooltip className = "copyButton" title = "Copy" value = {pn.value}></IconButton> <div className = "contactNotes">{pn.notes}</div><div className = "contactInfo">{[pn.type=="Other"?null:pn.type,pn.status=="Other"?null:pn.status].filter(function(value){return value!=null}).join(", ")}</div></div>)}</div>;
                    }

                    if (data.emails && data.emails.length > 0) {
                        emails = <div className = "vCardGroup"><div className = "vCardGroupHeader">Email:</div>{data.emails.map(em=><div className = "contactValue" key = {uuidv4()}><a href = {`mailto:${em.value}`}>{em.value}</a><IconButton type = "copy" action = "copy" tooltip className = "copyButton" title = "Copy" value = {em.value}></IconButton> <div className = "contactNotes">{em.notes}</div><div className = "contactInfo">{[em.type=="Other"?null:em.type,em.status=="Other"?null:em.status].filter(function(value){return value!=null}).join(", ")}</div></div>)}</div>
                    }
                    if (data.addresses && data.addresses.length > 0) {
                        addresses = <div className = "vCardGroup"><div className = "vCardGroupHeader">Address:</div>{data.addresses.map(ad=>
                            <div className = "contactValue" key = {uuidv4()}>
                            <div className = "text-danger"><u>{ad.name}</u></div>
                            <div>{ad.street},</div>
                            <div>{ad.city}, {ad.state}, {ad.country}<IconButton type = "copy" action = "copy" tooltip className = "copyButton" title = "Copy" value = {`${ad.street}, ${ad.city}, ${ad.state}, ${ad.country}`}></IconButton></div>
                             </div>

                             )}
                        </div>      
                    }
                    if (data.fields && data.fields.length > 0) {
                        fields = data.fields.map(field => <div key = {uuidv4()} className = "vCardGroup"><div className = "vCardGroupHeader">{field.name}:</div>{field.content.map(ch=><div className = "contactValue" key = {uuidv4()}><div>{ch.value}</div> <div className = "contactNotes">{ch.notes}</div></div>)}</div>);
                    }
                } 
                else {
                    name = <div className = "vCardGroupEdit">

                            <PropertyAttribute key = {uuidv4()} value = {data.firstName} placeholder = "First Name (e.g. John)" path = {[...this.props.path,"firstName"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                            <PropertyAttribute key = {uuidv4()} value = {data.middleName} placeholder = "Middle Name (e.g. F.)" path = {[...this.props.path,"middleName"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                            <PropertyAttribute key = {uuidv4()} value = {data.lastName} placeholder = "Last Name (e.g. Smith)" path = {[...this.props.path,"lastName"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>

                        <div className = "contactValueEdit"></div></div>;

                    if (data.phoneNumbers) {
                        phoneNumbers = 
                        <React.Fragment key = {uuidv4()}>
                        <div className = "vCardGroupEdit"><div className = "vCardGroupHeader">Phone:</div>{data.phoneNumbers.map((pn,index)=>
                            <React.Fragment key = {uuidv4()}>
                <div className = "flexEditWrapper" key = {uuidv4()}>
                <div className = "contactValueEdit">
            <PropertyAttribute key = {uuidv4()} value = {pn.value} style = {{color:"blue",textDecoration:"underline"}} placeholder = {`Phone #${index+1} (e.g. 15253772848)`} path = {[...this.props.path,"phoneNumbers",index,"value"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
            <PropertyAttribute key = {uuidv4()} value = {pn.notes}  style = {{fontStyle:"italic"}}  placeholder = {`Phone #${index+1} Notes (e.g. VM Reached)`} path = {[...this.props.path,"phoneNumbers",index,"notes"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
            <PropertyListAttribute value = {pn.type} list = {ValueTypes.phone} placeholder = "Select Type" path = {[...this.props.path,"phoneNumbers",index,"type"]} onChange = {this.props.modifyProperty}></PropertyListAttribute>
            <PropertyListAttribute value = {pn.status} list = {ValueStatuses.phone} placeholder = "Select Status" path = {[...this.props.path,"phoneNumbers",index,"status"]} onChange = {this.props.modifyProperty}></PropertyListAttribute>
                </div>
    <IconButton key = {uuidv4()}  type = "delete" value = {[...this.props.path,"phoneNumbers",index]} onClick = {this.deleteProperty}></IconButton>
                </div>
                            </React.Fragment>
                            )}
                  <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" value = {[...this.props.path,"phoneNumbers"]} onClick = {this.addValue}></IconButton>
                </div>
                        </div>
                            </React.Fragment>
                    }

                    if (data.emails) {
                        emails = <React.Fragment key = {uuidv4()}><div className = "vCardGroupEdit"><div className = "vCardGroupHeader">Email:</div>{data.emails.map((em,index)=>
                          <React.Fragment key = {uuidv4()}>
                <div className = "flexEditWrapper" key = {uuidv4()}>
                <div className = "contactValueEdit">
            <PropertyAttribute key = {uuidv4()} value = {em.value} style = {{color:"blue",textDecoration:"underline"}} placeholder = {`Email #${index+1}`} path = {[...this.props.path,"emails",index,"value"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
            <PropertyAttribute key = {uuidv4()} value = {em.notes}  style = {{fontStyle:"italic"}}  placeholder = {`Email #${index+1} Notes`} path = {[...this.props.path,"emails",index,"notes"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
            <PropertyListAttribute value = {em.type} list = {ValueTypes.email} placeholder = "Select Type" path = {[...this.props.path,"emails",index,"type"]} onChange = {this.props.modifyProperty}></PropertyListAttribute>
            <PropertyListAttribute value = {em.status} list = {ValueStatuses.email} placeholder = "Select Status" path = {[...this.props.path,"emails",index,"status"]} onChange = {this.props.modifyProperty}></PropertyListAttribute>
                </div>
            <IconButton key = {uuidv4()}  type = "delete" value = {[...this.props.path,"emails",index]} onClick = {this.deleteProperty}></IconButton>
                </div>
    
                            </React.Fragment>

                            )}
                  <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" value = {[...this.props.path,"emails"]} onClick = {this.addValue}></IconButton>
                </div>
                        </div>
                        </React.Fragment>

                    }
                    if (data.addresses) {
                        addresses = <div className = "vCardGroupEdit"><div className = "vCardGroupHeader">Address:</div>{data.addresses.map((ad,index)=>
                                            <div className = "flexEditWrapper" key = {uuidv4()}>
                            <div className = "contactValueEdit" key = {uuidv4()}>

                            <PropertyAttribute key = {uuidv4()} value = {ad.name} placeholder = {`Address #${index+1} name (e.g. Home)`} path = {[...this.props.path,"addresses",index,"name"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                            <PropertyAttribute key = {uuidv4()} value = {ad.street} placeholder = {`Address #${index+1} street (e.g. 27 Oak Street)`} path = {[...this.props.path,"addresses",index,"street"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                            <PropertyAttribute key = {uuidv4()} value = {ad.city} placeholder = {`Address #${index+1} city (e.g. Boston)`} path = {[...this.props.path,"addresses",index,"city"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                            <PropertyAttribute key = {uuidv4()} value = {ad.state} placeholder = {`Address #${index+1} state (e.g. MA)`} path = {[...this.props.path,"addresses",index,"state"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                            <PropertyAttribute key = {uuidv4()} value = {ad.country} placeholder = {`Address #${index+1} country (e.g. USA)`} path = {[...this.props.path,"addresses",index,"country"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                             </div>
                                    <IconButton key = {uuidv4()}  type = "delete" value = {[...this.props.path,"addresses",index]} onClick = {this.deleteProperty}></IconButton>
                                    </div>
                             )}
                  <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" value = {[...this.props.path,"addresses"]} onClick = {this.addAddress}></IconButton>
                </div>
                        </div>      
                    }
                    if (data.fields) {
                        fields = <React.Fragment key = {uuidv4()}>{data.fields.map((field, fieldIndex) => 
                            <React.Fragment key = {uuidv4()}>
                                                    <div className = "flexEditWrapper" key = {uuidv4()}>
                            <div className = "vCardGroupEdit"><div className = "vCardGroupHeader">
                            {<PropertyAttribute key = {uuidv4()} value = {field.name} placeholder = {`Custom Field #${fieldIndex+1} name`} path = {[...this.props.path,"fields",fieldIndex,"name"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>}
                            </div>{field.content.map((ch,chIndex)=>
                                            <div className = "flexEditWrapper" key = {uuidv4()}>
                                <div className = "contactValueEdit">

                                        <PropertyAttribute key = {uuidv4()} value = {ch.value} placeholder = {`${field.name} #${chIndex+1} value`} path = {[...this.props.path,"fields",fieldIndex,"content",chIndex,"value"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                                        <PropertyAttribute key = {uuidv4()} value = {ch.notes} placeholder = {`${field.name} #${chIndex+1} notes`} path = {[...this.props.path,"fields",fieldIndex,"content",chIndex,"notes"]} onSubmit = {this.props.modifyProperty}></PropertyAttribute>
                             </div>
                        <IconButton key = {uuidv4()}  type = "delete" value = {[...this.props.path,"fields",fieldIndex,"content",chIndex]} onClick = {this.deleteProperty}></IconButton>
                             </div>
                             )}
                                                               <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" value = {[...this.props.path,"fields",fieldIndex,"content"]} onClick = {this.addValue}></IconButton>
                </div>

                            </div>
                                 <IconButton key = {uuidv4()}  type = "delete" value = {[...this.props.path,"fields",fieldIndex]} onClick = {this.deleteProperty}></IconButton>

                            </div>
        
                                </React.Fragment>
                            )
                            }

                        <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" value = {[...this.props.path,"fields"]} onClick = {this.addField}></IconButton>
                </div>
                            </React.Fragment>;

                            }

                    

                }

            }
        } catch (err) {
            console.log(err);
        }
        var collapse = <div className = "addWrapper">
        <IconButton type = "up" color = "gray" size = {2} onClick = {this.props.toggleOpen}></IconButton>
        </div>
        return <React.Fragment key = {uuidv4()}>
            <div className = "vCardPanel">
            {name}{phoneNumbers}{emails}{addresses}{fields}{collapse}
            </div>
</React.Fragment>

    }

}

export default VCardPanel;