import React from "react";

import Navigation from "./navigation";

import './landing.css';

import Icon from '@mdi/react'
import { mdiNinja, mdiEarth, mdiAccount, mdiTable,mdiLightningBolt, mdiPhoneForward, mdiPhone, mdiHome, mdiCurrencyUsd,mdiCoffin } from '@mdi/js'

import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import houses from './images/houses.jpg';
import leadMap from './images/leadmap.png';
import entries from './images/entries.png';
import entries2 from './images/entries2.png';
import contacts from './images/contacts.gif';
import events from './images/events.gif';
import crm from './images/crm.gif';
import map from './images/map.gif';


class Landing extends React.Component {

componentDidMount(){
  var carousel =  document.getElementById("featuresCarousel");
 carousel.addEventListener('slide.bs.carousel', function (e) {
  var to = e.to;
var featuresItems = document.getElementsByClassName("featuresItem");
for(var i = 0;i<featuresItems.length;i++){
var featuresItem = featuresItems[i];
if(i == to){
featuresItem.classList.add("active");
}
else{
  featuresItem.classList.remove("active");
}
}
  });
}
  render() {
    return <React.Fragment>
      <Navigation navState="home" />
      <div className="content">
        <div className="d-flex landingHero">
          <div className="landingHeroChild landingHeroHeading" style={{backgroundImage:`url('${leadMap}')`}}>
            <div className="landingHeroContent">
              <div className="landingHeroTitle">
              <div>Verified Leads</div>
              <div>For Real Estate</div>
              </div>
              <Link to="/solutions" className="btn btn-primary shadow landingButton">View Solutions</Link>
              <div className="landingHeroText">
                <div>Confirmed Contacts</div>
                <div>Sent Straight to your CRM</div>
              </div>
            </div>
          </div>
          <div className="landingVideoWrapper" style={{backgroundImage:`url('${leadMap}')`}}><iframe width="100%" height="100%" src="https://www.youtube.com/embed/H6yt8QisS94" className="landingHeroVideo" title="Ninja Contacts - Real Estate Leads" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
        <div className="landingSection pt-5">
          <div className="container-lg">
            <div className="landingBlurb">
              <h2 className="landingHeader">Confirmed Leads</h2>
              <div>We find, dial, and verify real estate leads, so you don't have to. No more wasted hours dialing disconnected numbers. No more sifting through old, slow government websites. Our team manually verifies and formats the contacts so that you can focus on what you do best - calling prospective leads.</div>
            </div>
          </div>
        </div>
        <div className="landingSection pt-5">
          <div className="container-lg">
            <h2 className="landingHeader">Features</h2>
            <div className="featuresRow">
              <div className="featuresItem active" data-bs-target="#featuresCarousel" data-bs-slide-to={0} title = "Confirmed Leads">
                <Icon className="featuresItemIcon" path={mdiTable}></Icon>
                <span className="featuresItemText ms-1">Confirmed Leads</span>
              </div>
              <div className="featuresItem" data-bs-target="#featuresCarousel" data-bs-slide-to={1} title = "Contacts List">
                <Icon className="featuresItemIcon" path={mdiAccount}></Icon>
                <span className="featuresItemText ms-1">Contacts List</span>
              </div>
              <div className="featuresItem" data-bs-target="#featuresCarousel" data-bs-slide-to={2} title = "Lead Map">
                <Icon className="featuresItemIcon" path={mdiEarth}></Icon>
                <span className="featuresItemText ms-1">Lead Map</span>
              </div>
              <div className="featuresItem" data-bs-target="#featuresCarousel" data-bs-slide-to={3} title = "CRM Integration">
                <Icon className="featuresItemIcon" path={mdiPhone}></Icon>
                <span className="featuresItemText ms-1">CRM Integration</span>
              </div>
              <div className="featuresItem" data-bs-target="#featuresCarousel" data-bs-slide-to={4} title = "Events">
                <Icon className="featuresItemIcon" path={mdiLightningBolt}></Icon>
                <span className="featuresItemText ms-1">Events</span>
              </div>
            </div>
            <div className="featuresCarousel carousel slide" id="featuresCarousel" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="featuresCarouselSlide">
                  <div className="featuresCarouselSlideChild featuresContent">
                    <div className = "featuresBlurb">
                      <div className="featuresHeader">
                      <div className="text-center">
                    <Icon className="featuresCarouselIcon" path={mdiTable}></Icon>
                    </div>
                  <span>Confirmed Leads</span>
                      </div>
                      <div className = "featuresList">
                        <div><span><b>Search</b> leads by date, location, type, and tags</span></div>
                        <div><span><b>Tags</b> help to filter your leads, such as On/Off Market</span></div>
                        <div><span><b>Contacts</b> are all manually verified by our team</span></div>
                      </div>
                      </div>
                      </div>
                    <div className="featuresCarouselSlideChild bg-black"><img width="100%" src={entries2}></img></div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="featuresCarouselSlide">
                  <div className="featuresCarouselSlideChild featuresContent">
                    <div className = "featuresBlurb">
                      <div className="featuresHeader">
                      <div className="text-center">
                    <Icon className="featuresCarouselIcon" path={mdiAccount}></Icon>
                    </div>
                  <span>Contacts List</span>
                      </div>
                      <div className = "featuresList">
                      <div><span><b>Add</b> relevant contacts to your contact list</span></div>
                        <div><span><b>Organize</b> contacts into groups</span></div>
                        <div><span><b>Export</b> contacts to .csv or directly to your CRM</span></div>
                      </div>
                      </div>
                      </div>
                    <div className="featuresCarouselSlideChild bg-black"><img width="100%" src={contacts}></img></div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="featuresCarouselSlide">
                  <div className="featuresCarouselSlideChild featuresContent">
                    <div className = "featuresBlurb">
                      <div className="featuresHeader">
                      <div className="text-center">
                    <Icon className="featuresCarouselIcon" path={mdiEarth}></Icon>
                    </div>
                  <span>Lead Map</span>
                      </div>
                      <div className = "featuresList">
                        <div><span><b>View</b> loaded entries on the <b>Lead Map</b></span></div>
                        <div><span><b>Find</b> relevant leads in your area</span></div>
                        <div><span><b>Add</b> leads to your contact list</span></div>
                      </div>
                      </div>
                      </div>
                    <div className="featuresCarouselSlideChild bg-black"><img width="100%" src={map}></img></div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="featuresCarouselSlide">
                  <div className="featuresCarouselSlideChild featuresContent">
                    <div className = "featuresBlurb">
                      <div className="featuresHeader">
                      <div className="text-center">
                    <Icon className="featuresCarouselIcon" path={mdiPhone}></Icon>
                    </div>
                  <span>CRM Integration</span>
                      </div>
                      <div className = "featuresList">
                        <div><span><b>Link</b> Ninja Contacts to your favorite CRM</span></div>
                        <div><span><b>Export</b> contacts directly to your CRM</span></div>
                        <div><span><b>Use</b> the exported leads with relevant information like tags</span></div>
                      </div>
                      </div>
                      </div>
                    <div className="featuresCarouselSlideChild  bg-black"><img width="100%" src={crm}></img></div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="featuresCarouselSlide">
                  <div className="featuresCarouselSlideChild featuresContent">
                    <div className = "featuresBlurb">
                      <div className="featuresHeader">
                      <div className="text-center">
                    <Icon className="featuresCarouselIcon" path={mdiLightningBolt}></Icon>
                    </div>
                  <span>Events</span>
                      </div>
                      <div className = "featuresList">
                        <div><span><b>Configure</b> listeners based on location, type, and tags</span></div>
                        <div><span><b>Create</b> exports to Email, Text, Contacts List, and CRM</span></div>
                        <div><span><b>Contacts</b> are automatically sent to your listener, immediately after they are verified by our team</span></div>
                      </div>
                      </div>
                      </div>
                    <div className="featuresCarouselSlideChild bg-black"><img width="100%" src={events}></img></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lg marketing">
      {/* Three columns of text below the carousel */}
      <h2 className="landingHeader pt-5">Our Lead Lists</h2>
      <div className="row">
        <div className="col-lg-4 text-center py-2">
          <Icon path = {mdiHome} className="landingListIcon"></Icon>
          <h2 className="fw-normal pt-2">Servicemember</h2>
          <p style = {{minHeight:"4em",display:"flex",alignItems:"center",justifyContent:"center"}}>
            Individuals whom lenders are preparing to foreclose on.
          </p>
          <p>
          <Link to="/solutions" className="btn btn-primary">View Solutions</Link>
          </p>
        </div>
        {/* /.col-lg-4 */}
        <div className="col-lg-4 text-center pt-2">
        <Icon path = {mdiCurrencyUsd} className="landingListIcon"></Icon>
          <h2 className="fw-normal pt-2">Tax Lien</h2>
          <p style = {{minHeight:"4em",display:"flex",alignItems:"center",justifyContent:"center"}}>
            Individuals who have a legal claim against their property as a result of not paying taxes.
          </p>
          <p>
          <Link to="/solutions" className="btn btn-primary">View Solutions</Link>
          </p>
        </div>
        {/* /.col-lg-4 */}
        <div className="col-lg-4 text-center py-2">
        <Icon path = {mdiCoffin} className="landingListIcon"></Icon>
          <h2 className="fw-normal pt-2">Probate (Coming Soon)</h2>
          <p style = {{minHeight:"4em",display:"flex",alignItems:"center",justifyContent:"center"}}>
            Individuals who have inherited property. 
          </p>
          <p>
          <Link to="/solutions" className="btn btn-primary">View Solutions</Link>
          </p>
        </div>
        {/* /.col-lg-4 */}
      </div>
      </div>
    <div style = {{height:"3em"}}></div>
        <footer className="bg-black">
        <div className="container-lg">
      <p className="text-white">
        © 2022 Ninja Contacts, Inc. · <Link to="privacy">Privacy</Link> ·{" "}
        <Link to="terms">Terms</Link>
      </p>
      </div>
    </footer>
      </div>

    </React.Fragment>
  }
}

export default Landing;