import React from "react";

import Collapsible from 'react-collapsible';

import ChangeGroup from './changegroup';

import IconButton from './iconbutton';

import CheckAttribute from './checkattribute';

import PropertyListAttribute from './propertylistattribute';

import PropertyAttribute from './propertyattribute';

import { v4 as uuidv4 } from "uuid";

import Icon from '@mdi/react'
import { mdiAlert} from '@mdi/js'


import ConfirmDialog from './confirmdialog';

import {RowTemplate,DataTypes,States} from '../constants';

class ManagerPanel extends React.Component {
    constructor(props){
    super(props);
    this.updateAddControls = this.updateAddControls.bind(this);
    this.submitAddGroup = this.submitAddGroup.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.deleteAttribute = this.deleteAttribute.bind(this);

    this.state = {addControls:{state:"MA",dataType:"Probate",caseNumber:""}};
    }
    updateAddControls(fieldName,value){
        this.setState(prevState=>({addControls:{...prevState.addControls,[fieldName]:value}}));
    }
    submitAddGroup(){  
        var addControls = this.state.addControls;
        var state = addControls.state;
        var dataType = addControls.dataType;
        var caseNumber = addControls.caseNumber;

        if(caseNumber.length>0){
        this.addGroup(state,dataType,caseNumber)
        }

    }
    addGroup(state,dataType,caseNumber){
        state = state.toUpperCase();
        caseNumber = caseNumber.toUpperCase();

        var id = `${state}:${caseNumber}`;

        var date = new Date();

        var formattedDate = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
        var newAttributes = {id:id,state:state,dataType:dataType,caseNumber:caseNumber,filledDate:formattedDate};
        var newEntry = Object.assign(RowTemplate(),newAttributes);
        this.props.addEntry(newEntry);
    }
    deleteGroup(id){
        this.props.removeChangeGroup(id);
    }

    deleteAttribute(id,attribute){
        this.props.removeChanges(id,attribute);
    }
    render() {
        var newEntries = this.props.newEntries;
        var changes = this.props.changes;

        var entryKeys = Object.keys(newEntries);
        var normalKeys = Object.keys(changes).filter(key=>!entryKeys.includes(key));

        var keys = [...entryKeys,...normalKeys];

        var entriesRevealing = 0;
        for(var i = 0;i<keys.length;i++){
            if(changes[keys[i]].active==true){
                entriesRevealing+=1;
            }
        }

        var warning;

        var addControls = this.state.addControls;


        var newEntryIds = Object.keys(newEntries);

        if(entriesRevealing>0){
            warning = <div className = "filterWrapper"><Icon path={mdiAlert} className = "iconButtonMedium alertIcon"/>Warning: {entriesRevealing} {entriesRevealing==1?"entry is":"entries are"} setting to 'active' to&nbsp;<span className = "changeGroupHeader">true</span>. {entriesRevealing==1?"This entry":"These entries"} will be revealed to subscribers and new entry hooks will be triggered.</div>
        }
        var controls = <React.Fragment>
            <div className = "filterWrapper">
            <label><CheckAttribute nameProp = "Only show modified rows" checkedProp = {this.props.showOnlyModified} onChangeProp = {this.props.toggleShowOnlyModified} ></CheckAttribute></label>
            </div>
            <div className = "filterWrapper">
            <label><CheckAttribute nameProp = "Show disabled entries" checkedProp = {this.props.showDisabled} onChangeProp = {this.props.toggleShowDisabled}></CheckAttribute></label>
            </div>
            <div className = "filterWrapper">
            <label><CheckAttribute nameProp = "Only show disabled entries" checkedProp = {this.props.showOnlyDisabled} onChangeProp = {this.props.toggleShowOnlyDisabled}></CheckAttribute></label>
            </div>
            <div className = "filterWrapper">
            <label><CheckAttribute nameProp = "Prompt Confirmation" checkedProp = {this.props.confirmPrompt} onChangeProp = {this.props.toggleConfirmPrompt}></CheckAttribute></label>
            </div>
            <div className = "filterWrapper"><ConfirmDialog trigger = {<button className = "dashButton btn btn-danger">Reset Changes</button>} disabled = {!this.props.confirmPrompt} onConfirm = {this.props.clearChanges} title = {`Reset Changes`} text = {`Are you sure you want to clear ${keys.length} changes?`}></ConfirmDialog></div>
            <div className = "filterWrapper"><ConfirmDialog trigger = {<button className = "dashButton btn btn-success">Push Changes</button>} disabled = {!this.props.confirmPrompt} onConfirm = {this.props.pushChanges} title = {`Push Changes`} text = {`Are you sure you want to push ${keys.length} changes to the server?`}></ConfirmDialog></div>
            </React.Fragment>
        

        return <React.Fragment>
                        <div className = "dataFilterWrapper">
            <Collapsible transitionTime = {100} open = {true} trigger = {<div className = "dataFilterHeader">Manager<IconButton className="expandedIcon" type = "collapsed"></IconButton></div>} triggerWhenOpen = {<div className = "dataFilterHeader">Manager<IconButton className="expandedIcon" type = "expanded"></IconButton></div>}>
            <div className = "dataFilterRow"><span className = "dataFilterHeader dataFilterHeaderCell">Add:</span>
            <span className = "filterContent filterFlex">
            <span className = "filterWrapper">
                <PropertyListAttribute name = "State" list = {States} onChange = {this.updateAddControls} value = {addControls.state} path = "state"></PropertyListAttribute>
            </span>
            <span className = "filterWrapper">
                <PropertyListAttribute name = "Type" list = {DataTypes} onChange = {this.updateAddControls} value = {addControls.dataType} path = "dataType"></PropertyListAttribute>
            </span>

            <span className = "filterWrapper">
                <PropertyAttribute name = "Case Number" className = "propertyInput" value = {addControls.caseNumber} placeholder = {`ex. 22 SM 001984`} path = "caseNumber" onSubmit = {this.updateAddControls}></PropertyAttribute>
            </span>
            <span className = "filterWrapper">
                <button className = "dashButton btn btn-primary" onClick = {this.submitAddGroup}>Add Entry</button>
            </span>
            </span>
                </div>
            <div className = "dataFilterRow"><span className = "dataFilterHeader dataFilterHeaderCell">Changes:</span>
            <span className = "filterContent filterFlex filterWrap">
            {keys.map(key=>

                <div className = "changeWrapper" key = {uuidv4()}>
                <ChangeGroup key = {uuidv4()} id = {key} data ={changes[key]} newEntry = {newEntryIds.includes(key)} onChange = {this.deleteAttribute}></ChangeGroup>
                 <IconButton key = {uuidv4()}  type = "delete" value = {key} onClick = {this.deleteGroup}></IconButton>
                </div>

                )}
        {warning}
            </span>
                </div>
                        <div className = "dataFilterRow"><span className = "dataFilterHeader dataFilterHeaderCell">Control:</span>
                        <span className = "filterContent filterFlex">
                           {controls}
                        </span></div>
                        </Collapsible>
</div>
</React.Fragment>
}

}
export default ManagerPanel;