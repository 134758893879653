import React from "react";


import ContactTable from '../components/contacttable';

import ListTypeInput from "../input/listtypeinput";

import ConfirmDialog from "../components/confirmdialog";

import ListAttribute from "../components/listattribute";

import Collapsible from 'react-collapsible';

import * as Helper from '../utils/helper';

import { ContactExportTypes} from '../constants';

import IconButton from "../components/iconbutton";

import { v4 as uuidv4 } from "uuid";

import PropertyAttribute from "./propertyattribute";

import NinjaClient from "../api/ninjaclient";

class ContactsGroup extends React.Component {
    constructor(props) {
        super(props);
        this.setContactSearch = this.setContactSearch.bind(this);
        this.selectContactGroup = this.selectContactGroup.bind(this);
        this.selectContact = this.selectContact.bind(this);
        this.getSelectedIds = this.getSelectedIds.bind(this);
        this.getSelectedContacts = this.getSelectedContacts.bind(this);
        this.removeSelected = this.removeSelected.bind(this);
        this.exportSelected = this.exportSelected.bind(this);
        this.moveSelected = this.moveSelected.bind(this);
        this.copySelected = this.copySelected.bind(this);
        this.clearSelected = this.clearSelected.bind(this);
        this.submitContacts = this.submitContacts.bind(this);

        var data = this.props.data;

        
        var selectCache = {};

        if(data!=null){
        var groupNames = Object.keys(data);
        groupNames.forEach(groupName => {
            var selectGroup = {};

            var contactGroup = data[groupName];
            contactGroup.forEach(contact => {
                selectGroup[contact.id] = false;
            });

            selectCache[groupName] = selectGroup;
        });
    }
        this.state = { data: data, selectCache: selectCache,contactSearch:"" };
    }
    setContactSearch(search){
        this.setState({contactSearch:search});
    }
    selectContactGroup(group, selected) {
        var data = this.props.data;
        this.setState(prevState => {
            var contactGroup = data[group];
            if (contactGroup == null) {
                contactGroup = {};
            }
            var selectGroup = {};
            contactGroup.forEach(contact => {
                selectGroup[contact.id] = selected;
            });
            var newSelectCache = { ...prevState.selectCache, [group]: selectGroup };
            var newState = { selectCache: newSelectCache };
            return newState;
        });
    }
    selectContact(group, id) {
        this.setState(prevState => {
            var newSelectCache = JSON.parse(JSON.stringify(prevState.selectCache));

            if (newSelectCache[group] == null) {
                newSelectCache[group] = {};
            }

            newSelectCache[group][id] = !newSelectCache[group][id];

            var newState = { selectCache: newSelectCache };
            return newState;
        });
    }
    getSelectedIds() {
        var selectCache = this.state.selectCache;

        var selectedCache = {};

        if (selectCache != null) {
            var groupNames = Object.keys(selectCache);
            groupNames.forEach(groupName => {

                var contactGroup = selectCache[groupName];
                var contactIds = Object.keys(contactGroup);
                contactIds.forEach(contactId => {
                    if (contactGroup[contactId]) {
                        if (selectedCache[groupName] == null) {
                            selectedCache[groupName] = [];
                        }
                        selectedCache[groupName].push(contactId);
                    }
                });
            });
        }
        return selectedCache;
    }
    getSelectedContacts(){
        var selectedCache = this.getSelectedIds();
        var data = this.state.data;
        var selectGroups = Object.keys(data);

        var allSelectedContacts = [];
        selectGroups.forEach(selectGroup => {
            var selected = selectedCache[selectGroup];
            if (selected != null) {
                var selectedContacts = data[selectGroup].filter(contact=>selected.includes(contact.id));
                allSelectedContacts=allSelectedContacts.concat(selectedContacts);
            }
        });  
        return allSelectedContacts;
    }
    removeSelected() {
        var selectedCache = this.getSelectedIds();

        this.clearSelected();

        this.setState(prevState => {
            var newData = JSON.parse(JSON.stringify(prevState.data));
            var selectGroups = Object.keys(newData);
            selectGroups.forEach(selectGroup => {
                var selected = selectedCache[selectGroup];
                if (selected != null) {
                    newData[selectGroup] = newData[selectGroup].filter(contact => !selected.includes(contact.id));
                    if (newData[selectGroup] && newData[selectGroup].length == 0 && selectGroup != "default") {
                        delete newData[selectGroup];
                        this.props.removeTableCache(selectGroup);
                    }
                }
            });
            var newState = { data: newData };
            return newState;
        }, this.submitContacts);
    }
    async exportSelected(type) {    
        
        var selectedContacts = this.getSelectedContacts();

        var selectedGroups = Object.keys(this.getSelectedIds());

        selectedGroups = selectedGroups.map(selectedGroup=>selectedGroup=="default"?"Contacts":selectedGroup);

        var fileName = `Ninja Contacts - ${selectedGroups.join(" + ")} ${new Date().toLocaleString()}${type}`;

        var formattedContacts = selectedContacts.map(contact=>{
            var formattedContact = {
                first_name: contact.first_name,
                last_name: contact.last_name,
                mobile_phone: contact.mobile_phone?.value,
                home_phone: contact.home_phone?.value,
                office_phone:contact.office_phone?.value,
                email:contact.email?.value,
                secondary_email:contact.secondary_email?.value,
                address:null,
                tags: contact.tags,
                state:contact.identifier?.state,
                city:contact.identifier?.city,
                dataType:contact.identifier?.dataType,
                caseNumber:contact.identifier?.caseNumber
            };
            if(contact.addresses.length>0){
                var address = contact.addresses[0];
                var addressString = `${address.street_address_1}, ${address.city}, ${address.state}`;
                formattedContact.address=addressString;
            }
            return formattedContact;
        });

        var userData = this.props.userData;

        if(formattedContacts.length>0){
        switch(type){
            case ".csv":
                var fields = Object.keys(formattedContacts[0]);
                var csvData = Helper.jsonToCsv(formattedContacts,fields);
                Helper.downloadFile(fileName,csvData);
            break;
            case ".json":
                Helper.downloadFile(fileName,JSON.stringify(formattedContacts));
            break;
            case "LionDesk":
                var response = await NinjaClient.exportContacts(selectedContacts,"LionDesk");

                if(userData.liondesk_session){
                if(Array.isArray(response)){
                    alert(`Sent ${response.length} contacts to your LionDesk CRM`);
                }
                else{
                    alert('Error');
                }
            }
            else{
                alert('Error: No Linked LionDesk, Go to "CRM".');
            }
            break;
        }
    }
    }
    moveSelected(group,keep=false) {
if(group=="Contacts"){
group = "default";
}
        var selectedCache = this.getSelectedIds();
        
        this.clearSelected();

        this.setState(prevState => {
            var newData = JSON.parse(JSON.stringify(prevState.data));
            var selectGroups = Object.keys(newData);

            var allSelectedContacts = this.getSelectedContacts();
            selectGroups.forEach(selectGroup => {
                var selected = selectedCache[selectGroup];
                if (selected != null) {
                    if(!keep){
                        newData[selectGroup] = newData[selectGroup].filter(contact=>!selected.includes(contact.id));
                        if(newData[selectGroup].length==0&&selectGroup!="default"){
                            delete newData[selectGroup];
                        }  
                    }
                }
            });
            if(!newData[group]){
                newData[group]=[];
                this.props.toggleTable(group,true);
            }
            var allSelectedIds = allSelectedContacts.map(contact=>contact.id);
            newData[group]=newData[group].filter(contact=>!allSelectedIds.includes(contact.id));
            newData[group]=[...allSelectedContacts,...newData[group]];
            if(newData[group].length==0&&group!="default"){
                delete newData[group];
                this.props.removeTableCache(group);
            }  
            var newState = { data: newData };
            return newState;
        }, this.submitContacts);
    }
    copySelected(group) {
        if(group=="Contacts"){
            group = "default";
            }
        this.moveSelected(group,true);
    }
    shiftSelected(down = true) {
        var selectedCache = this.getSelectedIds();

        var selectCache = this.state.selectCache;

        this.setState(prevState => {
            var newData = JSON.parse(JSON.stringify(prevState.data));
            var selectGroups = Object.keys(newData);

            var allSelectedGroups = [];
            selectGroups.forEach(selectGroup => {
                var selected = selectedCache[selectGroup];
                if (selected != null) {
                    if(down){
                        newData[selectGroup]=newData[selectGroup].reverse();
                    }
                    for(var i = 0;i<newData[selectGroup].length;i++){
                        var targetIndex = i-1;
                        if(targetIndex<newData[selectGroup].length&&(targetIndex>=0)&&selected.includes(newData[selectGroup][i].id)&&!selected.includes(newData[selectGroup][targetIndex].id)){
                        
                            var temp = newData[selectGroup][i];
                            newData[selectGroup][i]=newData[selectGroup][targetIndex];
                            newData[selectGroup][targetIndex]=temp;
                        }
                    }
                    if(down){
                        newData[selectGroup]=newData[selectGroup].reverse();
                    }


                    var selectKeys = Object.keys(selectCache[selectGroup]);
                    var allSelected = selectKeys.length>0;
                    selectKeys.forEach(key => {
                        if (!selectCache?.[selectGroup][key]) {
                            allSelected = false;
                        }
                    });
                    if(allSelected){
                        allSelectedGroups.push(selectGroup);
                    }

                }
            });
            
            if(down){
                selectGroups = selectGroups.reverse();
            }
            for(var i = 0;i<selectGroups.length;i++){
                var targetIndex = i-1;
                if(targetIndex>=0&&targetIndex<selectGroups.length&&allSelectedGroups.includes(selectGroups[i])&&!allSelectedGroups.includes(selectGroups[targetIndex]&&selectGroups[i]!="default"&&selectGroups[targetIndex]!="default")){
                    var temp = selectGroups[i];
                    selectGroups[i]=selectGroups[targetIndex]
                    selectGroups[targetIndex]=temp;
                }
            }
            if(down){
                selectGroups = selectGroups.reverse();
            }
            var sortedData = {};
            selectGroups.forEach(selectGroup=>{
                sortedData[selectGroup]=newData[selectGroup];
            });

            
            var newState = { data: sortedData };
            return newState;
        }, this.submitContacts);

    }
    clearSelected(){
        this.setState({selectCache:{}});
    }
    submitContacts() {
        this.props.updateContacts(this.state.data);
    }
    render() {


        var userData = this.props.userData;

        var manager = userData.manager;

        var data = this.state.data;

        var selectCache = this.state.selectCache;

        var selectCount = 0;

        var selectedCache = this.getSelectedIds();
        var groupNames = Object.keys(selectedCache);
        groupNames.forEach(groupName => {
            var selectedGroup = selectedCache[groupName];
            selectCount += selectedGroup.length;
        });

        var contactGroups = Object.keys(selectCache).map(contactGroup => contactGroup == "default" ? "Contacts" : contactGroup);


            var groupNames = Object.keys(data);

            groupNames = ["default", ...groupNames.filter(groupName => groupName != "default")];


            var contactSearch = <React.Fragment>
            <div className="d-flex justify-content-left align-items-center p-2">
            <label>Search: </label>
            <PropertyAttribute value = {this.state.contactSearch} style = {{textAlign:"center",marginLeft:"0.3em"}}  placeholder = "Search Contacts" onSubmit = {(path,value)=>this.setContactSearch(value)}></PropertyAttribute>
            </div>

        </React.Fragment>
        return <React.Fragment>
            <div className="dataFilterWrapper user-select-none">
                    <Collapsible transitionTime={100} open={true} trigger = {<div className = "dataFilterHeader">Controls<IconButton className="expandedIcon" type = "collapsed"></IconButton></div>} triggerWhenOpen = {<div className = "dataFilterHeader">Controls<IconButton className="expandedIcon" type = "expanded"></IconButton></div>}>
                        <div className="dataFilterRow">
                            <span className="filterWrapper">
                                <span className="d-flex align-items-center justify-content-center">
                                    <label className="me-1">Move {selectCount}  contact(s) to</label><ListTypeInput className="stateAdd" placeholder="Group" bypass={true} manual options={contactGroups} value={data.group} onChange={this.moveSelected}></ListTypeInput>
                                </span>
                            </span>
                            <span className="filterWrapper">
                                <span className="d-flex align-items-center justify-content-center">
                                    <label className="me-1">Copy {selectCount} contact(s) to</label><ListTypeInput className="stateAdd" placeholder="Group" bypass={true} manual options={contactGroups} value={data.group} onChange={this.copySelected}></ListTypeInput>
                                </span>
                            </span>
                            <span className="filterWrapper">
                                <ListAttribute nameProp={`Export ${selectCount} contact(s) to`} placeholder="Export Type" className="stateAdd" valueProp="" optionsProp={ContactExportTypes} onChangeProp={this.exportSelected}></ListAttribute>
                            </span>
                            <span className="filterWrapper" style={{ flex: 0.5 }}>
                            <IconButton type="eraser" onClick = {this.clearSelected} size={1.5}></IconButton>
                                <IconButton type="refresh" onClick = {e=>window.location.reload()} size={1.5}></IconButton>
                                <IconButton type="up" onClick = {e=>this.shiftSelected(false)} size={2}></IconButton>
                                <IconButton type="down" onClick = {e=>this.shiftSelected(true)} size={2}></IconButton>
                                <ConfirmDialog trigger={<IconButton type="trash" size={1.5}></IconButton>} onConfirm={this.removeSelected} title={`Remove Contacts`} text={`Are you sure you want to remove ${selectCount} contacts?`}></ConfirmDialog>
                            </span>
                        </div>
                    </Collapsible>
                </div>
                {contactSearch}
                {groupNames.map(groupName => <ContactTable key = {uuidv4()} contactSearch = {this.state.contactSearch} open = {this.props.openCache[groupName]} toggleTable = {this.props.toggleTable} name={groupName} selectCache={selectCache[groupName] != null ? selectCache[groupName] : {}} selectContactGroup={this.selectContactGroup} selectContact={this.selectContact} data={data[groupName]}></ContactTable>)}

        </React.Fragment>
    }
}

export default ContactsGroup;