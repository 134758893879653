import { v4 as uuidv4 } from "uuid";

export const ContactTemplate = () => {

    return {
        "emails": [
        ],
        "firstName": "",
        "lastName": "",
        "addresses": [
        ],
        "middleName": "",
        "id": uuidv4(),
        "fields": [
        ],
        "phoneNumbers": [
        ]
    }
}

export const ValueTemplate = () => {

    return {
        "value": "",
        "notes": "",
        "type": "",
        "status":""
    }
}

export const FieldTemplate = () => {

    return {
                "name": "",
                "content": [{
                        "value": "",
                        "notes": ""
                    }
                ]
            }
    
}

export const AddressTemplate = () => {
    return {
                "name": "",
                "country": "",
                "state": "",
                "city": "",
                "street": ""
            }
}