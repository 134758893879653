const devMode = window.location.host!="ninjacontacts.com"&&window.location.host!="www.ninjacontacts.com";

const stage = devMode?"dev":"prod";

const sharedValues = {
changesPath: ["manager","changes"],
newEntriesPath: ["manager","newEntries"],
caseInfoPath:["manager","caseInfo"],
listenersCachePath:["user","listeners"],
contactCachePath:["user","contactCache"],
mapKey:"AIzaSyC23qIa1mK6NVPRQCvCfEEydRv21yU9XTI",
}

const stageValues = {
    dev: {
        apiEndpoint: "https://api.ninjacontacts.com/dev/",
        lionDeskAuthUrl:"https://api.ninjacontacts.com/dev/crm/liondesk/auth"
    },
    prod: {
        apiEndpoint: "https://api.ninjacontacts.com/v1/",
        lionDeskAuthUrl:"https://api.ninjacontacts.com/v1/crm/liondesk/auth"
    }
}

export const Config = Object.assign(sharedValues, stageValues[stage]);


export var DataState = {};


loadState();
function loadState(){
	var data = localStorage.getItem("dataState");
	var parsedData = JSON.parse(data);
	if(parsedData!=null){
		DataState = parsedData;
	}
}

function saveState(){
	var dataString = JSON.stringify(DataState);
	localStorage.setItem("dataState",dataString);
}

export function setState(path,value){
if(Array.isArray(path)){
var parentStep = DataState;
var pointer = DataState;
for(var i = 0;i<path.length;i++){
var pathStep = path[i];
parentStep = pointer;
pointer = pointer[pathStep];

if(pointer==null){
parentStep[pathStep] = isFinite(pathStep)?[]:{};
pointer = parentStep[pathStep]; 
}
}

parentStep[path[path.length-1]] = value;
if(parentStep[path[path.length-1]]===undefined){
delete parentStep[path[path.length-1]];
}
}
else{
	DataState[path]=value;
	if(DataState[path]===undefined){
		delete DataState[path];
	}
}
saveState();
}

export function getState(path){
if(Array.isArray(path)){
var pointer = DataState;
for(var i = 0;i<path.length;i++){
var pathStep = path[i];
pointer = pointer[pathStep];
if(pointer==null){
return null;
}
}
return pointer;
}
else{
	return DataState[path];
}
}