
import React from "react";


import TextAreaAttribute from './textareaattribute';

class PropertyAttribute extends React.Component {
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.changed = this.changed.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.state = {value:this.props.value}
    }
    onSubmit(e){
        var onSubmit = this.props.onSubmit;
        var path = this.props.path;
        
        onSubmit(path,this.state.value);
    }
    onSubmitValue(e){
        var onSubmit = this.props.onSubmit;
        var path = this.props.path;
        onSubmit(path,e);
    }
    changed(value){
        this.setState({value:value});
    }

    handleInput(e) {
        if (e.key == "Escape") {
            this.onSubmit();
        }
    }
    render() {
        var changed = this.changed;
        var onKeyDown = this.onKeyDown;
        var value = this.state.value;
        var disabled = this.props.disabled;
        var onBlur = this.props.onBlur;
        var autoFocus = this.props.autoFocus;
        var placeholder = this.props.placeholder;
        var style = this.props.style;
        var className = this.props.className;
        var tabIndex = this.props.tabIndex;

        var list = this.props.list;
        if(value==null){
            value = "";
        }

            return <React.Fragment>

        <div className = "propertyAttributeWrapper">
       <TextAreaAttribute stiff autoFocus = {autoFocus} style = {style} placeholder = {placeholder} disabled = {disabled} className= {className?className:undefined} valueProp = {value} onChangeProp = {e=>{changed(e)}}  onKeyDownProp = {this.handleInput} onBlurProp = {this.onSubmit}></TextAreaAttribute>
       </div>

</React.Fragment>
    }
}

export default PropertyAttribute;