
import React from "react";


import './datatable.css';

import DataRow from './datarow';

import DataHeader from './dataheader';

import { v4 as uuidv4 } from "uuid";

import {RowTemplate} from '../constants';

import * as DataHelper from '../utils/datahelper';

class DataTable extends React.Component {

    render() {

        var data = this.props.data;
        var view = this.props.view;
        var dataRows = data.dataCache;
        var changes = this.props.changes;
        if(dataRows.length>0){
        var dataSlice = [...dataRows];
        var offset = 1;

        var contactCache = this.props.contactCache;

        if(contactCache==null){
            contactCache = {};
        }

        if(view.type=="Page"&&this.props.data.dataCache.length>0){
               var pageStart = this.clamp((view.page-1)*view.perPage,0,data.dataCache.length);
               var pageEnd = this.clamp(Number(pageStart)+Number(view.perPage),0,data.dataCache.length);
               offset = pageStart+1;
               dataSlice = dataSlice.slice(pageStart,pageEnd);
        }
        var performanceMode = dataSlice.length>=25;

        var newEntries = this.props.newEntries;

        var newEntryIds = Object.keys(newEntries);

        var addedEntries = newEntryIds.map(newEntryId=>Object.assign(RowTemplate(),changes[newEntryId]));

            return <React.Fragment>
            <table className = "dataTable border-none" id = "dataTable">
                    
            <tbody>
            
            <DataHeader userData = {this.props.userData} template = {dataSlice.length>0?dataSlice[0]:addedEntries.length>0?addedEntries[0]:null} key = {uuidv4()} data = {data} sortBy={this.props.sortBy}></DataHeader>
            {addedEntries.map(row=><DataRow userData = {this.props.userData} contactCache = {contactCache} toggleContacts = {this.props.toggleContacts} key = {uuidv4()} data = {row} filter = {this.props.data.filter} changes = {this.props.changes} newEntry = {true} addChanges = {this.props.addChanges} removeChanges = {this.props.removeChanges} performanceMode = {performanceMode} clickToCopy = {this.props.clickToCopy}></DataRow>)}
            {dataSlice.map(row=><DataRow userData = {this.props.userData} contactCache = {contactCache} toggleContacts = {this.props.toggleContacts} key = {uuidv4()} data = {row} filter = {this.props.data.filter} changes = {this.props.changes} addChanges = {this.props.addChanges} removeChanges = {this.props.removeChanges} performanceMode = {performanceMode} clickToCopy = {this.props.clickToCopy}></DataRow>)}
            
            </tbody>
            </table>
</React.Fragment>
    }
}
    clamp = (num, min, max) => Math.min(Math.max(num, min), max);
}

export default DataTable;