import React from "react";

import { v4 as uuidv4 } from "uuid";

class ListTypeInput extends React.Component {

    constructor(props) {
        super(props);
        this.submitOption = this.submitOption.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);

        var value = this.props.value;
        if(value==null){
            value = "";
        }
        this.state = {value:value}
    }

    submitOption(option) {
        var options = this.props.options;
        var bypass = this.props.bypass;
        if (options.includes(option)||bypass) {
            this.props.onChange(option);
        }
    }
    onChange(e) {
        var lengthDiff = Math.abs(e.target.value.length-this.state.value.length);
        if (this.props.uppercase) {
            e.target.value = e.target.value.toUpperCase();
        }
        this.setState({value:e.target.value});
        var options = this.props.options;
        if(options.includes(e.target.value)&&(!this.props.manual||lengthDiff>1)){
            this.setState({value:""});
            this.submitOption(e.target.value);
        }
    }
    handleInput(e) {
        if (e.key == "Escape" || e.key == "Enter"&&(e.target.value.length>0||this.props.allowEmpty)) {
                this.setState({value:""});
            this.submitOption(e.target.value);
        }
    }

    onBlur(e){
               if (e.target.value.length>0||this.props.allowEmpty) {
                this.setState({value:""});
            this.submitOption(e.target.value);
        }
    }
    render() {
        var name = this.props.name;
        var placeholder = this.props.placeholder;
        var value = this.state.value;
        var options = this.props.options;
        var changed = this.props.onChange;
        var className = this.props.className;
        var listId = uuidv4();
        var datalist = <datalist id = {listId}>{options.map(item=><option key = {uuidv4()} value = {item}/>)}</datalist>

        var optionsList = options.map(option => <option key = {uuidv4()} value = {option}>{option}</option>);


        return <React.Fragment>
         {name&&name.length>0?<label className = "me-1">{name}</label>:null}<input type = "text" value = {value} className = {className} placeholder = {placeholder} list = {listId} onChange = {this.onChange} onBlur = {this.onBlur} onKeyDown = {this.handleInput}/>{datalist}
</React.Fragment>
    }
}

export default ListTypeInput;