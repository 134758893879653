import React from "react";

import * as ListenerHelper from '../utils/listenerhelper';

import * as Editor from '../utils/editor';

import * as Helper from '../utils/helper';

import { v4 as uuidv4 } from "uuid";

import TextInput from '../input/textinput';

import PropertyAttribute from './propertyattribute';

import ListTypeInput from '../input/listtypeinput';

import ItemAttribute from './itemattribute';

import ListAttribute from './listattribute';

import { States, StateColors, DataTypes,DataTypeColors, Tags, ExportTypes } from '../constants';

import IconButton from './iconbutton';

import ConfirmDialog from './confirmdialog';

import ExportPanel from './exportpanel';

import Cities from '../data/cities';

class Listener extends React.Component {
    constructor(props) {
        super(props);
        this.focusCell = this.focusCell.bind(this);
        this.modifyProperty = this.modifyProperty.bind(this);
        this.submitCache = this.submitCache.bind(this);
        this.addState = this.addState.bind(this);
        this.removeState = this.removeState.bind(this);
        this.addCity = this.addCity.bind(this);
        this.removeCity = this.removeCity.bind(this);
        this.addDataType = this.addDataType.bind(this);
        this.removeDataType = this.removeDataType.bind(this);
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.addExport = this.addExport.bind(this);
        this.removeExport = this.removeExport.bind(this);
        this.modifyExport = this.modifyExport.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.resolveExports = this.resolveExports.bind(this);
        this.state = { data: this.props.data, editing: this.props.editing };
    }
    componentDidMount() {
        if (this.state.editing) {
            this.focusCell();
        }
    }

    focusCell() {
        var listenerElement = document.getElementById(this.state.data.id);
        listenerElement.focus();
    }

    modifyProperty(value, path, callback = null) {
        this.setState(prevState => {
            var newData = { ...prevState.data };
            Editor.setToValue(newData, value, path);
            var newState = { data: newData };
            return newState;

        }, callback);
    }
    submitCache() {
        this.props.modifyProperty(this.state.data, this.props.path);
    }

    addState(state) {
        var states = this.state.data.states;
        if (!states.includes(state)) {
            var newStates = [...states, state];
            var sortedStates = newStates.sort();
            this.modifyProperty(sortedStates, ["states"], this.submitCache);
        }

    }
    removeState(state) {
        var states = this.state.data.states;
        if (states.includes(state)) {
            var newStates = [...states];
            var stateIndex = newStates.indexOf(state);
            newStates.splice(stateIndex, 1);
            this.modifyProperty(newStates, ["states"], this.submitCache);

            var newCities = JSON.parse(JSON.stringify(this.state.data.cities));
            delete newCities[state];
            this.modifyProperty(newCities, ["cities"], this.submitCache);
        }

    }

    addCity(city, state) {
        state = state.toUpperCase();
        var states = this.state.data.states;
        var cities = this.state.data.cities;
        var newCities = JSON.parse(JSON.stringify(cities));

        if (states.includes(state)) {
            if (newCities[state] == null) {
                newCities[state] = [];
            }
            newCities[state].push(city);
            newCities[state] = newCities[state].sort();
            this.modifyProperty(newCities, ["cities"], this.submitCache);
        }

    }
    removeCity(city, state) {
        var cities = this.state.data.cities;
        var newCities = JSON.parse(JSON.stringify(cities));
        if (newCities[state] != null) {
            newCities[state] = newCities[state].filter(listCity => listCity != city);
            if (newCities[state].length == 0) {
                delete newCities[state];
            }
            this.modifyProperty(newCities, ["cities"], this.submitCache);
        }
    }

    addDataType(dataType) {
        var dataTypes = this.state.data.dataTypes;
        if (!dataTypes.includes(dataType)) {
            var newDataTypes = [...dataTypes, dataType];
            var sortedDataTypes = newDataTypes.sort();
            this.modifyProperty(sortedDataTypes, ["dataTypes"], this.submitCache);
        }

    }
    removeDataType(dataType) {
        var dataTypes = this.state.data.dataTypes;
        if (dataTypes.includes(dataType)) {
            var newDataTypes = [...dataTypes];
            var dataTypeIndex = newDataTypes.indexOf(dataType);
            newDataTypes.splice(dataTypeIndex, 1);
            this.modifyProperty(newDataTypes, ["dataTypes"], this.submitCache);
        }

    }


    addTag(tag) {
        var tags = this.state.data.tags;
        if (!tags.includes(tag)) {
            var newTags = [...tags, tag];
            var sortedTags = newTags.sort();
            this.modifyProperty(sortedTags, ["tags"], this.submitCache);
        }

    }
    removeTag(tag) {
        var tags = this.state.data.tags;
        if (tags.includes(tag)) {
            var newTags = [...tags];
            var tagIndex = newTags.indexOf(tag);
            newTags.splice(tagIndex, 1);
            this.modifyProperty(newTags, ["tags"], this.submitCache);
        }

    }
    addExport(type) {
        var exports = this.state.data.exports;
        var xport = ListenerHelper.ExportTemplate(type);
        if (xport != null) {
            var newExports = [...exports, xport];
            this.modifyProperty(newExports, ["exports"], this.submitCache);
        }
    }
    removeExport(xportIndex) {
        var exports = this.state.data.exports;
        var newExports = [...exports];
        newExports.splice(xportIndex, 1);
        this.modifyProperty(newExports, ["exports"], this.submitCache);

    }
    modifyExport(data, index) {
        var exports = this.state.data.exports;
        var newExports = JSON.parse(JSON.stringify(exports));
        newExports[index].data = data;
        this.modifyProperty(newExports, ["exports"], this.submitCache);
    }
    onBlur(e) {
        var element = document.getElementById(this.state.data.id);

        if (e.relatedTarget != null) {
            var dialog = e.relatedTarget.getAttribute("class").includes("MuiDialog") || e.relatedTarget.getAttribute("class").includes("MuiButton");
            if (element.contains(e.relatedTarget) || dialog) {
                return;
            }
        }
        this.props.setEditing(false, this.state.data.id);
    }
    resolveExports() {
        var data = this.state.data;
        var states = data.states;
        var cities = data.cities;
        var dataTypes = data.dataTypes;
        var tags = data.tags;
        var exports = data.exports;

        var marketStatusTags = ["On Market", "Off Market"].filter(function (tag) {
            return tags.includes(tag);
        });
        var otherTags = ["Deceased", "Estate"].filter(function (tag) {
            return tags.includes(tag);
        });
        var phoneStatusTags = ["Verified Number", "Unverified Active Numbers", "No Active Numbers"].filter(function (tag) {
            return tags.includes(tag);
        });

        var emailsTot = 0;
        var phoneNumbersTot = 0;
        var contactsGroups = [];
        var crms = [];

        var emailExports = [];
        var textExports = [];
        var contactsExports = [];
        var crmExports = [];


        for (var i = 0; i < exports.length; i++) {
            var xport = exports[i];

            switch (xport.type) {
                case "Email":
                    emailExports.push(xport);
                    break;
                case "Text":
                    textExports.push(xport);
                    break;
                case "Contacts":
                    contactsExports.push(xport);
                    break;
                case "CRM":
                    crmExports.push(xport);
                    break;
            }
        }

        emailExports.forEach(emailExport => emailsTot += emailExport.data.emails.length);

        textExports.forEach(textExport => phoneNumbersTot += textExport.data.phoneNumbers.length);

        contactsExports.forEach(contactsExport => contactsGroups.push(contactsExport.data.group.length > 0 ? contactsExport.data.group : "Default"));

        crmExports.forEach((crmExport) => { if (crmExport.data.CRM && crmExport.data.CRM.length > 0) { crms.push(crmExport.data.CRM) } });



        if (states.length == 0) {
            return "Invalid Listener: Please enter State(s)";
        }

        if (dataTypes.length == 0) {
            return "Invalid Listener: Please enter Type(s)";
        }
        if (exports.length == 0) {
            return "Invalid Listener: Please enter Export(s)";
        }
        var marketStatusString = `a market status of ${Helper.listify(marketStatusTags, " or ")}`;

        var descriptionString = `${Helper.pluralify("tag", otherTags.length)} ${Helper.listify(otherTags, " and ")}`;

        var phoneStatusString = `a contact status of ${Helper.listify(phoneStatusTags, " or ")}`;

        var validTagStrings = [];

        if (marketStatusTags.length > 0) {
            validTagStrings.push(marketStatusString);
        }
        if (otherTags.length > 0) {
            validTagStrings.push(descriptionString);
        }
        if (phoneStatusTags.length > 0) {
            validTagStrings.push(phoneStatusString);
        }

        var tagString = " ";
        if (validTagStrings.length > 0) {
            tagString = `, with ${Helper.listify(validTagStrings, ", and with ", ", and with ")} `;
        }

        var stateStrings = states.map(state=>{
            if(cities[state]==null||cities[state].length==0){
                return state;
            }
            else{
                return `${state} (in ${Helper.listify(cities[state])})`;
            }
        });

        var conditionString = `${Helper.listify(dataTypes)} that are verified in ${Helper.pluralify("state", stateStrings.length)} ${Helper.listify(stateStrings)}${tagString}`;

        var crmSpans = crms.map(crm => <span key={uuidv4()} style={{ color: "blue" }}>{crm}</span>)

        var emailSend = <span key={uuidv4()}>sent to <span style={{ color: "red" }}>{`${emailsTot} ${Helper.pluralify("email", emailsTot)}`}</span></span>;

        var textSend = <span key={uuidv4()}>texted to <span style={{ color: "red" }}>{`${phoneNumbersTot} ${Helper.pluralify("number", phoneNumbersTot)}`}</span></span>;

        var contactsSend = <span key={uuidv4()}>added to {Helper.pluralify("contact group", contactsGroups.length)} <span style={{ color: "blue" }}>{Helper.listify(contactsGroups)}</span></span>;

        var crmsSend = <span key={uuidv4()}>pushed to your {Helper.listifySpan(crmSpans)} {Helper.pluralify("CRM", crms.length)}</span>;

        var exportTypeSpans = [];

        if (emailsTot > 0) {
            exportTypeSpans.push(emailSend);
        }
        if (phoneNumbersTot > 0) {
            exportTypeSpans.push(textSend);
        }
        if (contactsGroups.length > 0) {
            exportTypeSpans.push(contactsSend);
        }
        if (crms.length > 0) {
            exportTypeSpans.push(crmsSend);
        }

        var exportsSpan;

        if (exportTypeSpans.length > 0) {
            exportsSpan = <span key={uuidv4()}>are {Helper.listifySpan(exportTypeSpans)}</span>;
        }

        var wraps = {};

        states.forEach(function (state) {
            wraps[state] = <span key={uuidv4()} style={{ color: StateColors[state] }}>{state}</span>;

            var stateCities = cities[state];
            if(stateCities!=null){
                stateCities.forEach(stateCity=>{
                    wraps[stateCity] = <span key={uuidv4()} style={{ color: StateColors[state] }}>{stateCity}</span>;
                });
            }
        });

        dataTypes.forEach(function (dataType) {
            wraps[dataType] = <span key={uuidv4()} style={{ color:DataTypeColors[dataType]?DataTypeColors[dataType]:Helper.stringToColor(dataType) }}>{dataType}</span>
        });

        ExportTypes.forEach(function (exportType) {
            wraps[exportType] = <span key={uuidv4()} style={{ color: Helper.stringToColor(exportType) }}>{exportType}</span>
        });

        var tagNames = Object.keys(Tags);
        tagNames.forEach(function (tagName) {
            wraps[tagName] = <span key={uuidv4()} style={{ color: Tags[tagName] }}>{tagName}</span>

        });

        var resolvedConditions = Helper.multiReplace(conditionString, wraps);

        var resolvedElement = <span key={uuidv4()}>{`Contacts of ${Helper.pluralify("type", dataTypes.length)} `}{resolvedConditions}{exportsSpan}</span>

        return resolvedElement;
    }
    render() {
        var data = this.state.data;


        var editing = this.state.editing;
        var deleteListener = this.props.deleteListener;
        var content;

        var stateNames = [...States];
        stateNames = stateNames.filter(function (state) { return !data.states.includes(state) });

        var dataTypeNames = [...DataTypes];
        dataTypeNames = dataTypeNames.filter(function (dataType) { return !data.dataTypes.includes(dataType) });

        var tagNames = Object.keys(Tags);
        tagNames = tagNames.filter(function (tag) { return !data.tags.includes(tag) && tag != "Disabled" });


        var cityOptions = [];

        data.states.forEach(state => {
            var stateCities = Cities[state];
            if (stateCities) {
                var formattedCities = stateCities.map(stateCity => `${stateCity}, ${state}`);
                cityOptions = cityOptions.concat(formattedCities);
            }
        });

        cityOptions = cityOptions.sort();

        var rowClass = "dataFilterRow";
        if (editing) {
            content = <React.Fragment>
                <div className="listenerHeader d-flex justify-content-between align-items-center">
                    <ConfirmDialog className="" trigger={<IconButton className="m-2" type="trash" title="Delete Listener" value={data.id}></IconButton>} onConfirm={e => { deleteListener(data.id) }} title={`Delete Listener`} text={`Are you sure you want to delete listener '${data.name}'?`}></ConfirmDialog>
                    <div className="text-center">
                        <PropertyAttribute style={{ width: "15em", textAlign: "center" }} value={data.name} onSubmit={(path, value) => { this.modifyProperty(value, path, this.submitCache) }} path={["name"]}></PropertyAttribute>
                    </div>
                    <IconButton type="done" title="Finish Editing" onClick={this.props.toggleEditing} value={data.id} className="m-2"></IconButton>
                </div>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">States:</div>
                    <span className="filterContent filterFlex flex-wrap">
                        <div className="ps-1">
                            <ListTypeInput placeholder="Add State" options={stateNames} onChange={this.addState} uppercase={true} className="stateAdd"></ListTypeInput>
                        </div>

                        {data.states.map(state => <ItemAttribute color={StateColors[state]} fontSize="1em" key={uuidv4()} className="stateItem" nameProp={state} onChangeProp={this.removeState} valueProp={state}></ItemAttribute>)}
                    </span>
                </div>

                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Cities:</div>
                    <span className="filterContent filterFlex flex-wrap">
                        <div className="ps-1">
                            <ListTypeInput placeholder="Add City" bypass={true} options={cityOptions} onChange={e => { var split = e.split(","); if (split.length >= 2) { var city = Helper.capitalizeWords(split[0].trim()); var state = split[1].trim().toUpperCase(); this.addCity(city, state) } }} className="stateAdd"></ListTypeInput>
                        </div>
                        {data.states.map(state => {
                            var cities = data.cities[state];
                            if (!cities) {
                                cities = [];
                            }
                            var cityItems = cities.map(city => <ItemAttribute key={uuidv4()} className="stateItem" nameProp={`${city}, ${state}`} onChangeProp={e => { var split = e.split(","); if (split.length >= 2) { var city = split[0].trim(); var state = split[1].trim(); this.removeCity(city, state) } }} valueProp={`${city}, ${state}`}></ItemAttribute>);
                            return cityItems;
                        })}
                    </span>
                </div>

                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Types:</div>
                    <span className="filterContent filterFlex">
                        <div className="ps-1">
                            <ListTypeInput placeholder="Add Type" options={dataTypeNames} onChange={this.addDataType} className="stateAdd"></ListTypeInput>
                        </div>
                        {data.dataTypes.map(dataType => <ItemAttribute fontSize="1em" key={uuidv4()} nameProp={dataType} onChangeProp={this.removeDataType} valueProp={dataType}></ItemAttribute>)}
                    </span>
                </div>


                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Tags:</div>
                    <span className="filterContent filterFlex">
                        <div className="ps-1">
                            <ListTypeInput placeholder="Add Tag" options={tagNames} onChange={this.addTag} className="stateAdd"></ListTypeInput>
                        </div>
                        {data.tags.map(tag => <ItemAttribute fontSize="0.8em" key={uuidv4()} tag={true} nameProp={tag} onChangeProp={this.removeTag} valueProp={tag}></ItemAttribute>)}
                    </span>
                </div>
                <span className={rowClass}><span className="dataFilterHeader listenerHeaderCell">Exports:</span>
                    <span className="filterContent filterFlex flex-wrap">
                        {data.exports.map((xport, index) => <ExportPanel key={uuidv4()} type={xport.type} data={xport.data} editing={editing} deleteExport={e => this.removeExport(index)} modifyExport={e => this.modifyExport(e, index)}></ExportPanel>)}
                        <div className="pb-2 w-100 text-center">
                            <ListTypeInput placeholder="Add Export" options={ExportTypes} onChange={this.addExport} className="stateAdd"></ListTypeInput>
                        </div>
                    </span>
                </span>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Result:</div>
                    <span className="filterContent filterFlex">
                        <span className="p-2">{this.resolveExports(data.exports)}</span>
                    </span>
                </div>
            </React.Fragment>
        } else {
            content = <React.Fragment>
                <div className="listenerHeader d-flex justify-content-between align-items-center">
                    <IconButton className="invisible m-2"></IconButton>
                    {data.name}
                    <IconButton className="m-2" type="edit" title="Edit Listener" onClick={this.props.toggleEditing} value={data.id}></IconButton>
                </div>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">States:</div>
                    <span className="filterContent filterFlex flex-wrap">
                        {data.states.map(state => <ItemAttribute fontSize="0.8em" key={uuidv4()} tag={true} nameProp={state}></ItemAttribute>)}
                    </span>
                </div>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Cities:</div>
                    <span className="filterContent filterFlex flex-wrap">
                        {data.states.map(state => {
                            var cities = data.cities[state];
                            if (!cities) {
                                cities = [];
                            }
                            var cityItems = cities.map(city => <ItemAttribute key={uuidv4()} fontSize = "0.8em" color = "white" background = {StateColors[state]} style = {{outline:"none"}} tag = {true} className="stateItem" nameProp={`${city}, ${state}`} valueProp={`${city}, ${state}`}></ItemAttribute>);
                            return cityItems;
                        })}
                    </span>
                </div>

                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Types:</div>
                    <span className="filterContent filterFlex">
                        {data.dataTypes.map(dataType => <ItemAttribute fontSize="0.8em" key={uuidv4()} tag={true} nameProp={dataType}></ItemAttribute>)}
                    </span>
                </div>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Tags:</div>
                    <span className="filterContent filterFlex">
                        {data.tags.map(tag => <ItemAttribute fontSize="0.8em" key={uuidv4()} tag={true} nameProp={tag}></ItemAttribute>)}
                    </span>
                </div>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Exports:</div>
                    <span className="filterContent filterFlex flex-wrap flex-grow-3">
                        {data.exports.map((xport, index) => <ExportPanel key={uuidv4()} type={xport.type} data={xport.data} editing={editing}></ExportPanel>)}
                    </span>
                </div>
                <div className={rowClass}><div className="dataFilterHeader listenerHeaderCell">Result:</div>
                    <span className="filterContent filterFlex">
                        <span className="p-2">{this.resolveExports(data.exports)}</span>
                    </span>
                </div>


            </React.Fragment>
        }



        return <React.Fragment>
            <div className={`${this.state.editing ? 'planSubscribed' : ''} dataFilterWrapper`} tabIndex={0} id={data.id} onBlur={this.onBlur} onDoubleClick={e => this.props.setEditing(true, data.id)}>
                {content}
            </div>
        </React.Fragment>
    }
}

export default Listener;