import React from "react";

import PriceGroup from '../components/pricegroup';

import NinjaClient from '../api/ninjaclient';

class PlanTab extends React.Component {
    constructor(props) {
        super(props);
        this.manageSubscriptions = this.manageSubscriptions.bind(this);
    }
    manageSubscriptions(){
        NinjaClient.manageSubscriptions();
    }

    render() {

        var userData = this.props.userData;

        var planControls;
        var planInfo;
                if (userData.subscriptions != null&&userData.subscriptions.length>0) {
           planControls = <button className = "dashButton btn btn-primary planControlButton" onClick = {this.manageSubscriptions}>Manage Subscriptions</button>;
           planInfo = <span className = "planControlInfo">Active Subscriptions:&nbsp;<span className = "text-danger">{userData.subscriptions.length}</span>&nbsp;</span>
        }
        return <React.Fragment>
        <div className = "dataContent">
<div className="container py-2">
                          <div className="row d-flex align-items-center">
                            <div className="col text-center">
                            {planInfo}
                            </div>
                          <div className="col text-center h1">
                            Subscriptions
                            </div>
                            <div className="col text-center">
                              {planControls}
                            </div>
                          </div>
                        </div>
    <PriceGroup plans = {this.props.plans} userData = {userData} interactive = {true}></PriceGroup>
        </div>
        </React.Fragment>
    }
}

export default PlanTab;