
import React from "react";

import IconButton from './iconbutton';


import {Tags,StateColors} from '../constants';

class ItemAttribute extends React.Component {

    render() {
        var name = this.props.nameProp;
        var changed = this.props.onChangeProp;
        var value = this.props.valueProp;
        var className = this.props.className;
        var tag = this.props.tag;
        var fontSize = this.props.fontSize;
        var color = this.props.color;
        var background = this.props.background;
        var style = {...this.props.style};
        if(tag == true){
            style.backgroundColor = Tags[name];
            if(Tags[name]!=null){
                style.color = "white";
                style.outline = "none";
            }
            else if(StateColors[name]!=null){
                style.backgroundColor = StateColors[name];
                style.color = "white";
                style.outline = "none";
            }
        }
        if(color){
            style.color = color;
        }
        if(background){
            style.backgroundColor = background;
        }
        if(fontSize!=null){
            style.fontSize = fontSize;
        }
        var onRemove = ()=>{};
        var deleteButton;
        var classes = ['item'];
        if(tag){
            classes.push('itemTag');
        }
        if(changed!=null){
        if(!tag){
            deleteButton = <IconButton type = "delete" size = {1} value = {value} onClick = {changed}></IconButton>;
        }
        else{
            classes.push('itemRemovable');
            onRemove = changed;
        }
    }

        var classString = classes.join(" ");

            return <React.Fragment>
            <span className = {classString} style = {style} onClick = {e=>onRemove(value)}>{deleteButton}{name}</span>

</React.Fragment>
    }
}

export default ItemAttribute;