import React from "react";

import './dashboard.css';

import DashboardMenu from "./dashboardmenu";

import DashboardContent from "./dashboardcontent";

import jsonFieldAliases from './data/fieldAliases';

import jsonFieldFilter from './data/fieldFilter';

import NinjaClient from './api/ninjaclient';

import * as DataHelper from './utils/datahelper';

import { Permissions } from './constants';

import { v4 as uuidv4 } from "uuid";

import * as Helper from "./utils/helper";


class DashboardContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: { dataCache: [], filter: jsonFieldFilter, fieldAliases: jsonFieldAliases },
            view: {
                types: ["Page", "All"],
                type: "Page",
                perPage: 5,
                page: 1
            },
            userData: { permissions: {}, manager: false },
            contactCache: {},
            plans: [],
        };
        this.generateContactCache = this.generateContactCache.bind(this);
        this.toggleContacts = this.toggleContacts.bind(this);
        this.updateContacts = this.updateContacts.bind(this);
        this.loadContacts = this.loadContacts.bind(this);
        this.toggleField = this.toggleField.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.sortEntries = this.sortEntries.bind(this);
        this.setViewType = this.setViewType.bind(this);
        this.setViewPage = this.setViewPage.bind(this);
        this.setViewPerPage = this.setViewPerPage.bind(this);
        this.retrieveData = this.retrieveData.bind(this);
        this.searchEntries = this.searchEntries.bind(this);
        this.loadEntries = this.loadEntries.bind(this);
        this.updateEntries = this.updateEntries.bind(this);
        this.sortCache = {};

    }

    componentDidMount() {
        this.retrieveData();
    }
    clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    async retrieveData() {
        var userData = await NinjaClient.getUserData();
        var plans = await NinjaClient.getPlans();
        var listeners = await NinjaClient.getListeners();

        listeners = Array.isArray(listeners)?listeners.map(listener=>Helper.sortKeys(listener)):[];
        
        var manager = userData.permissions[Permissions.MODIFY_ENTRIES] || userData.permissions[Permissions.ADMIN];

        var contacts = JSON.parse(JSON.stringify(userData.contacts));
        var contactCache = this.generateContactCache(contacts);

        DataHelper.setState(DataHelper.Config.contactCachePath, contactCache);

        this.setState({ userData: { ...userData, manager: manager,contacts:contacts },contacts:contacts, plans: plans, listeners: listeners, contactCache: contactCache });
    }
    generateContactCache(contacts) {
        var contactCache = {};
        var groupNames = Object.keys(contacts);
        groupNames.forEach((groupName) => {
            var group = contacts[groupName];
            group.forEach((contact) => {
                contactCache[contact.id] = true;
            });
        });
        return contactCache;
    }
    async toggleContacts(ids) {
        var contactCache = this.state.contactCache;

        var newCache = {...contactCache};

        ids.forEach(id=>newCache[id]=!newCache[id]);

        this.setState({contactCache:newCache});

        var idsToAdd = [];
        var idsToRemove = [];

        ids.forEach(id=>{
            if(!contactCache[id]){
                idsToAdd.push(id);
            }
            else{
                idsToRemove.push(id);
            }
        });

        var results = {...this.state.contacts};

        if(results==null){
            results = {};
        }

        if(idsToAdd.length>0){
        results = await NinjaClient.addContacts(idsToAdd);
        }
        if(idsToRemove.length>0){
            var contacts = JSON.parse(JSON.stringify(results));
            var groupNames = Object.keys(contacts);
            groupNames.forEach((groupName) => {
                contacts[groupName] = contacts[groupName].filter(contact=>!idsToRemove.includes(contact.id));
            });
            results = await NinjaClient.postContacts(contacts);
        }


        this.loadContacts(results);
    }
    async updateContacts(contacts) {
        var results = await NinjaClient.postContacts(contacts);
        this.loadContacts(results);
    }
    loadContacts(contacts) {
        var contactCache = this.generateContactCache(contacts);
        this.setState(prevState=>({userData:{...prevState.userData,contacts:contacts},contacts:contacts,contactCache:contactCache}));
    }
    setViewType(type) {
        if (this.state.view.types.includes(type)) {
            this.setState(prevState => ({
                ...prevState,
                view: {
                    ...prevState.view,
                    type: type
                }
            }));
        }
    }

    setViewPage(page) {
        this.setState(prevState => ({
            ...prevState,
            view: {
                ...prevState.view,
                page: page
            }
        }));

    }

    setViewPerPage(perPage) {
        this.setState(prevState => ({
            ...prevState,
            view: {
                ...prevState.view,
                perPage: perPage
            }
        }));
    }

    toggleField(field) {
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                filter: {
                    ...prevState.data.filter,
                    visible: {
                        ...prevState.data.filter.visible,
                        [field]: !prevState.data.filter.visible[field]
                    }
                }
            }
        }))
    }

    sortEntries(data, field, ascending) {
        switch (field) {
            case "filledDate":
                if (ascending) {
                    var sortedAsc = data.sort(
                        (objA, objB) => Number(new Date(objA.filledDate)) - Number(new Date(objB.filledDate)) || Number(objA.caseNumber.split(" ")[objA.caseNumber.split(" ").length - 1]) - Number(objB.caseNumber.split(" ")[objB.caseNumber.split(" ").length - 1])
                    );
                    return sortedAsc;
                } else {
                    var sortedDesc = data.sort(
                        (objA, objB) => Number(new Date(objB.filledDate)) - Number(new Date(objA.filledDate)) || Number(objB.caseNumber.split(" ")[objB.caseNumber.split(" ").length - 1]) - Number(objA.caseNumber.split(" ")[objA.caseNumber.split(" ").length - 1])
                    );
                    return sortedDesc;
                }
                break;
        }
        return data;
    }

    sortBy(field) {
        if (this.sortCache[field] == null) {
            this.sortCache[field] = false;
        } else {
            this.sortCache[field] = !this.sortCache[field];
        }
        var ascending = this.sortCache[field];
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                dataCache: this.sortEntries(prevState.data.dataCache, field, ascending)
            }
        }))
    }

    async searchEntries(states,cities, dataTypes, visibilityTypes = [], tags = [], timeStart, timeEnd) {
        var retrievedEntries = await NinjaClient.getEntries(states,cities, dataTypes, visibilityTypes, tags, timeStart, timeEnd);

        if (retrievedEntries != null) {
            this.loadEntries(retrievedEntries);
        } else {
            alert("Error: Invalid query or permissions.");
        }

    }

    loadEntries(entries, sort = true, sortAscending = false, resetPage = true) {
        for (var i = 0; i < entries.length; i++) {
            var entry = entries[i];
            if (!entry.id) {
                entry.id = uuidv4();
            }
        }
        if (sort) {
            entries = this.sortEntries(entries, "filledDate", sortAscending);
        }
        this.setState(prevState => ({ data: { ...prevState.data, dataCache: entries }, view: { ...prevState.view, page: resetPage ? 1 : prevState.view.page } }));
    }

    updateEntries(entries) {
        var entryCache = {}
        for (var i = 0; i < entries.length; i++) {
            var entry = entries[i];
            if (!entry.id) {
                entry.id = uuidv4();
            }
            entryCache[entry.id] = entry;
        }


        var newEntries = [...this.state.data.dataCache];
        for (var i = 0; i < newEntries.length; i++) {
            var entry = newEntries[i];
            if (entryCache[entry.id] != null && entryCache[entry.id] != false) {
                newEntries[i] = entryCache[entry.id];
                entryCache[entry.id] = false;
            }
        }

        var sort = false;
        var sortAscending = false;


        var addedEntryIds = Object.keys(entryCache).filter(entryKey => entryCache[entryKey] != false);
        if (addedEntryIds.length > 0) {
            for (var i = 0; i < addedEntryIds.length; i++) {
                var addedEntry = entryCache[addedEntryIds[i]];
                newEntries.push(addedEntry);
            }
            sort = true;
            sortAscending = this.sortCache["filledDate"];
        }

        this.loadEntries(newEntries, sort, sortAscending, false);



    }

    render() {
        return <React.Fragment><div className="dashContainer"><div className="dashMenuWrapper"><DashboardMenu></DashboardMenu></div><div className="dashContentWrapper">
            <DashboardContent data={this.state.data} contacts = {this.state.contacts} toggleContacts={this.toggleContacts} updateContacts={this.updateContacts} contactCache={this.state.contactCache} toggleField={this.toggleField} sortBy={this.sortBy} view={this.state.view} setViewType={this.setViewType} setViewPage={this.setViewPage} setViewPerPage={this.setViewPerPage} searchEntries={this.searchEntries} updateEntries={this.updateEntries} userData={this.state.userData} plans={this.state.plans} listeners={this.state.listeners} retrieveData={this.retrieveData}></DashboardContent></div></div></React.Fragment>
    }
}

export default DashboardContainer;