import React from "react";

import Icon from '@mdi/react'
import { mdiNinja } from '@mdi/js'

import DataTable from '../components/datatable';

import DataFilter from '../components/datafilter';

import DataSearch from '../components/datasearch';

import ManagerPanel from '../components/managerpanel';

import PageTurner from '../components/pageturner';

import TextInput from "../input/textinput";

import PropertyAttribute from "../components/propertyattribute";

import './datatab.css';

import { Auth } from 'aws-amplify';

import NinjaClient from '../api/ninjaclient';

import { Permissions } from '../constants';

import { Link } from "react-router-dom";

import * as DataHelper from '../utils/datahelper';

class DataTab extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {


        var pagination;
        if (this.props.view.type == "Page" && this.props.data.dataCache.length > 0) {
            pagination = <div className = "pageTurnerWrapper"><PageTurner data = {this.props.data} view = {this.props.view} setViewPage = {this.props.setViewPage}></PageTurner></div>;
        }
        var userData = this.props.userData

        var manager = userData.manager;

        var managerPanel;

        if (manager) {
            managerPanel = <ManagerPanel newEntries = {this.props.newEntries} changes = {this.props.changes} addEntry = {this.props.addEntry} addChanges = {this.props.addChanges} removeChanges = {this.props.removeChanges} removeChangeGroup = {this.props.removeChangeGroup} clearChanges = {this.props.clearChanges} pushChanges = {this.props.pushChanges} showOnlyModified = {this.props.showOnlyModified} toggleShowOnlyModified = {this.props.toggleShowOnlyModified} showDisabled = {this.props.showDisabled} toggleShowDisabled = {this.props.toggleShowDisabled} showOnlyDisabled = {this.props.showOnlyDisabled} toggleShowOnlyDisabled = {this.props.toggleShowOnlyDisabled} confirmPrompt = {this.props.confirmPrompt} toggleConfirmPrompt = {this.props.toggleConfirmPrompt}></ManagerPanel>;
        }


        var dataView = <div className = "contentCentered heading">Loading...</div>;
        if (userData.subscriptions != null) {
           dataView =  <div className = "contentCentered heading"><span className = "text-center">No plan found<br></br><br></br>Subscribe to a&nbsp;<Link to = "/dashboard/plan">plan</Link></span></div>
        }
        if (manager || userData.permissions[Permissions.GET_ENTRIES]) {
            var searchText = this.props.dataSearch;
            var dataSearch;
            if(this.props.view.type=="Page"){
                dataSearch = <React.Fragment>
                    <div className="d-flex justify-content-left align-items-center p-2">
                    <label>Search: </label>
                    <TextInput placeholder = "Search Entries" style = {{textAlign:"center",marginLeft:"0.3em"}} onChange = {this.props.setDataSearch} value = {searchText}></TextInput>
                    </div>

                </React.Fragment>
            }
            else{
                dataSearch = <React.Fragment>
                <div className="d-flex justify-content-left align-items-center p-2">
                <label>Search: </label>
                <PropertyAttribute value = {searchText} style = {{textAlign:"center",marginLeft:"0.3em"}}  placeholder = "Search Entries" onSubmit = {(path,value)=>this.props.setDataSearch(value)}></PropertyAttribute>
                </div>

            </React.Fragment>
            }

            dataView = <div className = "dataTableWrapper" style={{fontSize:`calc(${window.screen.width*0.01}px)`}}>

                    <DataSearch searchEntries = {this.props.searchEntries} userData = {this.props.userData}></DataSearch>

                    <DataFilter data = {this.props.data} view = {this.props.view} toggleField = {this.props.toggleField} setViewType = {this.props.setViewType} setViewPage = {this.props.setViewPage} setViewPerPage = {this.props.setViewPerPage} userData = {this.props.userData} clickToCopy = {this.props.clickToCopy} toggleClickToCopy = {this.props.toggleClickToCopy}></DataFilter>

                    {managerPanel}

                    {dataSearch}

                    <DataTable data = {this.props.data} view = {this.props.view} sortBy = {this.props.sortBy} userData = {this.props.userData} newEntries = {this.props.newEntries} changes = {this.props.changes} addChanges = {this.props.addChanges} removeChanges = {this.props.removeChanges} showOnlyModified = {this.props.showOnlyModified} clickToCopy = {this.props.clickToCopy} toggleContacts = {this.props.toggleContacts} contactCache = {this.props.contactCache}></DataTable>
                    {pagination}
                </div>
        } 
        return <React.Fragment>
            <div className = "dataContent">
                {dataView}
            </div>
</React.Fragment>
    }
}

export default DataTab;