import React from "react";

import TextInput from "../input/textinput"

import PropertyListAttribute from "./propertylistattribute";

import PropertyTextAreaAttribute from "./propertytextareaattribute";

import IconButton from "./iconbutton";

import NinjaClient from '../api/ninjaclient';

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.updateForm = this.updateForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {sending:false,sent:false,sentStatus:null};
    }
    updateForm(value,property){
        this.setState({[property]:value});
    }
    async handleSubmit(){
        this.setState({sending:true});
        var state = this.state;
        var info = {
            First:state.first,
            Last:state.last,
            Email:state.email,
            Phone:state.phone,
            Company:state.company,
            Role:state.role,
            Source:state.source,
            Inquiry:state.inquiry
        }
        var results = await NinjaClient.sendInquiry(info);
        this.setState({sent:true,sentStatus:results});
    }
    render() {
        var state = this.state;
        var buttonEnabled = state.first&&state.last&&(state.email||state.phone)&&state.role&&state.source&&!state.sending;
        return <React.Fragment>

<div className="contactBox shadow">
<div className="contactInner">
    {state.sent?<React.Fragment>
        <div className="d-flex justify-content-center align-items-center h1">Success!<IconButton type = "done" color = "limegreen"></IconButton></div>
        <div className="d-flex justify-content-center align-items-center">A representative will contact you soon.</div>
    </React.Fragment>:<React.Fragment>
    <div className="d-flex justify-content-center align-items-center h1">Contact Us</div>
    <div className="d-flex justify-content-center">Sales/Inquiries&nbsp;<a href = "mailto:frederick.blake@ninjacontacts.com">frederick.blake@ninjacontacts.com</a></div>
<div className="text-center">
<div className="d-flex p-2">
    <div className="col px-2"><TextInput onChange = {this.updateForm} path = "first" className="w-100" placeholder = "First"></TextInput></div>
    <div className="col px-2"><TextInput onChange = {this.updateForm} path = "last" className="w-100" placeholder = "Last"></TextInput></div>
</div>
<div className="d-flex p-2">
    <div className="col px-2"><TextInput onChange = {this.updateForm} path = "email" className="w-100" placeholder = "Email"></TextInput></div>
    <div className="col px-2"><TextInput onChange = {this.updateForm} path = "phone" className="w-100" placeholder = "Phone"></TextInput></div>
</div>
<div className="d-flex p-2">
    <div className="col px-2"><TextInput onChange = {this.updateForm} path = "company" className="w-100" placeholder = "Company"></TextInput></div>
</div>
<div className="d-flex p-2">
    <div className="col px-2"><PropertyListAttribute onChange = {(property,value)=>this.updateForm(value,property)} value = {this.state.role} path = "role" list = {["Realtor","Investor","Broker","Other"]} className="roleSelect w-100" placeholder = "Select Role"></PropertyListAttribute></div>
</div>
<div className="d-flex p-2">
    <div className="col px-2"><PropertyListAttribute onChange = {(property,value)=>this.updateForm(value,property)}  value = {this.state.source} path = "source" list = {["Google/Search Engine","Referral","Email","Phone","Other"]} className="roleSelect w-100" placeholder = "How did you hear about us?"></PropertyListAttribute></div>
</div>
<div className="d-flex p-2">
    <div className="col px-2"><PropertyTextAreaAttribute onSubmit = {(property,value)=>this.updateForm(value,property)}  value = {this.state.inquiry} path = "inquiry" className = "contactInquiry" placeholder = "Inquiry/Product Interest"></PropertyTextAreaAttribute></div>
</div>
<div className="d-flex p-2">
    <div className="col px-2"><button disabled = {!buttonEnabled} className={`contactButton btn w-100${state.sending?" btn-success":" btn-primary"}`} onClick={this.handleSubmit}>{state.sending?"Sending...":"Contact Us"}</button></div>
</div>
</div>
        </React.Fragment>}

</div>
</div>
</React.Fragment>
    }
}

export default ContactForm;