import React from "react";

import Listener from './listener';

import * as Editor from '../utils/editor';

import { ListenerTemplate } from '../utils/listenerhelper';

import { v4 as uuidv4 } from "uuid";

import ConfirmDialog from './confirmdialog';

import NinjaClient from '../api/ninjaclient';

import * as DataHelper from '../utils/datahelper';

import * as Helper from '../utils/helper';


import Icon from '@mdi/react'
import { mdiAlert} from '@mdi/js'

import { Permissions } from "../constants";

import { Link } from "react-router-dom";

class ListenerGroup extends React.Component {
    constructor(props) {
        super(props);
        this.saveChanges = this.saveChanges.bind(this);
        this.clearChanges = this.clearChanges.bind(this);
        this.modifyProperty = this.modifyProperty.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.setEditing = this.setEditing.bind(this);
        this.addListener = this.addListener.bind(this);
        this.deleteListener = this.deleteListener.bind(this);
        this.listenerData = this.props.data;

        var data = DataHelper.getState(DataHelper.Config.listenersCachePath);

        if(data==null){
            data = [...this.listenerData];
        }

        this.state = { data: data, editCache: {} };
    }
    async saveChanges() {
        var listeners = await NinjaClient.postListeners(this.state.data);
        listeners = listeners.map(listener=>Helper.sortKeys(listener));
        DataHelper.setState(DataHelper.Config.listenersCachePath,null);
        this.setState({data:listeners});

        this.listenerData = listeners;
        this.props.retrieveData();
    }
    clearChanges() {
        DataHelper.setState(DataHelper.Config.listenersCachePath,null);
        this.setState({data:this.listenerData,editCache:{}});
        
    }
    modifyProperty(value, path) {
        this.setState(prevState => {
            var newData = [...prevState.data];
            Editor.setToValue(newData, value, path);

            DataHelper.setState(DataHelper.Config.listenersCachePath,newData);
            
            var newState = { data: newData };
            return newState;

        });
    }
    addListener() {
        this.setState(prevState => {
            var newListener = ListenerTemplate();
            newListener.name = `New Listener ${prevState.data.length+1}`;
            var newData = [...prevState.data,newListener];

            DataHelper.setState(DataHelper.Config.listenersCachePath,newData);

            var newEditCache = {...prevState.editCache,[newListener.id]:true};
            var newState = { data: newData,editCache:newEditCache };

            return newState;

        });
    }
    deleteListener(id) {
        this.setState(prevState => {
            var newData = [...prevState.data];
            newData = newData.filter(function(listener) {
                return listener.id != id;

            });
            
            DataHelper.setState(DataHelper.Config.listenersCachePath,newData);
            var newState = { data: newData };
            return newState;

        });
    }
    toggleEditing(id) {

        this.setState(prevState => ({ editCache: { ...prevState.editCache, [id]: !prevState.editCache[id] } }));
    }
    setEditing(editing,id) {

        this.setState(prevState => ({ editCache: { ...prevState.editCache, [id]: editing } }));
    }
    render() {
        
        var listeners = this.state.data;

        var base = JSON.stringify(listeners);

        var delta = JSON.stringify(this.listenerData);

        var changed = (base!=delta)&&DataHelper.getState(DataHelper.Config.listenersCachePath)!=null;

        var saveButton;
        var clearButton;
        var changesWarning;
        if (changed) {
            saveButton = <div className = "col-sm text-center">
<ConfirmDialog trigger = {<button className = "dashButton btn btn-success">Save Changes</button>} onConfirm = {this.saveChanges} title = {`Save Changes`} text = {`Are you sure you want to save your changes?`}></ConfirmDialog>
</div>;

clearButton = <div className = "col-sm text-center">
<ConfirmDialog trigger = {<button className = "dashButton btn btn-danger">Clear Changes</button>} onConfirm = {this.clearChanges} title = {`Clear Changes`} text = {`Are you sure you want to reset your changes?`}></ConfirmDialog>
</div>;

changesWarning = <div className = "col-sm text-center">
<Icon path={mdiAlert} className = "iconButtonMedium alertIcon"/>Warning: Changes must be saved to take effect.
</div>
        }

var userData = this.props.userData;


var unauthorizedExports = [];
listeners.forEach(listener=>{
listener.states.forEach(state=>{
    listener.dataTypes.forEach(dataType=>{
        var permission = Permissions?.LISTS[state][dataType];
        var authorized = userData.permissions[permission];
        if(!authorized){
            unauthorizedExports.push(`${state} ${dataType}`);
        }
    });
});
});

var permissionsWarning;
if(unauthorizedExports.length>0){
    permissionsWarning = <div className = "col-sm text-center">
    <Icon path={mdiAlert} className = "iconButtonMedium alertIcon"/>Warning:&nbsp;<Link to = "/dashboard/plan">Your plan</Link>&nbsp;does not include the lists <span className="text-danger">{Helper.listify(unauthorizedExports)}</span>. These contacts will not be exported.
    </div>
    
}

        return <React.Fragment>
        <div className = "container">{listeners.map((listener,index)=>
            <Listener key = {uuidv4()} data = {listener} path = {[index]} modifyProperty = {this.modifyProperty} editing = {this.state.editCache[listener.id]} toggleEditing = {this.toggleEditing} setEditing = {this.setEditing} deleteListener = {this.deleteListener}></Listener>
            
            )}</div>
        <div className = "container">
<div className = "container">
<div className = "row my-3">
 {clearButton}
<div className = "col-sm text-center">
<button className = "dashButton btn btn-primary" onClick = {this.addListener}>Add New Listener</button>
 </div>
 {saveButton}
</div>
{permissionsWarning}{changesWarning}
</div></div>
        </React.Fragment>
    }
}

export default ListenerGroup;