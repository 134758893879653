import React from "react";

import PropertyAttribute from './propertyattribute';

import ItemAttribute from './itemattribute';

import IconButton from './iconbutton';

import { v4 as uuidv4 } from "uuid";

import { Tags } from '../constants';

import ListTypeInput from '../input/listtypeinput';

class TagGroup extends React.Component {
    constructor(props) {
        super(props);

        this.modifyTag = this.modifyTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.addTag = this.addTag.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.submitTags = this.submitTags.bind(this);
        this.state = { tags: this.props.data };
    }
    modifyTag(index, value) {

        var newTags = [...this.state.tags];
        newTags[index] = value;
        this.props.onChange(newTags);
        var newState = { tags: newTags };
        this.setState(newState);

    }
    submitTags() {
        this.props.onChange(this.state.tags);
    }
    addTag(tag) {
        var tags = this.state.tags;
        if (!tags.includes(tag)) {
            var newTags = [...tags, tag];
            var sortedTags = newTags.sort();
            var newState = { tags: newTags };
            this.setState(newState,this.submitTags);
        }

    }

    deleteTag(index) {
        this.setState(prevState => {
            var newTags = [...prevState.tags];
            newTags.splice(index, 1);
            var newState = { tags: newTags };
            return newState
        },this.submitTags);

    }

    onBlur(e) {
        var related = e.relatedTarget;
        if (related) {
            return;
        }
        var onBlur = this.props.onBlur;
        onBlur(e);
    }

    render() {
        var changed = this.props.onChange;
        var tags = this.state.tags;
        var tagNames = Object.keys(Tags);
        tagNames = tagNames.filter(tagName=>!tags.includes(tagName));
        return <React.Fragment>
        <div className = "tagWrapper" tabIndex = {0} autoFocus onBlur = {this.onBlur}>
<ListTypeInput placeholder = "Add Tag" options = {tagNames} onChange = {this.addTag} bypass = {true} className = "stateAdd"></ListTypeInput>
                     <div className = "tagHandleWrapper">{tags.map((tg,index)=>
<ItemAttribute key = {uuidv4()} fontSize = "0.8em" tag = {true} nameProp = {tg} onChangeProp = {this.deleteTag} valueProp = {index}></ItemAttribute>          
                )}
                     </div>
                  <div className = "addWrapper" key = {uuidv4()}>
                <IconButton key = {uuidv4()}  type = "add" onClick = {this.addTag}></IconButton>
                </div>
                </div>
</React.Fragment>
    }
}

export default TagGroup;