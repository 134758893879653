
import React from "react";

import './contacttable.css';

import IconButton from './iconbutton';

import ItemAttribute from './itemattribute';

import CheckAttribute from "./checkattribute";

import { v4 as uuidv4 } from "uuid";

import Icon from "@mdi/react";

class ContactCell extends React.Component {

    constructor(props) {
        super(props);
        this.renderCell = this.renderCell.bind(this);
    }
    renderStatusIcon(status) {
        switch (status) {
            case "Confirmed":
                return <IconButton type="done" color="limegreen" title="Confirmed"></IconButton>
            case "Unverified":
                return <IconButton type="question" color="#FFBF00" title="Unverified Active"></IconButton>
            case "Inactive":
                return <IconButton type="cancel" color="red" title="Inactive"></IconButton>
        }
    }
    renderTypeIcon(type) {
        switch (type) {
            case "Mobile":
                return <IconButton title="Mobile Number" color="blue" type="cellphone"></IconButton>
            case "Home":
                return <IconButton title="Home Number" color="brown" type="phone"></IconButton>
            case "Office":
                return <IconButton title="Office Number" color="black" type="office"></IconButton>
        }
    }
    renderEmailTypeIcon(type) {
        switch (type) {
            case "Primary":
                return <IconButton title="Primary Email" type="primary"></IconButton>
            case "Secondary":
                return <IconButton title="Secondary Email" type="secondary"></IconButton>
            case "Personal":
                return <IconButton title="Personal Email" type="personal"></IconButton>
            case "Work":
                return <IconButton title="Work Email" type="work"></IconButton>
            case "Other":
                return <IconButton title="Other Email" type="other"></IconButton>
        }
    }
    renderCell() {
        var name = this.props.name;
        var content = this.props.content;


        if (content == null) {
            return;
        }
        var renderedCell;
        switch (name) {
            case "first_name":
                renderedCell = <React.Fragment><span>{content}</span></React.Fragment>
                break;
            case "last_name":
                renderedCell = <React.Fragment><span>{content}</span></React.Fragment>
                break;
            case "phones":
                var mobile;
                var home;
                var office;
                if (content.mobile_phone) {
                    var phone = content.mobile_phone;
                    mobile = <span key = {uuidv4()} className={`contactCellValue`}>
                        {this.renderTypeIcon(phone?.type)}
                        {this.renderStatusIcon(phone?.status)}
                        <a className={`${phone?.status}`} title={`${phone?.notes}`} href={`tel:${phone?.value}`}>{phone?.value}</a>
                        <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Phone" value={phone?.value}></IconButton>
                    </span>
                }
                if (content.home_phone) {
                    var phone = content.home_phone;
                    home = <span key = {uuidv4()} className={`contactCellValue`}>
                        {this.renderTypeIcon(phone?.type)}
                        {this.renderStatusIcon(phone?.status)}
                        <a className={`${phone?.status}`} title={`${phone?.notes}`} href={`tel:${phone?.value}`}>{phone?.value}</a>
                        <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Phone" value={phone?.value}></IconButton>
                    </span>
                }
                if (content.office_phone) {
                    var phone = content.office_phone;
                    office = <span key = {uuidv4()} className={`contactCellValue`}>
                        {this.renderTypeIcon(phone?.type)}
                        {this.renderStatusIcon(phone?.status)}
                        <a className={`${phone?.status}`} title={`${phone?.notes}`} href={`tel:${phone?.value}`}>{phone?.value}</a>
                        <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Phone" value={phone?.value}></IconButton>
                    </span>
                }
                renderedCell = <span key = {uuidv4()} className="contactGroup">{mobile}{home}{office}</span>
                break;
            case "additional_phones":
                renderedCell = <React.Fragment>
                    <span className="contactGroup">
                        {content.map(phone =>
                            <span key = {uuidv4()} className={`contactCellValue`}>
                                {this.renderTypeIcon(phone?.type)}
                                {this.renderStatusIcon(phone?.status)}
                                <a className={`${phone?.status}`} title={`${phone?.notes}`} href={`tel:${phone?.value}`}>{phone?.value}</a>
                                <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Phone" value={phone?.value}></IconButton>
                            </span>

                        )}</span>
                </React.Fragment>
                break;
            case "emails":
                var primary_email;
                var secondary_email;
                if (content.email) {
                    var email = content.email;
                    primary_email = <div key = {uuidv4()} className={`contactCellContent ${email?.status}`}>
                        {this.renderEmailTypeIcon(email?.type)}
                        <a className={`${email?.status}`} title={`${email?.notes}`} href={`mailto:${email?.value}`}>{email?.value}</a>
                        <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Email" value={email?.value}></IconButton>
                    </div>
                }
                if (content.secondary_email) {
                    var email = content.secondary_email;
                    secondary_email = <div key = {uuidv4()} className={`contactCellContent ${email?.status}`}>
                        {this.renderEmailTypeIcon(email?.type)}
                        <a className={`${email?.status}`} title={`${email?.notes}`} href={`mailto:${email?.value}`}>{email?.value}</a>
                        <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Email" value={email?.value}></IconButton>
                    </div>
                }
                renderedCell = <React.Fragment>
                    <span className="contactGroup">{primary_email}{secondary_email}</span>
                </React.Fragment>
                break;
            case "additional_emails":
                renderedCell = <React.Fragment>
                    {content.map(email =>
                        <div key = {uuidv4()} className={`contactCellContent ${email?.status}`}>
                            {this.renderEmailTypeIcon(email?.type)}
                            <a className={`${email?.status}`} title={`${email?.notes}`} href={`mailto:${email?.value}`}>{email?.value}</a>
                            <IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Email" value={email?.value}></IconButton>
                        </div>

                    )}
                </React.Fragment>
                break;
            case "addresses":
                renderedCell = <React.Fragment>
                    <div>
                        {content.map(address =>
                            <span key = {uuidv4()} className="d-flex flex-nowrap align-items-center justify-content-center"><span>{address.street_address_1}, {address.city}, {address.state}</span><IconButton type="copy" action="copy" tooltip className="copyButton" title="Copy Address" value={`${address.street_address_1}, ${address.city}, ${address.state}`}></IconButton></span>

                        )}
                    </div>
                </React.Fragment>
                break;
            case "identifier":
                renderedCell = <React.Fragment>
                    <div>{content.caseNumber}</div>
                </React.Fragment>
                break;
            case "tags":
                var tags = content.split(",");

                renderedCell = <React.Fragment>
                    <span className="">{tags.map(tagName => <ItemAttribute key={uuidv4()} fontSize="0.8em" tag={true} nameProp={tagName} valueProp={tagName}></ItemAttribute>)}</span>
                </React.Fragment>
                break;
            case "select":
                renderedCell = <React.Fragment>
                    <CheckAttribute checkedProp = {content}></CheckAttribute>
                </React.Fragment>
                break;
            default:
                renderedCell = content?.toString();
                break;
        }
        return renderedCell;

    }

    render() {

        var name = this.props.name;

        var onClick;
        if(name=="select"){
            onClick = this.props.selectContact;
        }

        return <React.Fragment>
            <td onClick = {onClick} className={`contactCell ${name}`}>{this.renderCell()}</td>
        </React.Fragment>
    }
}

export default ContactCell;