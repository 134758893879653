
import React from "react";

import Collapsible from 'react-collapsible';

import './contacttable.css';

import ContactRow from './contactrow';

import CheckAttribute from "./checkattribute";

import { v4 as uuidv4 } from "uuid";

import IconButton from "./iconbutton";

class ContactTable extends React.Component {

    constructor(props) {
        super(props);
        this.selectContact = this.selectContact.bind(this);

    }

    selectContact(id) {
        var group = this.props.name;
        this.props.selectContact(group, id);
    }

    render() {

        var name = this.props.name;
        var data = this.props.data;

        var selectCache = this.props.selectCache;

        var selectKeys = Object.keys(selectCache);

        var allSelected = selectKeys.length > 0;

        selectKeys.forEach(key => {
            if (!selectCache[key]) {
                allSelected = false;
            }
        });

        if(data==null){
            data = [];
        }

        var dataTot = data.length;
        var contactSearch = this.props.contactSearch.trim().toLowerCase();
        if(contactSearch.length>0){
            data = data.filter(contact=>{
                var matchString = `${contact.first_name} ${contact.last_name} ${contact.mobile_phone?.value} ${contact.home_phone?.value} ${contact.office_phone?.value} ${contact.additional_phones.map(phone=>phone?.value).join(" ")} ${contact.email?.value} ${contact.secondary_email?.value} ${contact.additional_emails.map(email=>email?.value).join(" ")} ${contact.addresses.map(address=>`${address.street_address_1}, ${address.city}, ${address.state}`).join(" ")} ${contact.identifier.caseNumber} ${contact.tags.replaceAll(","," ")}`.toLowerCase();
                console.log(matchString);
                return matchString.includes(contactSearch);
            });
        }

        var countString = data.length==dataTot?`(${dataTot})`:`(${data.length}/${dataTot})`
        return <React.Fragment>
            <Collapsible transitionTime={100} open={this.props.open} handleTriggerClick = {e=>this.props.toggleTable(name)} trigger={<div className="contactTableHeader h1 pointer-none">{(name == "default" ? "Contacts" : name)+` ${countString}`}<IconButton type = "collapsed"></IconButton></div>} triggerWhenOpen={<div className="contactTableHeader h1 pointer-none">{name == "default" ? "Contacts" : name} {countString}<IconButton type = "expanded"></IconButton></div>}>
                <div className="contactTableWrapper">
                    <table className="contactTable border-none">

                        <tbody>
                            <tr className="dataHeader">
                                <th className="contactCellHeader">First</th>
                                <th className="contactCellHeader">Last</th>
                                <th className="contactCellHeader">Phones</th>
                                <th className="contactCellHeader">Extra Phones</th>
                                <th className="contactCellHeader">Emails</th>
                                <th className="contactCellHeader">Extra Emails</th>
                                <th className="contactCellHeader">Addresses</th>
                                <th className="contactCellHeader">Entry</th>
                                <th className="contactCellHeader">Tags</th>
                                <th className="contactCellHeader" onClick={e => this.props.selectContactGroup(name, !allSelected)}><CheckAttribute checkedProp={allSelected}></CheckAttribute></th>
                            </tr>
                            {data.map(row => <ContactRow key={uuidv4()} data={row} selected={selectCache[row.id]} selectContact={this.selectContact}></ContactRow>)}
                        </tbody>
                    </table>
                </div>
            </Collapsible>

        </React.Fragment>
    }
}

export default ContactTable;