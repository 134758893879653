
import React from "react";

import * as DataHelper from '../utils/datahelper';

import { Marker,InfoWindow,Circle } from '@react-google-maps/api';

import { v4 as uuidv4 } from "uuid";

import VCardGroup from "./vcardgroup";

import IconButton from "./iconbutton";

import { DataTypeColors } from "../constants";

class MapRender extends React.Component{
constructor(props){
    super(props);
    this.openInfo = this.openInfo.bind(this);
    this.closeInfo = this.closeInfo.bind(this);
    this.infoLoaded = this.infoLoaded.bind(this);
    this.renderInfo = this.renderInfo.bind(this);
    this.state = {infoCache:{}};
    this.infoCountCache = {};
}
openInfo(entryId){
    this.setState(prevState=>({infoCache:{...prevState.infoCache,[entryId]:true}}));
}
closeInfo(entryId){
    this.setState(prevState=>({infoCache:{...prevState.infoCache,[entryId]:false}}));
}
infoLoaded(infoWindow,id){
if(this.infoCountCache[id]==null){
    this.infoCountCache[id]=0;
}
if(this.infoCountCache[id]>0){
infoWindow.content?.parentElement?.parentElement?.parentElement?.parentElement?.remove();
}
this.infoCountCache[id]++;
}
renderInfo(entry){
if(this.state.infoCache[entry.id]){
    var contactCache = this.props.contactCache;

    if(contactCache==null){
        contactCache = {};
    }
    var address = `${entry.street}, ${entry.city}, ${entry.state}`;
return <InfoWindow key = {uuidv4()} position={entry.location} onCloseClick = {e=>this.closeInfo(entry.id)} onLoad = {e=>this.infoLoaded(e,entry.id)}>
    <div className = "overflow-hidden">
    <div className="text-center">Type: {entry.dataType}</div>
    <div className="text-center">Case: {entry.caseNumber}<IconButton type = "copy" action = "copy" tooltip className = "copyButton" title = "Copy Case Number" value = {entry.caseNumber}></IconButton></div>
    <div className="text-center">Filed: {entry.filledDate}</div>
    <div className="text-center">{address}<IconButton type = "copy" action = "copy" tooltip className = "copyButton" title = "Copy Address" value = {address}></IconButton></div>
   <div>
   <VCardGroup entry = {entry} toggleContacts = {this.props.toggleContacts} contactCache = {this.props.contactCache} rowActive = {entry.active} performanceMode = {false} data = {entry.contacts}></VCardGroup>
    </div>
    </div>
    </InfoWindow>
}
}
render(){
    var entries = this.props.entries;
    var contactCache = this.props.contactCache;

    var location = this.props.location;
    var locationMarker;
    if(location){
        locationMarker = <Marker icon = {window.google.maps.SymbolPath.Circle}
        position={this.props.center}>
       </Marker>
    }

    return <React.Fragment>
        <InfoWindow position={this.props.center} onLoad = {e=>e.close()}><div>Your Location</div></InfoWindow>
        {locationMarker}
        { entries.map((entry,index)=>{

        var added = false;
        entry.contacts.forEach(contact=>{
            if(contactCache[contact.id]){
                added = true;
            }
        });
        var typeColor = DataTypeColors[entry.dataType]?DataTypeColors[entry.dataType]:"#0C6EFD";
        var pinColor = added?"#16d404":typeColor;
    
        var pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
        var labelOriginFilled =  new window.google.maps.Point(12,9);
    
    
        var markerImage = {
            path: pinSVGFilled,
            anchor: new window.google.maps.Point(12,17),
            fillOpacity: 1,
            fillColor: pinColor,
            strokeWeight: 1,
            strokeColor: "white",
            scale: 2,
            labelOrigin: labelOriginFilled
        };

        var labelText = "";
        switch(entry.dataType){
            case "Servicemember":
            labelText = "SM"
            break;
            case "Tax Lien":
            labelText = "TL"
            break;
            case "Probate":
            labelText = "PB"
            break;
        }
        return <Marker key = {uuidv4()} icon = {markerImage} label={{text:labelText,className:"text-white font-weight-bold"}}
        position={entry.location}
        onClick = {e=>this.openInfo(entry.id)}>
            {this.renderInfo(entry)}
       </Marker>
        }
    )}
        
    </React.Fragment>
}
}

export default MapRender;