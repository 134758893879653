import React from "react";

import "./exportpanel.css";

import IconButton from "./iconbutton";

import ConfirmDialog from './confirmdialog';

import PropertyTextAreaAttribute from './propertytextareaattribute';

import PropertyListAttribute from './propertylistattribute';

import ListTypeInput from '../input/listtypeinput';

import {CRMTypes} from '../constants';

import {v4 as uuidv4} from 'uuid';

class ExportPanel extends React.Component {
    constructor(props) {
        super(props);
        this.modifyValue = this.modifyValue.bind(this);
        this.modifyLineList = this.modifyLineList.bind(this);
        this.state = { data: this.props.data }
    }
    modifyValue(fieldName, value) {
        var newData = JSON.parse(JSON.stringify(this.state.data));
        newData[fieldName] = value;
        this.props.modifyExport(newData);
    }
    modifyLineList(fieldName,valueString){
        if(valueString!=null){
        var newData = JSON.parse(JSON.stringify(this.state.data));

        var values = valueString.trim().split("\n");
        values = values.map(value=>value.trim());

        values = values.filter(value=>value.length>0);
        newData[fieldName] = values;

        this.props.modifyExport(newData);
    }
    }
    render() {
        var exportType = this.props.type;
        var editing = this.props.editing;
        var data = this.state.data;

        var contactGroups = [];

        var exportContent;
        var deleteButton;

        var basis = "exportFull";

        var exportHeaderText = `Export to ${exportType}`;
        if (editing) {
            switch (exportType) {
                            case "Email":
                basis = "exportFull";
                var emailsString = data.emails.join("\n");
                    exportContent = <React.Fragment>
                    <div className = "d-flex justify-content-evenly w-100 flex-nowrap">
                    <div className = "w-100 px-2">
                    <div className = "text-center">Emails ({data.emails.length})</div>
                    
                    <PropertyTextAreaAttribute className = "lineListInput" placeholder = "Enter Emails (One Per Line)" onSubmit = {this.modifyLineList} value = {emailsString} path = "emails"></PropertyTextAreaAttribute>

                    </div>
                    <div className = "w-100 px-2">
                    <div className = "text-center">Subject Template</div>
                    <PropertyTextAreaAttribute className = "lineListInput" placeholder = "Enter Email Subject Template. Use {EXPORTED_COUNT} and {EXPORTED_ALIASES}" onSubmit = {this.modifyValue} value = {data.subjectTemplate} path = "subjectTemplate"></PropertyTextAreaAttribute>
                    </div>
                    <div className = "w-100 px-2">
                    <div className = "text-center">Body Template</div>
                    <PropertyTextAreaAttribute className = "lineListInput" placeholder = "Enter Email Body Template. Use {EXPORTED_COUNT} and {EXPORTED_ALIASES}" onSubmit = {this.modifyValue} value = {data.bodyTemplate} path = "bodyTemplate"></PropertyTextAreaAttribute>
                    </div>
                    </div>
                    </React.Fragment>
                    break;
                        case "Text":
                basis = "exportFull";
                var phoneNumbersString = data.phoneNumbers.join("\n");
                    exportContent = <React.Fragment>

                    <div className = "d-flex justify-content-evenly w-100 flex-nowrap">
                    <div className = "w-100 px-2">
                    <div className = "text-center">Phone Numbers ({data.phoneNumbers.length})</div>
                    <PropertyTextAreaAttribute className = "lineListInput" placeholder = "Enter Phone Numbers (One Per Line)" onSubmit = {this.modifyLineList} value = {phoneNumbersString} path = "phoneNumbers"></PropertyTextAreaAttribute>
                    </div>
                    <div className = "w-100 px-2">
                    <div className = "text-center">Template</div>
                    <PropertyTextAreaAttribute className = "lineListInput" placeholder = "Enter Text Template. Use {EXPORTED_COUNT} and {EXPORTED_ALIASES}" onSubmit = {this.modifyValue} value = {data.template} path = "template"></PropertyTextAreaAttribute>
                    </div>
                    </div>
                    </React.Fragment>
                    break;
                case "Contacts":
                basis = "exportHalf";
                    exportContent = <React.Fragment>

                    <ListTypeInput name = "Group" placeholder = "Default Group" bypass = {true} manual allowEmpty options = {contactGroups} value = {data.group} onChange = {e=>this.modifyValue("group",e)} className = "exportListTypeInput"></ListTypeInput>
                    </React.Fragment>
                    break;
                case "CRM":
                basis = "exportHalf";
                    exportContent = <React.Fragment>
                <PropertyListAttribute name = "CRM" placeholder = "Select CRM" list = {CRMTypes} onChange = {this.modifyValue} value = {data.CRM} path = "CRM"></PropertyListAttribute>

                </React.Fragment>
                    break;
                default:
                    exportContent = JSON.stringify(data);
                    break;
            }
            deleteButton = <ConfirmDialog trigger = {<IconButton type = "delete"></IconButton>} onConfirm = {this.props.deleteExport} title = {`Delete Export`} text = {`Are you sure you want to delete this export?`}></ConfirmDialog>;
        } else {
            switch (exportType) {
                                      case "Email":
                basis = "exportFull";

                    exportContent = <React.Fragment>
                    <div className = "d-flex justify-content-evenly w-100 flex-nowrap">
                    <div>
                    <div className = "text-center">Emails ({data.emails.length})</div>
                    <div className = "exportTextContainer">
                    {data.emails.map(email=><div className = "text-center" key = {uuidv4()}>{email}</div>)}
                    </div>
                    </div>
                    <div>
                    <div className = "text-center">Subject Template</div>
                    <div className = "exportTextContainer">
                    {data.subjectTemplate}
                    </div>
                    </div>
                    <div>
                    <div className = "text-center">Body Template</div>
                        <div className = "exportTextContainer">
                    {data.bodyTemplate}
                    </div>
                    </div>
                    </div>
                    </React.Fragment>
                    break;
                        case "Text":
                basis = "exportFull";

                    exportContent = <React.Fragment>

                    <div className = "d-flex justify-content-evenly w-100 flex-nowrap">
                    <div>
                    <div className = "text-center">Phone Numbers ({data.phoneNumbers.length})</div>
                    <div className = "exportTextContainer">
                    {data.phoneNumbers.map(phoneNumber=><div className = "text-center" key = {uuidv4()}>{phoneNumber}</div>)}
                    </div>
                    </div>
                    <div>
                    <div className = "text-center">Template</div>
                    <div className = "exportTextContainer">
                    {data.template}
                    </div>
                    </div>
                    </div>
                    </React.Fragment>
                    break;
                case "Contacts":
                basis = "exportHalf";
                    exportContent = <React.Fragment>
                    <span className = "me-1">Group:</span><span className = "exportFieldContainer">{data.group.length>0?data.group:"Default"}</span>
                    </React.Fragment>
                    break;
                case "CRM":
                basis = "exportHalf";
                    exportContent = <React.Fragment>
                <span className = "me-1">CRM:</span><span className = "exportFieldContainer">{data.CRM.length>0?data.CRM:"None"}</span>

                </React.Fragment>
                    break;
                default:
                    exportContent = JSON.stringify(data);
                    break;
            }
            deleteButton = <IconButton type = "delete" className = "invisible"></IconButton>;
        }
        return <React.Fragment>
        <div className = {`exportWrapper ${basis}`}>
        <div className = {`exportPanelWrapper`}>
                    <div className = "exportHeader text-nowrap">
            {exportHeaderText}
            </div>
            <div className = {`exportPanel`}>
            <div className = "exportContent">
            {exportContent}
            </div>
            </div>
                        <div>
            {deleteButton}
            </div>
            </div>
</div>
        </React.Fragment>
    }
}

export default ExportPanel;