
import React from "react";


import './datatable.css';

import DataCell from './datacell';

import { v4 as uuidv4 } from "uuid";

class DataRow extends React.Component {
    constructor(props){
        super(props);
        this.setActive = this.setActive.bind(this);
        this.setEditing = this.setEditing.bind(this);
        this.modifyRow = this.modifyRow.bind(this);
        this.disableEditing = this.disableEditing.bind(this);
        this.removeChange = this.removeChange.bind(this);
        this.state = {data:this.props.data,editCache:{}};
    }

    setActive(active){
        var attributes = {active:active};
        this.modifyRow(attributes);
    }

    setEditing(editing,key){
        this.setState(prevState=>({editCache:{...prevState.editCache,[key]:editing}}));
    }
    disableEditing(){
        this.setEditing(false);
    }
    modifyRow(attributes){
        var addChanges = this.props.addChanges;
        this.setState((prevState) => 
            {
            var oldData = prevState.data;
            var newData = Object.assign({...prevState.data},attributes);

            var modified = JSON.stringify(oldData)!=JSON.stringify(newData);
            if(prevState.data.id&&modified){
                var state = this.props.data.state;
                var caseNumber = this.props.data.caseNumber;
                addChanges(prevState.data.id,attributes,state,caseNumber);
            }

            var newState = {data:newData};
            return newState;
    });
    }
    removeChange(attribute){
        var id = this.state.data.id;
        this.props.removeChanges(id,attribute);

    }
    render() {
        
        var userData = this.props.userData;


        var data = this.state.data;
        var filter = this.props.filter;
        var keys = Object.keys(data);
        var performanceMode = this.props.performanceMode;
        var toggleActive = this.toggleActive;
        var state = this.state;
        var setEditing = this.setEditing;
        var modifyRow = this.modifyRow;

        var contactCache = this.props.contactCache;

        var changes = this.props.changes;
        var rowChanges = changes[data.id];
        var changedCells = [];
        if(rowChanges!=null){
            changedCells = Object.keys(rowChanges);
        }
        else{
            rowChanges = {};
        }
        var rowModified = changedCells.length>0;

        var newEntry = this.props.newEntry;

        var validKeys = keys.filter(function(key){return (key!="active"||userData.manager)&&filter.visible[key]});

                var dataRow = validKeys.map(key=><DataCell entry = {data} name = {key} contactCache = {contactCache} toggleContacts = {this.props.toggleContacts} rowActive = {this.props.data.active} content = {data[key]} newEntry = {newEntry} key = {uuidv4()} performanceMode = {performanceMode} setActive = {this.setActive} manager = {userData.manager} editing = {state.editCache[key]} setEditing = {setEditing} modifyRow = {modifyRow} changedValue = {rowChanges[key]} cellChanged = {changedCells.includes(key)} removeChange = {this.removeChange} clickToCopy = {this.props.clickToCopy}></DataCell>);

            var onKeyDown;
            if(userData.manager){
                onKeyDown = this.handleInput;
            }
            return <React.Fragment>
            <tr className = {rowModified?`dataRow${newEntry?' modifyNewEntry':' modifiedRow'}`:"dataRow"}>

            {dataRow}

             </tr>
</React.Fragment>
    }
}

export default DataRow;