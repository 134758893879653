import React from "react";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Icon from '@mdi/react'
import { mdiNinja } from '@mdi/js'


import './navigation.css';
class Navigation extends React.Component {


Header(stateIndex){

switch(stateIndex){
  case "home":
       return <Link className="navbar-brand" to="/">
          Ninja Contacts <Icon path={mdiNinja} className = "iconLarge"/>
        </Link>
  break;
  case "solutions":
       return <Link className="navbar-brand" to="/">
        Ninja Contacts <Icon path={mdiNinja} className = "iconLarge"/>
        </Link>
  break;
  case "contact":
    return <Link className="navbar-brand" to="/">
     Ninja Contacts <Icon path={mdiNinja} className = "iconLarge"/>
     </Link>
break;
  case "logged in":
       return <Link className="navbar-brand" to="/">
       Ninja Contacts <Icon path={mdiNinja} className = "iconLarge"/>
        </Link>
  break;
}
}


Contact(stateIndex){

  switch(stateIndex){
              default:
              return <Link className="navButton" to = "/contact">
               Contact Us
               </Link>
              break;
              case "contact":
                return <Link className="navButton active" to = "/contact">
                 Contact Us
                 </Link>
                break;
              case "logged in": 
              break;
        
  }
  }

Solutions(stateIndex){

switch(stateIndex){
            default:
            return <Link className="navButton" to = "/solutions">
             Solutions
             </Link>
            break;
            case "solutions":
              return <Link className="navButton active" to = "/solutions">
               Solutions
               </Link>
              break;
            case "logged in":
              
      
}
}

ActionButton(stateIndex){
switch(stateIndex){
           default:
           return <Link className="navButton" to = "/dashboard/data">
            Dashboard 
            </Link>
            break;
            case "logged in":
                var email = this.props.user.attributes.email;
           return   <div className="dropdown profile">
    <a
      href=""
      className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
      id="dropdownUser1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
        alt=""

        className="rounded-circle me-2 profilePic"
      />
      {email}
    </a>
    <ul
      className="dropdown-menu dropdown-menu-dark text-small shadow"
      aria-labelledby="dropdownUser1"
    >
      <li>
        <hr className="dropdown-divider" style = {{display:"none"}} />
      </li>
      <li>
        <a className="dropdown-item" onClick = {this.props.signOut}>
          Sign out
        </a>
      </li>
    </ul>
  </div>;
            return;
            break;
          }
}

    render() {
            return <React.Fragment>
  <header>
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark navheader" style = {{flexWrap:"nowrap"}}>
      <div className="container-fluid">
      {this.Header(this.props.navState)}
        <div className="navRow">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
            </li>
          </ul>
          <span>
            {this.Contact(this.props.navState)} 
            </span>
            <span>
            {this.Solutions(this.props.navState)} 
            </span>
            <span>    
            {this.ActionButton(this.props.navState)}
            </span>
        </div>
      </div>
    </nav>
  </header>

</React.Fragment>
    }
}

export default Navigation;