import { Auth } from 'aws-amplify';

import * as DataHelper from '../utils/datahelper';

const endpoint = DataHelper.Config.apiEndpoint;

class NinjaClient {
    static async test() {
        var response = await send("test");
        console.log(response);
    }
    static async getUserData() {
        var response = await send("userdata");
        return response;
    }
    static async getEntries(states,cities, dataTypes, visibilityTypes = [], tags = [], timeStart = null, timeEnd = null) {
        var params = new URLSearchParams();


        for (var i = 0; i < states.length; i++) {
            var state = states[i];
            params.append('state', state);
        }
        for (var i = 0; i < cities.length; i++) {
            var city = cities[i];
            params.append('city', city);
        }


        for (var i = 0; i < dataTypes.length; i++) {
            var dataType = dataTypes[i];
            params.append('dataType', dataType);
        }

        for (var i = 0; i < visibilityTypes.length; i++) {
            var visibilityType = visibilityTypes[i];
            params.append('visibilityType', visibilityType);
        }

        for (var i = 0; i < tags.length; i++) {
            var tag = tags[i];
            params.append('tag', tag);
        }

        if (timeStart && timeEnd) {
            params.append('timeStart', Math.min(timeStart, timeEnd));
            params.append('timeEnd', Math.max(timeStart, timeEnd));
        }
        var response = await send("entries", params);
        return response;
    }

    static async pushChanges(changes) {
        var results = await send("pushchanges", null, "POST", {}, changes);
        return results;
    }

    static async getPlans() {
        var results = await send("subscriptions/getplans");
        return results;
    }
    static async getListeners() {
        var results = await send("listeners");
        return results;
    }
    static async postListeners(listeners) {
        var results = await send("listeners", null, "POST", {}, listeners);
        return results;
    }
    static async getContacts(){
        var results = await send("contacts");
        return results;
    }
    static async addContacts(contactIds){
        var results = await send("contacts/add",null,"POST",{},contactIds);
        return results;
    }
    static async postContacts(contacts){
        var results = await send("contacts",null,"POST",{},contacts);
        return results;
    }
    static async exportContacts(contacts,destination){
        var body = {contacts:contacts,destination:destination};
        var results = await send("contacts/export",null,"POST",{},body);
        return results;
    }
    static async sendInquiry(info){
        var results = await send("inquiry",null,"POST",{},info);
        return results;
    }
    static async checkout(prices) {
        var params = new URLSearchParams();
        for (var i = 0; i < prices.length; i++) {
            var price = prices[i];
            params.append('price', price);
        }
        params.append('returnUrl', window.location.href);
        var results = await send("subscriptions/checkout", params);
        if (results != null) {
            window.location.href = results.url;
        }
        return results;

    }

    static async manageSubscriptions() {
        var params = new URLSearchParams();
        params.append('returnUrl', window.location.href);
        var results = await send("subscriptions/manage", params);
        if (results != null) {
            window.location.href = results.url;
        }
        return results;
    }

    static async lionDeskLogin(code) {
        var params = new URLSearchParams();
        params.append('code', code);
        var results = await send("crm/liondesk/login", params);
        if (results != null) {
            window.location.href = results.url;
        }
        return results;
    }
}

async function getIdToken() {
    return Auth.currentSession()
        .then(data => data.idToken.jwtToken)
        .catch(err => console.log(err));
}

async function send(resource, parameters = null, method = "GET", extraHeaders = {}, body = null) {
    var token = await getIdToken();

    var headers = Object.assign({ Authorization: token }, extraHeaders);

    var requestOptions = {
        method: method,
        headers: headers,
        redirect: 'follow'
    };

    if (body != null) {
        requestOptions.body = JSON.stringify(body);
    }
    var callURI = endpoint + resource;

    if (parameters != null) {
        callURI += "?" + parameters.toString();
    }
    return await fetch(callURI, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log(error));

}

export default NinjaClient;