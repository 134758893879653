import React from 'react';

import './hoveroverlay.css';

class HoverOverlay extends React.Component{
render(){
	return <React.Fragment><div className = "hoverOverlay">
	<div className = "hoverContent">{this.props.children}</div>

<div className = "hiddenOverlay">{this.props.overlay}</div>
	</div></React.Fragment>
}
}

export default HoverOverlay;