import React from "react";

import { Link } from "react-router-dom";

import * as DataHelper from "../utils/datahelper";

import './maptab.css';

import {Permissions} from '../constants';

import MapView from "../components/mapview";

class MapTab extends React.Component {
    constructor(props) {
        super(props);
        this.requestLocation = this.requestLocation.bind(this);
        this.locationCallback = this.locationCallback.bind(this);
        this.locationFailCallback = this.locationFailCallback.bind(this);
        this.state = {center:{lat:null,lng:null},zoom:8,location:false};
        this.requestedLocation = false;
        
    }
        requestLocation(){
        if(!this.requestedLocation){
            this.requestedLocation= true;
        //Dummy one, which will result in a working next statement.
var locationCallback = this.locationCallback;
var locationFailCallback = this.locationFailCallback;
navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
//The working next statement.
navigator.geolocation.getCurrentPosition(function (position) {
    locationCallback(position);
}, function (e) {
    locationFailCallback(e);
}, {
    enableHighAccuracy: true
});
        }
        return null;
    }
    locationCallback(e){
        console.log(e);
        if(e?.coords?.latitude&&e?.coords?.longitude){
        var lat = e.coords.latitude;
        var lng = e.coords.longitude;
       if(this.state.center.lat!=lat||this.state.center.lng!=lng){
            this.setState({center:{lat:lat,lng:lng},zoom:12,location:true});
        }
    }
    }
    locationFailCallback(e){
        console.log(e);
                this.setState({center:{lat:42.407211,lng:-71.382439},zoom:12,location:false});
    }

    render() {

        var userData = this.props.userData;

        var manager = userData.manager;

        var dataView = <div className = "contentCentered heading">Loading...</div>;
        if (userData.subscriptions != null) {
           dataView =  <div className = "contentCentered heading"><span className = "text-center">No plan found<br></br><br></br>Subscribe to a&nbsp;<Link to = "/dashboard/plan">plan</Link></span></div>
        }
        if (manager || userData.permissions[Permissions.GET_ENTRIES]) {
var dataCache = this.props.data.dataCache;
var mappedEntries = [];
if(dataCache&&Array.isArray(dataCache)){
mappedEntries=dataCache.filter(entry=>entry.location!=null);
}

if(mappedEntries.length>0){
dataView = <React.Fragment>
    <div className="w-100 h-100">
    {this.state.center.lat&&this.state.center.lng?<MapView location = {this.state.location} entries = {mappedEntries} userData = {userData} center = {this.state.center} zoom = {this.state.zoom} data = {this.props.data} contactCache = {this.props.contactCache} toggleContacts = {this.props.toggleContacts}></MapView>:<div className="contentCentered heading">Loading Map...{this.requestLocation()}</div>}
    </div>
</React.Fragment>
}
else{
dataView = <div className = "contentCentered heading"><span className = "text-center">No mapped entries loaded<br></br><br></br>Search for&nbsp;<Link to = "/dashboard/data">entries</Link></span></div>;
}

}


        return <React.Fragment>
            <div className="mapContent">
{dataView}
</div>
        </React.Fragment>
    }
}

export default MapTab;