import React from "react";

import Icon from '@mdi/react'
import { mdiCloseCircle, mdiPlus, mdiPencil, mdiCheckBold, mdiDeleteOutline, mdiContentCopy, mdiHelp, mdiCancel, mdiCellphone, mdiHome, mdiOfficeBuilding, mdiRomanNumeral1,mdiRomanNumeral2,mdiAccount,mdiBriefcase,mdiDotsVertical,mdiChevronUp,mdiChevronDown,mdiChevronRight,mdiRefresh,mdiEraser,mdiPhone,mdiPhoneInTalk } from '@mdi/js'

import ToolTip from './tooltip';

import * as Helper from '../utils/helper';

class IconButton extends React.Component {
    constructor(props) {
        super(props);
        this.copyText = this.copyText.bind(this);

    }
    copyText(text) {
        Helper.copyText(text);
    }
    render() {
        var type = this.props.type;
        var onClick = this.props.onClick;
        var value = this.props.value;
        var className = this.props.className
        var title = this.props.title;
        var color = this.props.color;
        var size = this.props.size;
        var action = this.props.action;
        var hasTooltip = this.props.tooltip;
        var style = this.props.style;


        var buttonClasses = ["iconButton", className];

        if (onClick == null) {
            onClick = () => { };
        }


        var path = mdiCloseCircle;
        switch (type) {
            case "delete":
                buttonClasses.push("itemDelete");
                path = mdiCloseCircle;
                break;
            case "add":
                path = mdiPlus;
                break;
            case "edit":
                path = mdiPencil;
                break;
            case "done":
                path = mdiCheckBold;
                break;
            case "trash":
                path = mdiDeleteOutline;
                break;
            case "copy":
                path = mdiContentCopy;
                break;
            case "question":
                path = mdiHelp;
                break;
            case "cancel":
                path = mdiCancel;
                break;
            case "cellphone":
                path = mdiCellphone;
                break;
            case "home":
                path = mdiHome;
                break
            case "office":
                path = mdiOfficeBuilding;
                break;
            case "primary":
                path = mdiRomanNumeral1;
            break;
            case "secondary":
                path = mdiRomanNumeral2;
            break;
            case "personal":
                path = mdiAccount;
            break;
            case "work":
                path = mdiBriefcase;
            break;
            case "other":
                path = mdiDotsVertical;
            break;
            case "collapsed":
                path = mdiChevronRight;
            break;
            case "expanded":
                path = mdiChevronDown;
            break;
            case "up":
                path = mdiChevronUp;
            break;
            case "down":
                path = mdiChevronDown;
            break;
            case "refresh":
                path = mdiRefresh;
            break;
            case "eraser":
                path = mdiEraser;
            break;
            case "phone":
                path = mdiPhone;
            break;
            case "phoneTalk":
                path = mdiPhoneInTalk;
            break;

        }
        var toolTipText;
        switch (action) {
            case "copy":
                onClick = this.copyText;
                toolTipText = "Copied!";
                buttonClasses.push("toolTip");
                break;
        }

        if(style==null){
            style = {};
        }
        if (color != null) {
            style.color = color;
        }

        if (size != null) {
            style.width = `${size}em`;
            style.height = `${size}em`;
        }


        var buttonClass = buttonClasses.join(" ");

        var icon = <Icon path={path} style={style} title={title} className={buttonClass} onClick={e => { onClick(value) }} />;

        var content;
        if (hasTooltip) {
            content = <ToolTip text={toolTipText} trigger={icon}></ToolTip>;
        }
        else {
            content = icon;
        }

        return <React.Fragment>
            {content}
        </React.Fragment>
    }
}

export default IconButton;