
import React from "react";

import { v4 as uuidv4 } from "uuid";

class TextAttribute extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        var changed = this.props.onChange;
        var onKeyDown = this.props.onKeyDown;
        var value = this.props.value;
        var disabled = this.props.disabled;
        var onBlur = this.props.onBlur;
        var autoFocus = this.props.autoFocus;
        var placeholder = this.props.placeholder;
        var className = this.props.className;
        var style = this.props.style;
        var tabIndex = this.props.tabIndex;

        var list = this.props.list;
        var dataList;
        var listId = uuidv4();
        if(list!=null){
            dataList = <datalist id = {listId}>{list.map(item=><option key = {uuidv4()} value = {item}/>)}</datalist>
        }

            return <React.Fragment>
        
        
       <input type = "text" list = {list?listId:null} autoFocus = {autoFocus} tabIndex = {tabIndex} style = {style} placeholder = {placeholder} disabled = {disabled} className= {className?className:"textInput"} value = {value} onChange = {e=>changed(e.target.value)}  onKeyDown = {onKeyDown} onBlur = {onBlur}/>{dataList}
</React.Fragment>
    }
}

export default TextAttribute;