
import React from "react";

import IntegerAttribute from './integerattribute';

import { v4 as uuidv4 } from "uuid";

import Icon from '@mdi/react'
import { mdiChevronLeft,mdiChevronRight} from '@mdi/js'

class PageTurner extends React.Component {
    constructor(props){
    super(props);
    this.pageDelta = this.pageDelta.bind(this);


    }

    pageDelta(delta){
    var view = this.props.view;
    var data = this.props.data;
    var setViewPage = this.props.setViewPage;
    var pageMax = Math.ceil(data.dataCache.length/view.perPage);

    var rawPage = Number(view.page)+delta;
    var page = this.clamp(rawPage,1,pageMax);
    setViewPage(page);
    }

    render() {
        var view = this.props.view;
        var data = this.props.data;
        var setViewPage = this.props.setViewPage;
        var pageMax = Math.ceil(data.dataCache.length/view.perPage);
        return <React.Fragment>
        <div className = "pageTurner">
           {this.props.label}<Icon path={mdiChevronLeft} onClick = {e=>{this.pageDelta(-1)}} className = "pageArrow"/><IntegerAttribute className = "cleanInput" minProp = {1} maxProp = {pageMax} valueProp = {view.page} onChangeProp = {this.props.setViewPage}></IntegerAttribute> <span>/{pageMax}</span><Icon path={mdiChevronRight} onClick = {e=>{this.pageDelta(1)}} className = "pageArrow"/>
        </div>
        </React.Fragment>
    }
    clamp = (num, min, max) => Math.min(Math.max(num, min), max);
}

export default PageTurner;