import React from "react";

import './main.css';

class Main extends React.Component{


	render(){
		return <p>Main</p>
	}
}

export default Main;