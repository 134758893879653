
import React from "react";


import './datatable.css';

import Icon from '@mdi/react'
import { mdiSort } from '@mdi/js'

import { v4 as uuidv4 } from "uuid";

class DataHeader extends React.Component {
constructor(props){
    super(props);
}
renderCell(field,alias){
switch(field){
case "filledDate":
return <React.Fragment>{alias}<Icon path={mdiSort} className="iconHandle" onClick={e=>{this.props.sortBy(field)}}/></React.Fragment>
break;

default:
return alias;
break;
}
}

    render() {
var userData = this.props.userData;

var header = [];

var template = this.props.template;

var data = this.props.data;
if(template!=null)
    {
var keys = Object.keys(template);
for(var i = 0;i<keys.length;i++){
var key = keys[i];
var name = key;
if(key!="active"||userData.manager){
            if(data.filter.visible[key]){
if(data.fieldAliases[key]!=null){
name = data.fieldAliases[key];
}
header.push(<th className = "dataHeaderCell" key = {uuidv4()}>{this.renderCell(key,name)}</th>);
}
}
}

            return <React.Fragment>
            <tr className = "dataHeader" key = {uuidv4()}>{header}</tr>
</React.Fragment>
    
}
}
}

export default DataHeader;