import React from "react";

import Navigation from "./navigation";

import './contact.css';


import ContactForm from './components/contactform';

class Contact extends React.Component {
    
    render() {
        return <React.Fragment>
<Navigation navState = "contact"/> 
<div className = "content">
<div className = "authWrapper">
<ContactForm></ContactForm>
</div>
</div>
</React.Fragment>
    }
}

export default Contact;