
import React from "react";

class TextInput extends React.Component {
    constructor(props){
        super(props);
        this.handleInput = this.handleInput.bind(this);
    }
    handleInput(e) {
        if (e.key == "Escape"||e.key=="Enter"&&this.props.onSubmit) {
            this.props.onSubmit();
        }
    }
    render() {
        var value = this.props.value;
        var path = this.props.path;
        var onChange = this.props.onChange;
        var placeholder = this.props.placeholder;
        var className = this.props.className;
        if(onChange==null){
            onChange = ()=>{};
        }
        var style = this.props.style;

            return <React.Fragment>
<input type = "text" style = {style} className={className} value = {value} placeholder={placeholder} onChange = {e=>onChange(e.target.value,path)} onKeyDown = {this.handleInput}/>
</React.Fragment>
    }
}

export default TextInput;