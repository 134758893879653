import React from 'react';

import PricePlan from './priceplan';

import NinjaClient from '../api/ninjaclient';

import { v4 as uuidv4 } from "uuid";



class PriceGroup extends React.Component {


    render() {
        var plans = this.props.plans;

        var interactive = this.props.interactive;

        var rows = [];

        var array = plans;
        const chunkSize = 2;
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            var row = <div key = {uuidv4()} className="row mb-5 text-center d-flex justify-content-around">
          {chunk.map(plan=><PricePlan key = {uuidv4()} userData = {this.props.userData} plan = {plan} interactive = {interactive}></PricePlan>)}
            </div>

            rows.push(row);

        }

        var planView;

        if (plans.length > 0) {
          planView = <div className="dataContent">
       <section>
          <div className="container py-3">
            
            {rows}

          </div>
        </section>
      </div>
        } else {
            planView = <div className = "contentCentered heading">Loading...</div>
        }

        return planView;

    }
}

export default PriceGroup;