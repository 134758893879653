import { v4 as uuidv4 } from "uuid";

export const ListenerTemplate = () => {

    return {
        "exports": [

        ],
        "dataTypes": [
            "Servicemember"
        ],
        "states": [
            "MA"
        ],
        "cities":{"MA":[]},
        "id": uuidv4(),
        "name": "",
        "tags": [

        ]
    }
}

export const ExportTemplate = (type) => {
    switch(type){
        case "Email":
        return {
            type: "Email",
            data: {
                emails: [],
                subjectTemplate: "{EXPORTED_COUNT} New Contacts Verified", 
                bodyTemplate: "New Contacts Verified: {EXPORTED_ALIASES}"
            }
        }
        break;
        case "Text":
        return {
            type: "Text",
            data: {
                phoneNumbers: [],
                template: "{EXPORTED_COUNT} new contacts verified - {EXPORTED_ALIASES}"
            }
        }
        break;
        case "Contacts":
        return {
            type: "Contacts",
            data: {
                group: ""
            }

        }
        break;
        case "CRM":
        return {
            type: "CRM",
            data: {
                CRM: ""
            }

        }
        break;
    }
}